import React, { forwardRef, lazy, Suspense } from 'react'

const LazyLoadComponent = (importFunc, skeleton, delay) => {
    const LazyComponent = lazy(() =>
      delay
        ? new Promise((resolve) => setTimeout(() => resolve(importFunc()), delay))
        : importFunc()
    );
  
    return forwardRef((props, ref) => (
      <Suspense fallback={skeleton}>
        <LazyComponent ref={ref} {...props} />
      </Suspense>
    ));
  };

export default LazyLoadComponent