import React, { useEffect, useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import NewHeader from "../Component/NewHeader/NewHeader.js";
import { isCompositeComponent } from "react-dom/test-utils";

import BlogContent from "./blog";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const TravelBlogDetails = () => {
  const [BlogDetails, setBlogDetails] = useState(null);
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const Blog = urlParams.get("blogs");
    const d = JSON.parse(Blog);

    const data = BlogContent.filter(item => item.id == d);

    setBlogDetails(data);
  }, []);

  return (
    <div className="StaticMainComponent">
      <NewHeader page={"inner_listing"} />
      <div className="MainLayout__LayoutWrapper-iwen70-0 htSJWp">
        <div className="wrapper">
          <h1 className="iVFvSv">{BlogDetails != null && BlogDetails[0].title}</h1>
          <div
            className="TDHeaderContainer__Container-sc-11jrbqt-0"
            style={{ marginTop: 60 }}
          >
            <img
              src={BlogDetails != null && BlogDetails[0].image}
              alt={BlogDetails != null && BlogDetails[0].alt_tag}
              style={{
                width: "100%",
                height: 600,
                objectFit: "cover",
                borderRadius: 10,
              }}
            />
          </div>
          <div className="content_Wrapper">
            <p className="Overview__SubHeading-h8mcjb-1 twsxb">
              {BlogDetails != null && BlogDetails[0].description}
            </p>
            {BlogDetails != null &&
              BlogDetails[0].subblog.map((i, j) => (
                <div style={{ marginTop: 60 }}>
                  <h2 className="blogTitleNumber">
                    {j + 1 + "."} <span className="blogTitle">{i.title}</span>
                  </h2>
                  {i.image != null && (
                    <img
                      src={i.image != null && i.image}
                      alt={i.image != null && i.alt_tag}
                      style={{
                        width: "100%",
                        height: 500,
                        objectFit: "cover",
                        borderRadius: 10,
                        marginBottom: 20,
                      }}
                    />
                  )}

                  <p className="Overview__SubHeading-h8mcjb-1 twsxb">
                    {ReactHtmlParser(i.description)}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelBlogDetails;
