import React from "react";
import Skeleton from "react-loading-skeleton";

const OfferSliderSkeletonContainer = () => (
  <div className="container bg-white p-4 mb-4 rounded rounded-4">
    <div className="row mb-4">
      <div className="col-12">
        <h2>
          <Skeleton width={200} height={30} />
        </h2>
      </div>
    </div>

    <div className="row mb-4 shadow-sm">
      <div className="col-12">
        <ul className="list-unstyled d-flex justify-content-start">
          {Array(5)
            .fill(null)
            .map((_, index) => (
              <li className="mx-2" key={index}>
                <a className="d-flex justify-content-center align-items-center">
                  <Skeleton width={80} height={20} />
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>

    <div className="row">
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <div className="col-md-4 mb-4" key={index}>
            <div className="card p-3 shadow-sm">
              <div className="row">
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <Skeleton circle={true} height={80} width={80} />
                </div>
                <div className="col-8">
                  <div className="card-body py-0 px-3">
                    <h5 className="card-title mb-2 mt-3">
                      <Skeleton />
                    </h5>
                    <p className="card-text mb-2">
                      <Skeleton />
                    </p>
                    <p className="card-price mt-3">
                      <Skeleton width="60%" height={20} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

export default OfferSliderSkeletonContainer;
