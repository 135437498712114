import React, { useEffect, useState, useRef } from "react";
import DialCode from './PaymentDetails/DialCode';
import { withCookies } from 'react-cookie';
import { getGeoInfo, registrationagentemailcheck, setCustomerLogin } from './axios';
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';

function SignUp() {
  const [Newcountry, setCountry] = useState({});
  const [countrycode, setCountryCode] = useState({});
  const [Newcurrency, setCurrency] = useState({});
  const [ipaddress, setIpaddress] = useState({});
  //const [sussessRegister, setSussessRegister] = useState(false);
  const [EmailRecheck, setEmailRecheck] = useState(false);
  //const [PasswordMatchcheck, setPasswordMatchcheck] = useState(false);
  const [btnloader, setbtnloader] = useState(0);
  const [MobileCode, setMobileCode] = useState("");
  const {
    register,
    handleSubmit, watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    getGeoInfo().then(
      (data) => {
        setCountry(data.country_name);
        setCountryCode(data.country_calling_code);
        setCurrency(data.country_name);
        setIpaddress(data.ip);
      }
    );
  }, []);

  let OnChangeMobileCode = (event) => {
    setMobileCode(event.target.value);
  };
  const password = useRef({});
  password.current = watch("password", "");
  // End On Change Status Data Show
  // On Submit User List
  // const onSubmit = (data) => {
  const SubmitLogin = handleSubmit((data) => {
    let subData = true;
    setbtnloader(1);

    registrationagentemailcheck(data.email).then(
      (data) => {
        setbtnloader(0);
        if (data == "exist") {
          setEmailRecheck(true);
          return (subData = false);
        } else {
          setEmailRecheck(false);
        }
      }
    );
    setTimeout(() => {
      if (subData) {
        // setSussessRegister(false);
        var MobileCodeData = MobileCode != '' ? MobileCode : countrycode;
        let first_name = data.firstName;
        let last_name = data.lastName;
        let email = data.email;
        let profile_image = "";
        let birthday = "";
        let gender = "";
        let login_source = 0;
        let mobile_number = data.number;
        let country = Newcountry;
        let country_code = MobileCodeData;
        let currency = Newcurrency;
        let ip_address = ipaddress;
        let password = data.password;
        let verified = 1;

        let Verified = "";
        if (verified == 1) {
          Verified = true;
        }

        const dataSearch = {
          first_name: first_name,
          last_name: last_name,
          email: email,
          country_code: country_code,
          verified: Verified,
          uid: "",
        };

        let JsonData = JSON.stringify(dataSearch);

        localStorage.setItem('userDetails', JsonData);
        setCustomerLogin(first_name,
          last_name,
          email,
          profile_image,
          birthday,
          gender,
          login_source,
          mobile_number,
          country,
          country_code,
          currency,
          ip_address,
          password,
          verified).then(
            (data) => {
              if (data != 'exist') {
                window.location.reload(true);
                setbtnloader(0);
              } else {
                setbtnloader(0);
                let text = "This email id is already exist.";
                if (window.confirm(text) == true) {
                }
              }
            }
          );
      }
    }, 3000);
  })

  return (
    <>
      <form autoComplete="off">
        <input
          type="text"
          className={`form-control ${errors.firstName && "Err"
            }`}
          {...register("firstName", {
            required: true,
            minLength: 2,
            maxLength: 50,
            pattern: /^[A-Za-z ]+$/i,
          })}
          id="firstName"
          name="firstName"
          placeholder="Enter First Name"
        />

        {errors.firstName &&
          errors.firstName.type ===
          "required" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Please enter your First Name
            </span>
          )}
        {errors.firstName &&
          errors.firstName.type ===
          "minLength" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              First Name must be at least 2 characters
              long!
            </span>
          )}
        {errors.firstName &&
          errors.firstName.type ===
          "maxLength" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              First Name should be less then  50
              characters !
            </span>
          )}
        {errors.firstName &&
          errors.firstName.type === "pattern" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Please enter only alphabets
            </span>
          )}

        <input
          type="text"
          className={`form-control ${errors.lastName && "Err"
            }`}
          {...register("lastName", {
            required: true,
            minLength: 2,
            maxLength: 50,
            pattern: /^[A-Za-z ]+$/i,
          })}
          id="lastName"
          name="lastName"
          placeholder="Enter Last Name"
        />

        {errors.lastName &&
          errors.lastName.type === "required" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Please enter your Last Name
            </span>
          )}
        {errors.lastName &&
          errors.lastName.type ===
          "minLength" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Last Name must be at least 2 characters long!
            </span>
          )}
        {errors.lastName &&
          errors.lastName.type ===
          "maxLength" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Last Name should be less then 50 characters
              !
            </span>
          )}
        {errors.lastName &&
          errors.lastName.type === "pattern" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Please enter only alphabets
            </span>
          )}

        <input type="email"
          className={`form-control ${errors.email && "Err"
            }`}
          {...register("email", {
            required: true,
            minLength: 2,
            pattern:
              /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          })}
          id="email"
          name="email"
          placeholder="Enter Email"
        />

        {errors.email &&
          errors.email.type === "required" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Please enter your Email
            </span>
          )}
        {errors.email &&
          errors.email.type === "minLength" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Email must be at least 2 characters long!
            </span>
          )}
        {errors.email &&
          errors.email.type === "pattern" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Please enter a valid email address
            </span>
          )}

        {EmailRecheck && (
          <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
            This email id already exist
          </span>
        )}

        <div className="guestDtls__col width327">
          <div className="paymakeFlex textLtr">
            <div
              className="guestDtls__contact"
              style={{ margin: "8px 0px 0px 0px" }}
            >
              <label for="mCode" className=" frmSelectCont__contact">
                <select
                  id="mCode"
                  className="frmSelect frmSelectContact"
                  value={MobileCode}
                  onChange={OnChangeMobileCode}
                >
                  {DialCode.map((item, key) => (
                    <option value={item.dial_code}>
                      {item.name}&nbsp;({item.dial_code})
                    </option>
                  ))}
                </select>
              </label>
              <span className="selectedCode">
                {MobileCode == null
                  ? countrycode
                  : MobileCode}
              </span>
            </div>
            <div className="flexOne">
              <div className="textFieldCol ">
                <input
                  type="number"
                  className={`frmTextInput noLeftBorder form-control ${errors.number && "Err"
                    }`}
                  {...register("number", {
                    required: true,
                    minLength: 6,
                    maxLength: 15,
                    pattern: /^[0-9]+$/i,
                  })}
                  id="number"
                  name="number"
                  placeholder="Enter Contact Number"
                />

                {errors.number &&
                  errors.number.type === "required" && (
                    <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
                      Please enter your Contact Number
                    </span>
                  )}
                {errors.number &&
                  errors.number.type ===
                  "minLength" && (
                    <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
                      Contact Number must be at least 6
                      characters long!
                    </span>
                  )}
                {errors.number &&
                  errors.number.type ===
                  "maxLength" && (
                    <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
                      Contact Number should be less then 15
                      characters !
                    </span>
                  )}
                {errors.number &&
                  errors.number.type === "pattern" && (
                    <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
                      Please enter only number
                    </span>
                  )}

              </div>
            </div>
          </div>
        </div>

        <input
          type="password"
          className={`form-control ${errors.password && "Err"
            }`}
          {...register("password", {
            required: true,
            minLength: 6,
            maxLength: 20,
          })}
          id="password"
          name="password"
          placeholder="Enter Password"
        />

        {errors.password &&
          errors.password.type === "required" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Please enter your Password
            </span>
          )}
        {errors.password &&
          errors.password.type === "minLength" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Password must be at least 6 characters
              long!
            </span>
          )}
        {errors.password &&
          errors.password.type === "maxLength" && (
            <span className="error" style={{ color: '#eb4134', textTransform: 'none' }}>
              Password should be less then 20 characters !
            </span>
          )}

        <button onClick={SubmitLogin}>Sign Up  &nbsp;{btnloader === 1 ? (<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          visually-hidden="true"
        />) : ""}</button>
      </form>
    </>
  );

}
export default withCookies(SignUp);