import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Base64 } from "js-base64";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import {
  getCustomerLogin,
} from "./axios";
import NewsLatterComponent from "./NewsLatterComponent";
import Banner from "./Banner";
import BannerTabNav from "./BannerTabNav";
import Footer from "./Footer";
import "./Header.css";
import OfferSliderSkeletonContainer from "./OfferSliderSkeletonContainer ";
import MakeYourTrip from "./MakeYourTrip";
import LazyLoadComponent from "./LazyLoadComponent";
import MobileAppComponent from "./MobileAppComponent";
import LoginComponent from "./Auth/LoginComponent";

const Event = LazyLoadComponent(() => import("./Events/Event"), <OfferSliderSkeletonContainer />)
const Superoffers = LazyLoadComponent(() => import("./Superoffers"), <OfferSliderSkeletonContainer />)

const TabsArr = [
  "Accommodations",
  "Attractions & Things to Do",
  "Packages",
  "Land & Ferry Transfers",
  "Liveaboard",
];
class Header extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      selected: "Accommodations",
      isLoginOpen: false,
      customerEmailForData: localStorage.getItem("userDetails") || "abc",
      customerDetails: {},
      profileNew: false,
      profileNewAgain: false,
      ForgotEmail: "",
    };
  }

  openLogin = () => this.setState({ isLoginOpen: true });
  onCloseLogin = () => this.setState({ isLoginOpen: false });
  onOpenLogin = () => this.setState({ isLoginOpen: true });

  handleScroll = () => {
    if (window.pageYOffset > 40) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };

  setSelectedTabOptions = (tab) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.setState({ selected: tab });
  };

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tabName = urlParams.get("t");
    const logout = urlParams.get("lg");
    if (logout != null) {
      if (Base64.atob(logout) == 'logout') {
        localStorage.clear();
        var base_url = window.location.origin;
        window.location.href = base_url;
      }
    }

    if (tabName != null) {
      if (Base64.atob(tabName) == 1) {
        this.setState({ selected: "Accommodations" });
      } else if (Base64.atob(tabName) == 2) {
        this.setState({ selected: "Attractions & Things to Do" });
      } else if (Base64.atob(tabName) == 3) {
        this.setState({ selected: "Packages" });
      } else if (Base64.atob(tabName) == 4) {
        this.setState({ selected: "Land & Ferry Transfers" });
      } else if (Base64.atob(tabName) == 5) {
        this.setState({ selected: "Liveaboard" });
      }
    }

    let userData = localStorage.getItem("userDetails");

    if (userData != null) {
      let newData = JSON.parse(userData);
      getCustomerLogin(newData?.email).then(
        (data) => {
          this.setState({ customerDetails: data });

          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
        },
        (error) => { }
      );
    }

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("click", this.globalClickListener);
  }

  globalClickListener = (nativeEvent) => {
    this.setState({ profileNew: false, profileNewAgain: false }, () => {
      // document.removeEventListener('click', this.globalClickListener)
      window.removeEventListener("click", this.globalClickListener);
    });
  };

  OpenProfilelink = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ profileNew: !prevState.profileNew }),
      () => {
        if (this.state.profileNew) {
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  OpenProfilelinkAgain = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ profileNewAgain: !prevState.profileNewAgain }),
      () => {
        if (this.state.profileNewAgain) {

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  logoutChange = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  render() {
    const withoutLoginText = "Login or Create Account";
    const withoutLoginIcon = <img src="images/icon/favicon.ico" className="loginIcon" />;
    const withLoginIcon = <span className="userNameIcon hs-white hs-LoginFlex perfectCenter latoBlack text-capitalize me-2">{this.state.customerDetails?.first_name?.charAt(0)}</span>;
    const LoginInfoButton = ({ light }) =>
      <div className={`hs-LoginFlex column userNameText${light ? " hs-white" : ""} latoBold`}>
        <p
          data-cy="loggedInUser"
          className="appendBottom3 truncate customerName"
        >
          Hey {this.state.customerDetails?.first_name}
        </p>
      </div>;

    const logoHtml = <Link to="/">
      <img
        src="images/logo/seyLogo.png"
        className="img-fluid"
        alt="Holiday Seychelles"
      />
    </Link>

    const dropdownContent = [
      {
        link: "/PlanTrips",
        icon: 'userItemMyTrips',
        heading: "My Trips",
        subHeading: "Plan trip & Manage your bookings.",
      },
      {
        link: "/Profile",
        icon: 'userItemMyProfile',
        heading: "My Profile",
        subHeading: "Manage your profile, login details and password.",
      },
      {
        onClick: this.logoutChange,
        icon: 'userItemLogout',
        heading: "Logout",
      }
    ].map(({ link, icon, heading, subHeading, onClick = null }, index) => {
      const html = (
        <>
          <span className={`${icon} landingSprite me-3`} />
          <div className="lh-1">
            <p className="h6 m-0">{heading}</p>
            {subHeading && <small className="text-muted fw-bold">{subHeading}</small>}
          </div>
        </>
      );

      return link ? <Link to={link} key={`drop-down-menu-${index}`} className="userItems d-flex">
        {html}
      </Link> : <a className="userItems d-flex" onClick={onClick} key={`drop-down-menu-${index}`}>{html}</a>
    });

    return (
      <div id="page" className="bgGradient">
        <div className="mobile-menu">
          <nav className="navbar navbar-expand-md navbar-light mobile-menu">
            <button className="ma5menu__toggle" type="button">
              <span className="ma5menu__icon-toggle"></span>{" "}
              <span className="ma5menu__sr-only">Menu</span>
            </button>
            <div>
              <ul className="site-menu" style={{ display: "none" }}>
                <li>
                  <a href="" title="Home">
                    Home
                  </a>
                </li>
                <li>
                  <a href="contact-us.html" title="Contact Us">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="">
          <div className="container">
            <div className="row mb-3 py-2">
              <div className="col-md-3 col-12 text-center text-md-start mb-2 mb-md-0">
                {logoHtml}
              </div>

              <div className="col-md-9 col-12">
                <div className="container">
                  <div className="row gy-1 gx-2 justify-content-end">
                    {[
                      {
                        icon: <span className="landingSprite supportIcon appendRight10" />,
                        heading: "Offers",
                        subHeading: "Explore Best Deals and Offers",
                        link: "/tourofferlisting",
                      },
                      {
                        heading: "HS for Agent",
                        subHeading: "(Agent Login)",
                        anchor: "https://agents.holidays-seychelles.com/",
                      },
                      {
                        heading: "HS for Business",
                        subHeading: "(Supplier Login)",
                        anchor: "https://supplier.holidays-seychelles.com/",
                      },
                      {
                        icon: <span className="landingSprite myTripsIcon appendRight10" />,
                        heading: "My Trips",
                        subHeading: "Plan Trips and Bookings",
                        link: this.state.customerEmailForData != "abc" && "/PlanTrips",
                        onClick: this.state.customerEmailForData == "abc" && this.openLogin,
                      },
                      {
                        icon: this.state.customerEmailForData == "abc" ? withoutLoginIcon : withLoginIcon,
                        heading: this.state.customerEmailForData == "abc" ?
                          withoutLoginText :
                          <>
                            <LoginInfoButton light />
                            {this.state.profileNew && (
                              <div className="userDropdown fadeInDown hs-LoginFlex column">
                                {dropdownContent}
                              </div>
                            )}</>,
                        onClick: this.state.customerEmailForData == "abc" ? this.openLogin : this.OpenProfilelink,
                      },
                    ].map(({ icon, heading, subHeading, link, anchor, onClick }, i, arr) => {
                      const card = <div className="bg-white bg-opacity-10 rounded rounded-4 p-2 h-100 shadow-md cursor-pointer" onClick={onClick}>
                        <div className="d-flex align-item-center text-white">
                          {icon}
                          <div className={`lh-${subHeading ? '1' : 'default'}`}>
                            <small className="d-block m-0 fw-bold">{heading}</small>
                            {subHeading && <small className="fs-sm">{subHeading}</small>}
                          </div>
                        </div>
                      </div>

                      return <div className={`col-${arr.length === i + 1 ? '12' : '6'} col-md-auto`} key={`top-menu-${i}`}>
                        {link ? <Link to={link}> {card} </Link> :
                          anchor ? <a href={anchor} target="_blank">{card}</a> : card
                        }
                      </div>
                    })
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="hsFlex_Bann hsCenter prependTop5 appendBottom15 mobile-view">
              {" "}
            </div> */}

            <div className="header-top-section">
              <div
                className={`chHeaderWrapper ${this.state.nav == true ? " stickyHeader" : " navOnly"
                  }`}
              >
                <div className="chHeaderContainer">
                  <span className="logoContainer">
                    {logoHtml}
                  </span>

                  <BannerTabNav
                    tabs={TabsArr}
                    selected={this.state.selected}
                    setSelectedTab={this.setSelectedTabOptions}
                  ></BannerTabNav>

                  <ul className="userSection pushRight">
                    {this.state.customerEmailForData == "abc" ? (
                      <li
                        className="hsFlex_Bann hsCenter font10 hsRelative hsUser"
                        onClick={this.openLogin}
                      >
                        <div className="hsFlex_Bann column flexOne latoBold">
                          <p className="appendBottom3_Header">
                            {withoutLoginIcon} {withoutLoginText}
                          </p>
                        </div>
                      </li>
                    ) : (
                      <li
                        className="hs-LoginFlex hsCenter font10 hsRelative hsUser userLoggedIn"
                        onClick={this.OpenProfilelinkAgain}
                      >
                        {withLoginIcon}
                        <LoginInfoButton />
                        {this.state.profileNewAgain && (
                          <div className="userDropdown fadeInDown hs-LoginFlex column dropbanner">
                            {dropdownContent}
                          </div>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <Banner isSelected={this.state.selected} />
          </div>
        </div>
        <Superoffers />
        <MakeYourTrip />
        <Event />
        <MobileAppComponent />
        <NewsLatterComponent />
        <Footer />
        <LoginComponent open={this.state.isLoginOpen} onClose={this.onCloseLogin} onOpen={this.onOpenLogin} />
      </div>
    );
  }
}

export default withCookies(Header);
