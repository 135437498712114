import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import NewHeader from "../Component/NewHeader/NewHeader.js";
import Footer from "../Component/Footer";
import BlogContent from "./blog";
import { isCompositeComponent } from "react-dom/test-utils";
import {Helmet} from "react-helmet";

const TravelBlog = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
      <div className="MainStaticMainComponentContaint">
      <Helmet>
          <title> Holidays Seychelles Blog</title>
          <meta name="description" content="Get Information about best honeymoon destination in the world Seychells." />
          <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
        </Helmet>
        <h1 style={{}}> Holidays Seychelles Blog</h1>
        <NewHeader page={"inner_listing"} />
        <div className="MainLayout__LayoutWrapper-iwen70-0 htSJWp">
          <div className="wrapper">
            <h1 className="iVFvSv">
              Travel Blog - Discover Places &amp; Plan Your Vacations With Us!
            </h1>
            <div className="DestinationListing__ListingCont-sc-3pgqyg-0 dDCOoG">
              {BlogContent.map((item, index) => (
                <a
                  href={"/travelblogdetails?blogs=" + JSON.stringify(item.id)}
                  target="_blank"
                  className="DestinationCard__Card-sc-1czmno9-0 gjMtnc"
                >
                  <div className="ImageSlider__ImgCont-sc-1qdl6fj-0 hyLvbp">
                    <div className="ImageSlider__CardImg-sc-1qdl6fj-1 fBVWBY">
                      <img src={item.image} alt={item.alt_tag} />
                    </div>
                  </div>
                  <a
                    href={"/travelblogdetails?blogs=" + JSON.stringify(item.id)}
                    target="_blank"
                  >
                    <p className="DestinationCard__CardTitle-sc-1czmno9-1 gMbbQW">
                      <span>{index + 1}</span>
                      {item.title}
                    </p>
                  </a>
                  <p className="DestinationCard__SubTitle-sc-1czmno9-2 blmrSB"></p>
                  <p className="DestinationCard__Desc-sc-1czmno9-3 knmWQN">{`${item.description.substring(
                    0,
                    200
                  )}...`}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default TravelBlog;
