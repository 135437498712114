import React, { Component } from "react";
import "./HotelDetails.css";
import { Base64 } from "js-base64";
import NewHeader from "../../Component/NewHeader/NewHeader";
import { zonelistAccoData, HotelListDataById, HotelListData, SupplierUrl } from "../axios";
import moment from "moment";
import Autosuggest from "react-autosuggest";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-scroll";
import GoogleMap from "./GoogleMap/GoogleMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Helmet} from "react-helmet";

import RatesAndDetails from "./RatesAndDetails/RatesAndDetails";

import Lightbox from "react-image-lightbox";
import {
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import HotelDetailSkeleton from "./HotelDetailSkeleton";
import { decode } from "html-entities";
import { Redirect } from "react-router-dom";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import Ratings from "react-ratings-declarative";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TawkToWidget from "../../Component/TawkToWidget";

const ListNumber = [
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" },
  { id: "5" },
  { id: "6" },
  { id: "7" },
  { id: "8" },
  { id: "9" },
  { id: "10" },
  { id: "11" },
  { id: "12" },
  { id: "13" },
];

class HotelDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guests: 0,
      children: 0,
      adults: 0,
      visibleToggler: "buttons-invisible",
      startDate: null,
      endDate: null,
      isOpen: false,
      roomCounter: 1,
      dropdownVisible: false,
      selected: "Accommodations",
      searchInput: "",
      itemsZone: [],
      error: null,
      val: "",
      value: "",
      suggestions: [],
      showResults: false,
      defaultZoneval: "Seychelles",
      modalIsOpen: false,
      SelectedPlace: null,
      nav: false,
      isOpenGallery: false,
      photoIndex: 0,
      HotelDetails: "",
      HotelGallery: "",
      HotelServices: [],
      Neighbourhoodareas: [],
      guestCombination: "",
      cats: [
        { adultCounter: 1, childCounter: 0, roomCounter: 1, isExpanded: true },
      ],
      EJuniper: [],
      HSPortal: [],
      HotelLink: [],
      redirect: null,
      querySe: {},
      roomDescription: {},
      JuniperDesc: [],
      CartIndexNumber: null,
      showMore: false
    };
  }

  guestToggleHandler = () => {
    if (this.state.visibleToggler === "buttons-invisible") {
      this.setState({
        visibleToggler: "buttons-stop",
      });
    } else {
      this.setState({
        visibleToggler: "buttons-invisible",
      });
    }
  };

  adultIncrementHandler = () => {
    this.setState({
      adults: this.state.adults + 1,
      guests: this.state.guests + 1,
    });
  };

  adultDecrementHandler = () => {
    if (this.state.adults > 0 && this.state.guests > 0) {
      this.setState({
        adults: this.state.adults - 1,
        guests: this.state.guests - 1,
      });
    }
  };

  childIncrementHandler = () => {
    this.setState({
      children: this.state.children + 1,
      guests: this.state.guests + 1,
    });
  };

  childDecrementHandler = () => {
    if (this.state.children > 0 && this.state.guests > 0) {
      this.setState({
        children: this.state.children - 1,
        guests: this.state.guests - 1,
      });
    }
  };

  handleClick() {
    this.setState({showMore: true})
  }
  handleClickLess() {
    this.setState({showMore: false})
  }
  //numberOfItems = this.state.showMore ? list.length : 10

  setDatefunction(startDate, endDate) {
    // const startD = moment(startDate).format("MMM Do YY");
    // const endD = moment(endDate).format("MMM Do YY");
    this.setState({ startDate, endDate });
  }
  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  handleEvent = (id) => {
    this.setState({ SelectedPlace: id });
  };

  increment = () => {
    if (this.state.adultCounter < 15) {
      this.setState({ adultCounter: this.state.adultCounter + 1 });
    }
  };

  decrement = () => {
    if (this.state.adultCounter > 0) {
      this.setState({ adultCounter: this.state.adultCounter - 1 });
    }
  };

  incrementChild = () => {
    if (this.state.adultCounter == 0) {
      this.setState({ adultCounter: this.state.adultCounter + 1 });
    }
    if (this.state.childCounter < 6) {
      this.setState({ childCounter: this.state.childCounter + 1 });
    }
  };

  decrementChild = () => {
    if (this.state.childCounter > 0) {
      this.setState({ childCounter: this.state.childCounter - 1 });
    }
  };

  incrementRoom = () => {
    if (this.state.roomCounter < 10) {
      this.setState({ roomCounter: this.state.roomCounter + 1 });
    }
  };

  decrementRoom = () => {
    if (this.state.roomCounter > 0) {
      this.setState({ roomCounter: this.state.roomCounter - 1 });
    }
  };

  handleScroll = () => {
    if (window.pageYOffset > 10) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const HotelId = urlParams.get("hotelid");
    const query = urlParams.get("query");
    const indexNumber = urlParams.get("IndexNumber");
   

    let querySearch = JSON.parse(query);

    this.setState({ cats: querySearch.guestCombination });
    this.setState({ startDate: querySearch.startDate });
    this.setState({ endDate: querySearch.endDate });
    this.setState({ CartIndexNumber: indexNumber });

    this.setState({ querySe: query });

    HotelListDataById(HotelId, query).then(
      (result) => {
        result.map((item, key) => {
      
          this.setState({ HotelDetails: item.details });
          this.setState({ defaultZoneval: item.details.property_name });
          this.setState({ HotelGallery: item.property_gallery });
          this.setState({ HotelServices: item.property_services_facilities });
          this.setState({ Neighbourhoodareas: item.neighbourhoodareas });
          if (item.ejuniper != null) {
            this.setState({ EJuniper: item.ejuniper });
          }

          this.setState({ HSPortal: item.hs_portal });

          this.setState({ HotelLink: item.hotel_link });
          this.setState({ JuniperDesc: item.property_description });

          this.setState({ roomDescription: item.rooms_description });
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    zonelistAccoData().then(
      (data) => {
        this.setState({
          itemsZone: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    window.addEventListener("scroll", this.handleScroll);
  }

  SearchListing = () => {
    // window.location.reload(true);

    let JsonState = {
      defaultZoneval: this.state.defaultZoneval,
      startDate: moment(this.state.startDate).format("Y-MM-DD"),
      endDate: moment(this.state.endDate).format("Y-MM-DD"),
      guestCombination: this.state.cats,
    };
    const hotelArr = JSON.stringify(JsonState);

    this.setState({
      HotelDetails: "",
    });
    HotelListData(hotelArr).then(
      (data) => {
        if (data == undefined) {
          //  this.setState({
          //      hotelList: null,
          //  });
        } else if (data.length > 1) {
          const url =
            "/hotellisting?defaultZoneval=" +
            encodeURIComponent(this.state.defaultZoneval) +
            "&checkin=" +
            encodeURIComponent(this.state.startDate) +
            "&checkout=" +
            encodeURIComponent(this.state.endDate) +
            "&guestCombination=" +
            JSON.stringify(this.state.cats);
          window.location.replace(url);
        } else {
        
          data.map((item, key) => {
            const url =
              "/hotelDetails?hotelid=" +
              Base64.btoa(item.details.id) +
              "&query=" +
              encodeURIComponent(hotelArr);

            window.location.replace(url);
          });
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  componentWillUnmount() {
    // document.removeEventListener('click', this.globalClickListener)
    window.removeEventListener("click", this.globalClickListener);
    window.removeEventListener("scroll", this.handleScroll);
  }
  globalClickListener = (nativeEvent) => {
    this.setState({ dropdownVisible: false, showResults: false }, () => {
    
      // document.removeEventListener('click', this.globalClickListener)
      window.removeEventListener("click", this.globalClickListener);
    });
  };
  toggleDropdown = (syntheticEvent) => {

    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ dropdownVisible: !prevState.dropdownVisible }),
      () => {
        if (this.state.dropdownVisible) {
        

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
   
  };
  handleBodyClick = (event) => {
   
    event.stopPropagation();
  };
  setImagePhotoIndex = (index) => {
    this.setState({ isOpenGallery: true });
    this.setState({ photoIndex: index });
  };

  renderDropdownMenu() {
    return (
      <div className="roomsGuestsList" onClick={this.handleBodyClick}>
        {this.state.cats.map((val, idx) => {
          if (idx <= 2) {
            return (
              <div className="roomsGuestsTop" key={idx}>
                <div className="addRoomRowhs">
                  <div className="addRoomLeft">
                    <p className="darkText font16 latoBlack capText appendBottom10">
                      ROOM {idx + 1}
                    </p>
                  </div>
                  <div className="addRoomRight">
                    {val.isExpanded == true ? (
                      <div className="addRooomDetails">
                        <p className="spaceBetween appendBottom10">
                          <span className="latoBold font12 grayText">
                            Adults (12y +){" "}
                          </span>
                        </p>
                        <ul className="guestCounter font12 darkText">
                          {ListNumber.map((item, index) => (
                            <li
                              className={
                                this.state.cats[idx].adultCounter == item.id
                                  ? "selected"
                                  : ""
                              }
                              key={index}
                              onClick={() =>
                                this.handleInputChange(item.id, idx)
                              }
                            >
                              {item.id}
                            </li>
                          ))}
                        </ul>
                        <div>
                          <p className="latoBold font12 grayText appendBottom10">
                            Children (1y - 12y )
                          </p>
                          <ul className="guestCounter font12 darkText">
                            {ListNumber.slice(0, 6).map((item, index) => (
                              <li
                                className={
                                  this.state.cats[idx].childCounter == index
                                    ? "selected"
                                    : ""
                                }
                                key={index}
                                onClick={() =>
                                  this.handleInputChangeChild(index, idx)
                                }
                              >
                                {index}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="editRoom">
                        <p className="hdmakeFlex end">
                          <span className="font16 blackText">
                            <span className="latoBlack">
                              {this.state.cats[idx].adultCounter}
                            </span>{" "}
                            Adults,
                            <span className="latoBlack">
                              {" "}
                              {this.state.cats[idx].childCounter}
                            </span>{" "}
                            Children
                          </span>
                          <span
                            className="font12 appendLeft10 editRoomClass"
                            onClick={() => this.handleEditRoomClick(idx)}
                          >
                            Edit{" "}
                          </span>
                        </p>
                        <span
                          className="font12 editRoomClass"
                          key={idx}
                          onClick={() => this.handleRemoveClick(idx)}
                        >
                          Remove
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
        <div className="roomsGuestsBottom">
        {this.state.cats.length <=2 &&(<span className="btnAddRoom" onClick={this.addRoom}>+ Add Another Room{" "}</span>)}
          <span className="submit-button" onClick={this.applyRoom}>
            Apply
          </span>
        </div>
      </div>
    );
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZone.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionValue = (suggestion) => suggestion.Name;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="spaceBetween makeFlex hrtlCenter ">
        <div className="flexOne">
          <p className="locusLabel appendBottom5">{suggestion.Name}</p>
        </div>
        <span
          className="width60 font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight"
          style={{ width: "100px", padding: "0px" }}
        >
          {suggestion.AreaType}
        </span>
      </div>
    </div>
  );

  renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      defaultZoneval: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = () => {
    this.setState({
      showResults: false,
    });
  };

  showZonelistdiv = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResults: !prevState.showResults }),
      () => {
        if (this.state.showResults) {
        
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  addRoom = (e) => {
    const catLength = this.state.cats.length;
    var i = 0;
    for (i = 0; i <= catLength - 1; i++) {
      let cats = [...this.state.cats];
      cats[i]["isExpanded"] = false;
      this.setState({
        cats: cats,
      });
    }

    this.setState((prevState) => ({
      cats: [
        ...prevState.cats,
        { adultCounter: 1, childCounter: 0, roomCounter: 1, isExpanded: true },
      ],
    }));
  };

  handleEditRoomClick = (index) => {
    const catLength = this.state.cats.length;
    var i = 0;
    for (i = 0; i <= catLength - 1; i++) {
      let cats = [...this.state.cats];
      cats[i]["isExpanded"] = false;
      this.setState({
        cats: cats,
      });
    }

    let cater = [...this.state.cats];
    cater[index]["isExpanded"] = true;
    this.setState({
      cats: cater,
    });
  };

  applyRoom = (e) => {
    this.setState(
      { dropdownVisible: false, showResults: false, checkinShow: false },
      () => {
       
        // document.removeEventListener('click', this.globalClickListener)
        window.removeEventListener("click", this.globalClickListener);
      }
    );
  };

  handleInputChange = (e, index) => {
    const { adultCounter, value } = e;
    let cats = [...this.state.cats];

    cats[index]["adultCounter"] = e;
    this.setState({
      cats: cats,
    });
  };

  handleInputChangeChild = (e, index) => {
    let cats = [...this.state.cats];
    cats[index]["childCounter"] = e;
    this.setState({
      cats: cats,
    });
  };
  handleRemoveClick = (index) => {
    let cats = [...this.state.cats];
    cats.splice(index, 1);
    this.setState({
      cats: cats,
    });
  };
  handleSetActive = (to) => {
    
  };
  handleSetInactive = (to) => {
   
  };
  starsCount = () => {
    if (this.state.HotelDetails.star_category_id == 1) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (this.state.HotelDetails.star_category_id == 2) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (this.state.HotelDetails.star_category_id == 3) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (this.state.HotelDetails.star_category_id == 4) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (this.state.HotelDetails.star_category_id == 5) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
        </>
      );
    }
  };

  render() {
    const menuclassName = `panel-dropdown${
      this.state.dropdownVisible ? " active" : ""
    }`;
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Enter City/ Location/ Property",
      type: "search",
      value,
      onChange: this.onChange,
    };
    const images = this.state.HotelGallery;
    
    return (
      <div className="Detail-container">
          <Helmet>
      <title>Book the best of 250 hotels in Seychelles - Holidays Seychelles</title>
        <meta name="description" content="Book best hotels in Seychelles at affordable prices." />
        <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
      </Helmet>
      <h1 style={{color:'#fff'}}>Book the best of 250 hotels in Seychelles - Holidays Seychelles</h1>
        <NewHeader page={"inner"} />
        <div
          className={`${
            this.state.nav == true ? "_Hlisting_header_new" : "_Hlisting_header"
          }`}
        >
          <div className="container hsw">
            <div className="hsw_inner">
              <div className="hsw_inputBox  " onClick={this.showZonelistdiv}>
                <label
                  for="city"
                  className="lbl_input latoBold font12 blueText"
                >
                  City, Location or Property
                </label>
                <p
                  className="hsw_inputField font16 whiteText"
                  style={{ marginBottom: "3px" }}
                >
                  {this.state.defaultZoneval.length >= 25
                    ? `${this.state.defaultZoneval.substring(0, 25)}...`
                    : this.state.defaultZoneval}
                </p>
                <div className="focused_div">
                  {this.state.showResults ? (
                    <div
                      className="autocomplete-wrapper"
                      onClick={this.handleBodyClick}
                    >
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequested
                        }
                        onSuggestionSelected={this.onSuggestionSelected}
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequested
                        }
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestionsContainer={
                          this.renderSuggestionsContainer
                        }
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="hsw_inputBox  ">
                <span className="checkinandcheckout">
                  <label
                    for="checkin"
                    className="lbl_input latoBold font12 blueText capText"
                  >
                    Check-In:
                  </label>
                  <label
                    for="checkout"
                    className="lbl_input latoBold font12 blueText capText"
                  >
                    Check-Out:
                  </label>
                </span>
                {/* <input type="text" className="hsw_inputField font16 whiteText" readOnly="" value="Tue, 8 Jun 2021" /> */}

                <RangeDatePicker
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={(startDate, endDate) =>
                    this.setState({ startDate: startDate, endDate: endDate })
                  }
                  minDate={new Date()}
                  maxDate={new Date(2100, 0, 1)}
                  startDatePlaceholder="Check In"
                  endDatePlaceholder="Check Out"
                  className="hotellisting"
                  startWeekDay="monday"
                />
              </div>

              <div className="hsw_inputBox  " onClick={this.toggleDropdown}>
                <label
                  for="guest"
                  className="lbl_input latoBold font12 blueText"
                >
                  ROOMS &amp; GUESTS
                </label>

                <div className={menuclassName}>
                  {this.state.dropdownVisible && this.renderDropdownMenu()}
                </div>
                <p
                  className="hsw_inputField guests font16 whiteText"
                  style={{ marginBottom: "0px" }}
                >
                  {this.state.cats.reduce((a, v) => a + v.roomCounter, 0)}
                  <span> Room</span>{" "}
                  {this.state.cats.reduce(
                    (a, v) => a + v.adultCounter * 1 + v.childCounter * 1,
                    0
                  )}
                  <span> Guests</span>
                </p>
              </div>
              <span
                className="primaryBtn hsw_searchButton"
                onClick={this.SearchListing}
              >
                Search
              </span>
            </div>
          </div>
        </div>
        {this.state.HotelDetails == "" ? (
          <HotelDetailSkeleton />
        ) : (
          <>
            <div
              className="container"
              style={{ marginTop: 100, maxWidth: "1210px" }}
            >
              <div className="DetailPageTitle">
                <h4 className="title_dt">
                  {this.state.HotelDetails.property_name}
                </h4>
                <span className="stars_dt">{this.starsCount()}</span>
              </div>

              <div className="GalleryHeader">
                <div className="GalleryImg">
                  <div className="GalleryBig_img">
                    <LazyLoadImage
                      src={images[0]}
                      className="Gallery_images_Large"
                      style={{
                        borderTopLeftRadius: 15,
                        borderBottomLeftRadius: 15,
                        height: "350px",
                      }}
                      onClick={() => this.setImagePhotoIndex(0)}
                      placeholderSrc={
                        process.env.PUBLIC_URL + "images/placeholder.png"
                      }
                      key={0}
                    />
                  </div>
                  <div className="GallerySmall_img">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="GallerySmallerSection"
                        style={{ paddingRight: 5, paddingBottom: 5 }}
                      >
                        {images.length < 1 ? (
                          <img
                            src="images/placeholder.png"
                            className="Gallery_images_Small"
                            onClick={() => this.setImagePhotoIndex(1)}
                            key={1}
                          />
                        ) : (
                          <LazyLoadImage
                            src={images[1]}
                            className="Gallery_images_Small"
                            onClick={() => this.setImagePhotoIndex(1)}
                            placeholderSrc={
                              process.env.PUBLIC_URL + "images/placeholder.png"
                            }
                            key={1}
                          />
                        )}
                      </div>
                      <div
                        className="GallerySmallerSection"
                        style={{ paddingRight: 5, paddingTop: 5 }}
                      >
                        {images.length < 2 ? (
                          <img
                            src="images/placeholder.png"
                            className="Gallery_images_Small"
                            onClick={() => this.setImagePhotoIndex(2)}
                            key={2}
                          />
                        ) : (
                          <LazyLoadImage
                            src={images[2]}
                            className="Gallery_images_Small"
                            onClick={() => this.setImagePhotoIndex(2)}
                            placeholderSrc={
                              process.env.PUBLIC_URL + "images/placeholder.png"
                            }
                            key={2}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="GallerySmallerSection"
                        style={{ paddingLeft: 5, paddingBottom: 5 }}
                      >
                        {images.length < 3 ? (
                          <img
                            src="images/placeholder.png"
                            className="Gallery_images_Small"
                            onClick={() => this.setImagePhotoIndex(3)}
                            key={3}
                          />
                        ) : (
                          <LazyLoadImage
                            src={images[3]}
                            className="Gallery_images_Small"
                            onClick={() => this.setImagePhotoIndex(3)}
                            placeholderSrc={
                              process.env.PUBLIC_URL + "images/placeholder.png"
                            }
                            key={3}
                          />
                        )}
                      </div>
                      <div
                        className="GallerySmallerSection"
                        style={{ paddingLeft: 5, paddingTop: 5 }}
                      >
                        {images.length < 4 ? (
                          <img
                            src="images/placeholder.png"
                            className="Gallery_images_Small"
                            onClick={() => this.setImagePhotoIndex(4)}
                            key={4}
                          />
                        ) : (
                          <div class="card text-bg-dark" 
                          onClick={() => this.setImagePhotoIndex(4)}>
                            <LazyLoadImage
                              src={images[4]}
                              className="Gallery_images_Small"
                              placeholderSrc={
                                process.env.PUBLIC_URL + "images/placeholder.png"
                              }
                              key={4}
                            />
                            {images.length > 4 && <div class="card-img-overlay  d-flex justify-content-center align-items-center bg-dark bg-opacity-50 m-0">
                              <p class="text-center h5 m-0 text-white">+{images.length - 4} Photos</p>
                            </div>}
                          </div>

                        )}
                        {/* <div className="Image_showAll_btn">
                          <p className="Image_showAll_content">
                            {" "}
                            Show all photos ({images.length}){" "}
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.isOpenGallery && (
                <Lightbox
                  mainSrc={images[this.state.photoIndex]}
                  nextSrc={images[(this.state.photoIndex + 1) % images.length]}
                  prevSrc={
                    images[
                      (this.state.photoIndex + images.length - 1) %
                        images.length
                    ]
                  }
                  onCloseRequest={() => this.setState({ isOpenGallery: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (this.state.photoIndex + images.length - 1) %
                        images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (this.state.photoIndex + 1) % images.length,
                    })
                  }
                  wrapperClassName="ImageModal"
                />
              )}
              {/* <Gallery images={IMAGES} maxRows={6} rowHeight={230} /> */}
            </div>

            <div
              className="container"
              style={{ marginTop: 30, maxWidth: "1210px", marginBottom: 40 }}
            >
              <div className="aboutProp">
                <div className="aboutProp__header">
                  <div className="aboutProp__left">
                    <p className="txtHeading font22 latoBlack blackText">
                      About {this.state.HotelDetails.property_name}
                    </p>

                    <p className="font15 appendBottom12">
                      CHECK IN{" "}
                      <span className="latoBlack">
                        {this.state.HotelDetails.check_in.split(":")[0] < 12
                          ? this.state.HotelDetails.check_in + " AM"
                          : this.state.HotelDetails.check_in.split(":")[0] -
                              12 ==
                            0
                          ? "12" +
                            ":" +
                            this.state.HotelDetails.check_in.split(":")[1] +
                            " PM"
                          : this.state.HotelDetails.check_in.split(":")[0] +
                            ":" +
                            this.state.HotelDetails.check_in.split(":")[1] +
                            " PM"}
                      </span>{" "}
                      <span className="dot"></span> CHECK OUT{" "}
                      <span className="latoBlack">
                        {this.state.HotelDetails.check_out.split(":")[0] < 12
                          ? this.state.HotelDetails.check_out + " AM"
                          : this.state.HotelDetails.check_out.split(":")[0] -
                              12 ==
                            0
                          ? "12" +
                            ":" +
                            this.state.HotelDetails.check_out.split(":")[1] +
                            " PM"
                          : this.state.HotelDetails.check_out.split(":")[0] +
                            ":" +
                            this.state.HotelDetails.check_out.split(":")[1] +
                            " PM"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="aboutProp__desc">
                  <div className="lineHight20 aboutDesc">
                    <span>
                      {this.state.HotelDetails.property_source == 1 ? (
                        this.state.JuniperDesc != [] ? (
                          this.state.JuniperDesc.map((item, key) => (
                            <p>{ReactHtmlParser(item.description)}</p>
                          ))
                        ) : (
                          <> </>
                        )
                      ) : (
                        <p>
                          {ReactHtmlParser(
                            this.state.HotelDetails.property_description
                          )}
                        </p>
                      )}
                    </span>
                  </div>
                </div>
                {/* <div className="font15 appendBottom12"><span>Cafe - This is testing Data</span><span>1 KM</span></div> */}
              </div>
            </div>
            {this.state.HotelDetails.property_source != 1 &&(<>
            <p className="txtHeading font18 latoBlack blackText" style={{marginLeft: '13%', marginBottom: '2px'}}>Nearby Area:</p>
            <div class="abpp__bottom--left appendRight10 nearbyleft">
            <ul>
           
            {this.state.Neighbourhoodareas && this.state.Neighbourhoodareas.map((newitem, key2) => (
              <li><span className="latoBlack latoblacknearby">{newitem.neighbourhood_category}: </span>&nbsp; <p>{newitem.value} ({newitem.distance})</p> </li>
              ))}
              </ul>
              </div>
            </>)}

            <div className="_navSticky navSticky appendBottom15">
              <ul>
                <li>
                  <Link
                    activeClass="active"
                    to="Rates"
                    spy={true}
                    smooth={true}
                    duration={100}
                    offset={0}
                    onSetActive={this.handleSetActive}
                    onSetInactive={this.handleSetInactive}
                    ignoreCancelEvents={false}
                  >
                    Rooms Options
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="faculty"
                    spy={true}
                    smooth={true}
                    duration={100}
                    offset={0}
                    onSetActive={this.handleSetActive}
                    onSetInactive={this.handleSetInactive}
                    ignoreCancelEvents={false}
                  >
                    Amenities
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="Maps"
                    spy={true}
                    smooth={true}
                    duration={100}
                    offset={0}
                    onSetActive={this.handleSetActive}
                    onSetInactive={this.handleSetInactive}
                    ignoreCancelEvents={false}
                  >
                    Location
                  </Link>
                </li>
                {/* <li>
                  <Link
                    activeClass="active"
                    to="Reviews"
                    spy={true}
                    smooth={true}
                    duration={100}
                    offset={0}
                    onSetActive={this.handleSetActive}
                    onSetInactive={this.handleSetInactive}
                    ignoreCancelEvents={false}
                  >
                    Review
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className="container" style={{ maxWidth: "1210px" }}>
              <div id="Rates">
                {this.state.HotelDetails.property_source == 1 ? (
                  <>
                    {" "}
                    {this.state.EJuniper != [] ? (
                      <>
                        {" "}
                        {this.state.EJuniper.map((item, key) => (
                          <RatesAndDetails
                            propertyName={this.state.HotelDetails.property_name}
                            eJuniper={item}
                            hs_portal="no_hs_portal"
                            hotel_link="no_hotel_link"
                            HotelDetails={this.state.HotelDetails}
                            querySearching={this.state.querySe}
                            HotelImage={images[0]}
                            roomDescription={this.state.roomDescription}
                            CartIndexNumber = {this.state.CartIndexNumber}
                            propertyCheckin = {this.state.HotelDetails.check_in.split(":")[0] < 12
                            ? this.state.HotelDetails.check_in + " AM"
                            : this.state.HotelDetails.check_in.split(":")[0] -
                                12 ==
                              0
                            ? "12" +
                              ":" +
                              this.state.HotelDetails.check_in.split(":")[1] +
                              " PM"
                            : this.state.HotelDetails.check_in.split(":")[0] +
                              ":" +
                              this.state.HotelDetails.check_in.split(":")[1] +
                              " PM"}
                              propertyCheckout = {this.state.HotelDetails.check_out.split(":")[0] < 12
                              ? this.state.HotelDetails.check_out + " AM"
                              : this.state.HotelDetails.check_out.split(":")[0] -
                                  12 ==
                                0
                              ? "12" +
                                ":" +
                                this.state.HotelDetails.check_out.split(":")[1] +
                                " PM"
                              : this.state.HotelDetails.check_out.split(":")[0] +
                                ":" +
                                this.state.HotelDetails.check_out.split(":")[1] +
                                " PM"}
                                description = {this.state.JuniperDesc[0]}
                                SupplierUrl = {SupplierUrl}

                          ></RatesAndDetails>
                        ))}
                      </>
                    ) : (
                      <> </>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    {this.state.HotelDetails.property_source == 2 ? (
                      <>
                        {" "}
                        {this.state.HotelLink != [] ? (
                          <>
                            {" "}
                            {Object.keys(this.state.HotelLink).map((key) => (
                              <RatesAndDetails
                                propertyName={
                                  this.state.HotelDetails.property_name
                                }
                                eJuniper="no_ejuniper"
                                hs_portal="no_hs_portal"
                                hotel_link={this.state.HotelLink[key]}
                                HotelDetails={this.state.HotelDetails}
                                querySearching={this.state.querySe}
                                HotelImage={images[0]}
                                roomDescription="no_descr"
                                CartIndexNumber = {this.state.CartIndexNumber}
                                propertyCheckin = {this.state.HotelDetails.check_in.split(":")[0] < 12
                                ? this.state.HotelDetails.check_in + " AM"
                                : this.state.HotelDetails.check_in.split(":")[0] -
                                    12 ==
                                  0
                                ? "12" +
                                  ":" +
                                  this.state.HotelDetails.check_in.split(":")[1] +
                                  " PM"
                                : this.state.HotelDetails.check_in.split(":")[0] +
                                  ":" +
                                  this.state.HotelDetails.check_in.split(":")[1] +
                                  " PM"}
                                  propertyCheckout = {this.state.HotelDetails.check_out.split(":")[0] < 12
                                  ? this.state.HotelDetails.check_out + " AM"
                                  : this.state.HotelDetails.check_out.split(":")[0] -
                                      12 ==
                                    0
                                  ? "12" +
                                    ":" +
                                    this.state.HotelDetails.check_out.split(":")[1] +
                                    " PM"
                                  : this.state.HotelDetails.check_out.split(":")[0] +
                                    ":" +
                                    this.state.HotelDetails.check_out.split(":")[1] +
                                    " PM"}
                                    description = {this.state.HotelDetails.property_description}
                                    SupplierUrl = {SupplierUrl}
                               
                              ></RatesAndDetails>
                            ))}
                          </>
                        ) : (
                          <> </>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        {this.state.HSPortal != [] ? (
                          <>
                            {" "}
                            {Object.keys(this.state.HSPortal).map((key) => (
                              <RatesAndDetails
                                propertyName={
                                  this.state.HotelDetails.property_name
                                }
                                eJuniper="no_ejuniper"
                                hotel_link="no_hotel_link"
                                hs_portal={this.state.HSPortal[key]}
                                HotelDetails={this.state.HotelDetails}
                                querySearching={this.state.querySe}
                                HotelImage={images[0]}
                                roomDescription="no_descr"
                                CartIndexNumber = {this.state.CartIndexNumber}
                                propertyCheckin = {this.state.HotelDetails.check_in.split(":")[0] < 12
                                ? this.state.HotelDetails.check_in + " AM"
                                : this.state.HotelDetails.check_in.split(":")[0] -
                                    12 ==
                                  0
                                ? "12" +
                                  ":" +
                                  this.state.HotelDetails.check_in.split(":")[1] +
                                  " PM"
                                : this.state.HotelDetails.check_in.split(":")[0] +
                                  ":" +
                                  this.state.HotelDetails.check_in.split(":")[1] +
                                  " PM"}
                                  propertyCheckout = {this.state.HotelDetails.check_out.split(":")[0] < 12
                                  ? this.state.HotelDetails.check_out + " AM"
                                  : this.state.HotelDetails.check_out.split(":")[0] -
                                      12 ==
                                    0
                                  ? "12" +
                                    ":" +
                                    this.state.HotelDetails.check_out.split(":")[1] +
                                    " PM"
                                  : this.state.HotelDetails.check_out.split(":")[0] +
                                    ":" +
                                    this.state.HotelDetails.check_out.split(":")[1] +
                                    " PM"}
                                    description = {this.state.HotelDetails.property_description}
                                    SupplierUrl = {SupplierUrl}
                              ></RatesAndDetails>
                            ))}{" "}
                          </>
                        ) : (
                          <> </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="container" style={{ maxWidth: "1210px" }}>
              <div id="faculty" className="Text-Details">
                <h4 className="txtHeading font22 latoBlack blackText textHeader">
                  Facilities in {this.state.HotelDetails.property_name}
                </h4>

                <div className="jCollA">
                  {this.state.HotelServices.map((item, key) =>
                    item == null ? (
                      <></>
                    ) : (
                      item.head_name.map((item1, key1) => (
                        <div className="KuomZ">
                          <p className="latoBold font12 blackText capText appendBottom15">
                            {item1.head_name}
                          </p>
                          <ul className="facilityLists font13 dqqjQn">
                          {this.state.showMore? (<>{item1.data.map((dat, key2) => (
                              <li>
                                <span className="sprite facilityTickIcon appendRight10"></span>
                                <span className="facilityName">{dat}</span>
                              </li>
                            ))}</>):(<> {item1.data.slice(0,10).map((dat, key2) => (
                              <li>
                                <span className="sprite facilityTickIcon appendRight10"></span>
                                <span className="facilityName">{dat}</span>
                              </li>
                            ))}</>)}
                           
                           {item1.data.length >10 &&(this.state.showMore?(<><button onClick={()=> this.handleClickLess()}>Show less</button></>):(<button onClick={()=> this.handleClick()}>Show more</button>))}
                          </ul>
                        </div>
                      ))
                    )
                  )}
                </div>
              </div>
              </div>
           
              <div className="container" style={{ maxWidth: "1210px" }}>
              <div id="Maps" className="google-map Text-Details">
                <h4 className="txtHeading font22 latoBlack blackText textHeader">
                  Location
                </h4>
                <div className="GoogleMapLo">
                  <div className="GoogleInner">
                    <GoogleMap
                      lat={this.state.HotelDetails.latitude}
                      lng={this.state.HotelDetails.longitude}
                      src={images[0]}
                      ProDetails={this.state.HotelDetails}
                    />
                  </div>
                </div>
              </div>

              {/* <section
                id="Reviews"
                className="page-section"
                style={{ marginTop: 50 }}
              >
                <div className="spaceBetween appendBottom15">
                  <h2 className="txtHeading font22 latoBlack blackText textHeader">
                    Verified Ratings &amp; Reviews for{" "}
                    {this.state.HotelDetails.property_name}
                  </h2>
                </div>
                <div className="TAReviewTop">
                  <div className="TAReviewLeft">
                    <div className="ratingBox greenBgTA">
                      <div className="ratingBoxTop whiteText">
                        <span>4.5</span>
                        <span className="font12">2787 REVIEWS</span>
                      </div>
                      <div className="ratingBoxBottom">
                        <span className="sprite taratingIconNew"></span>
                        <span className="ratingNew">
                          <Ratings
                            rating={4.5}
                            widgetDimensions="14px"
                            widgetSpacings="0px"
                          >
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                          </Ratings>
                        </span>
                      </div>
                    </div>
                    <div className="flexOne">
                      <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                        <span className="latoBold font11 capText">
                          EXCELLENT
                        </span>
                        <span className="spacing"></span>
                        <span className="font10">2218 REVIEWS</span>
                      </p>
                      <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                        <span className="latoBold font11 capText">
                          VERY GOOD
                        </span>
                        <span className="spacing"></span>
                        <span className="font10">388 REVIEWS</span>
                      </p>
                      <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                        <span className="latoBold font11 capText">AVERAGE</span>
                        <span className="spacing"></span>
                        <span className="font10">98 REVIEWS</span>
                      </p>
                      <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                        <span className="latoBold font11 capText">POOR</span>
                        <span className="spacing"></span>
                        <span className="font10">55 REVIEWS</span>
                      </p>
                      <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                        <span className="latoBold font11 capText">BAD</span>
                        <span className="spacing"></span>
                        <span className="font10">28 REVIEWS</span>
                      </p>
                    </div>
                  </div>
                  <div className="TAReviewRight">
                    <ul className="font12">
                      <li id="categoryItem_0">
                        <span>
                          <Ratings
                            rating={5}
                            widgetDimensions="14px"
                            widgetSpacings="0px"
                          >
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                          </Ratings>
                        </span>
                        <span className="ratingName">LOCATION</span>
                      </li>
                      <li id="categoryItem_1">
                        <span>
                          <Ratings
                            rating={4.5}
                            widgetDimensions="14px"
                            widgetSpacings="0px"
                          >
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                          </Ratings>
                        </span>
                        <span className="ratingName">SLEEP</span>
                      </li>

                      <li id="categoryItem_2">
                        <span>
                          <Ratings
                            rating={5}
                            widgetDimensions="14px"
                            widgetSpacings="0px"
                          >
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                          </Ratings>
                        </span>
                        <span className="ratingName">ROOM</span>
                      </li>
                      <li id="categoryItem_3">
                        <span>
                          <Ratings
                            rating={4}
                            widgetDimensions="14px"
                            widgetSpacings="0px"
                          >
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                          </Ratings>
                        </span>
                        <span className="ratingName">SERVICE</span>
                      </li>
                      <li id="categoryItem_4">
                        <span>
                          <Ratings
                            rating={5}
                            widgetDimensions="14px"
                            widgetSpacings="0px"
                          >
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                            <Ratings.Widget widgetRatedColor="#deba56" />
                          </Ratings>
                        </span>
                        <span className="ratingName">VALUE FOR MONEY</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="TAReviewRow" id="ta_reviewItem_0">
                  <h4 className="latoBold font18 yellowText">
                    Idyllic Honeymoon
                    <span style={{ marginLeft: 10 }}>
                      <Ratings
                        rating={5}
                        widgetDimensions="14px"
                        widgetSpacings="0px"
                      >
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                      </Ratings>
                    </span>
                  </h4>
                  <p className="grayText appendBottom10">
                    Alexhotel6, Apr 03, 2021
                  </p>
                  <p className="font16 lineHight22">
                    We spent our Honeymoon week at the exceptional Lemuria
                    Hotel. First of all the check-in experience made by Eva
                    (Guest Relation Manager) and her team is the perfect
                    definition of an experience. We were speechless immediately
                    after our first minutes at the Resort. Everything was noted,
                    repeated to make sure that our schedule of the week was
                    correct. They offered us immediately seated a welcome drink
                    and we were escorted to our amazing Senior Suite with a lof
                    of gifts waiting for us in the room and the bed. It was the
                    most incredible check-in experience I had in my luxury
                    travel life. Discovering the resort with its fabulous
                    restaurant locations, SPA, Golf course is an evasion inside
                    your trip. The hotel has 3 exceptional beaches and Lindsay
                    the beach boy from Day 1 took care of us for the entire week
                    - thanks to him for his attention and service. You need to
                    meet Jerome - Head Sommelier - and his great team and
                    discover the wine tasting experience in the huge private
                    wine cellar. We had the chance to be pampered by Eva and her
                    team with daily special attention and that is very unique.
                    Special thanks as well to Mita - waitress at the Nest
                    Restaurant - for her service and hospitality during our
                    private sunset diner at On The Rocks (Experience to book in
                    advance as well... I would like to thank you Mr Le Gac -
                    General Manager - and his entire team for this dream we will
                    never forget. By far the most beautiful hotel of the
                    Seychelles. A place to visit with no doubt for its luxury
                    accomodation, perfect loction on the beach and for its
                    personallized service. The Lemuria is the perfect reflect of
                    LHW reputation. Thank you and congratulations for your
                    incredible work.
                  </p>
                </div>

                <div className="TAReviewRow" id="ta_reviewItem_2">
                  <h4 className="latoBold font18 yellowText">
                    The wine dinner was absolutely the highlight of our stay!
                    <span style={{ marginLeft: 10 }}>
                      <Ratings
                        rating={5}
                        widgetDimensions="14px"
                        widgetSpacings="0px"
                      >
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                      </Ratings>
                    </span>
                  </h4>
                  <p className="grayText appendBottom10">
                    dimamah, COUPLES Apr 01, 2021
                  </p>

                  <p className="font16 lineHight22">
                    You can read a lot about the hotel in other reviews, I'll
                    focus specifically on the wine dinner in the constance wine
                    cellar. The dinner is absolutely a must! You get a 5 course
                    meal, the food quality is simply above and beyond, it was
                    easily the best meal in seychelles, and could probably be
                    served in any top quality restaurant in France. But that was
                    just the beginning , the real start of the night was our
                    sommelier Jerome, he is very, very, professional and did an
                    amazing job pairing top notch wines to every dish. We drank
                    5 extremely generous glasses of the finest french wines.
                    Even thought the dinner isn't cheap, when you look at the
                    value for money, it's really worth it. Now back to Jerome,
                    this guy really made the dinner, he is sooo fun, just
                    hearing him explain about the food and wine makes it much
                    tastier.Do not miss this!
                  </p>
                </div>

                <div className="TAReviewRow" id="ta_reviewItem_3">
                  <h4 className="latoBold font18 yellowText">
                    Perfect wedding destination
                    <span style={{ marginLeft: 10 }}>
                      <Ratings
                        rating={5}
                        widgetDimensions="14px"
                        widgetSpacings="0px"
                      >
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                      </Ratings>
                    </span>
                  </h4>
                  <p className="grayText appendBottom10">
                    Broussova, Mar 30, 2021
                  </p>
                  <p className="font16 lineHight22">
                    It is a beautiful hotel set on stunning grounds with world
                    className golf course. We were lucky that Kheshma has
                    answered our query regarding wedding venues at the hotel and
                    thanks to her she made our choice very easy. She helped us
                    organise our perfect wedding day at Constance Lemuria. The
                    whole team made our stay and wedding day so special,
                    memorable and perfect in every single way, we couldn't be
                    more thankful. We had private candlelit dinners on the
                    beach, breakfast on the rocks, sunset drinks and rehearsal
                    evening on the edge of the mountain, all organised by the
                    team as special treats. The team was thoughtful, careing and
                    very attentive. We will definitely come back to visit
                    again.Oh and do enquire about helicopter transfers to/from
                    the resort!
                  </p>
                </div>

                <div className="TAReviewRow" id="ta_reviewItem_4">
                  <h4 className="latoBold font18 yellowText">
                    Chefs' Teasing and Tantalizing
                    <span style={{ marginLeft: 10 }}>
                      <Ratings
                        rating={5}
                        widgetDimensions="14px"
                        widgetSpacings="0px"
                      >
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                        <Ratings.Widget widgetRatedColor="#deba56" />
                      </Ratings>
                    </span>
                  </h4>
                  <p className="grayText appendBottom10">
                    sofarsogood94, Mar 27, 2021
                  </p>
                  <p className="font16 lineHight22">
                    We have enjoyed many a fine dinner over the years at
                    Lemuria's Diva Restaurant, but we had not visited for quite
                    some time due to Covid restrictions. With the Seychelles
                    reopening to the world, we jumped at the first chance we
                    got, although I do admit that I had some hesitation - would
                    the standards still be what they used to be or would the
                    team have grown rusty? We were pleasantly surprised. There
                    was a lovely ambience in the restaurant with a good number
                    of other clients, mainly repeat guests, who had also flocked
                    back at the first opportunity they got. Service was great -
                    Emma took great care of us as usual. We felt safe as
                    excellent protocols seemed to be in place. What blew us away
                    though, and prompted this review and the heading, was the
                    food. Now, I expect excellent food in a top establishment
                    that charges accordingly, but I did not expect just how much
                    fun the kitchen team seemed to be having in creating and
                    presenting dishes that were there to tease and tantalise. So
                    much energy and creativity was put into the dishes we
                    ordered. I am a little reluctant to mention the treats they
                    served, as I fear it may detract from the surprise of those
                    who follow, but three items stood out: an amuse-bouche of
                    caviar ice-cream served in a mini-cone; the presentation of
                    the scallops dish we ordered, and the sorbet with popping
                    candy. Each one a delight to the eye and mouth. The truffle
                    risotto my wife ordered was one of the best she has ever
                    had. This was all "washed-down" with a bottle of fine
                    champagne - an Egly-Ouriet - which is the first time I have
                    tried one of these. A lovely evening, made all the more
                    special for us because we have not been able to enjoy
                    outings like this as often as we would wish to do in these
                    strange times, but also because of the appreciation we felt
                    for the huge amount of effort and shear zest the team put
                    into the dinner, not just for us, but for all the tables
                    around us too. There was no sight of any rusty team members
                    nor of disrupted supply chains. Hats off Lemuria. We will be
                    back.
                  </p>
                </div>
              </section> */}
            </div>
          </>
        )}
         <TawkToWidget />
      </div>
    );
  }
}

export default HotelDetails;
