import React, { useState } from "react";
import "./Superoffers.css";
import { Tripurl } from "./axios";
import LoginComponent from "./Auth/LoginComponent";

const MakeYourTrip = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const loginData = JSON.parse(localStorage.getItem("userDetails")) || null;

  const onOpenLogin = () => setIsLoginOpen(true);
  const onCloseLogin = () => setIsLoginOpen(false);

  return (
    <>
      <div className="mb-4">
        <div className="container">
          <div className="buildBackgroundImg BuildlandingCard">
            <div className="wid-pages">
              <h2>
                Build your Own Trip: Trips made easier for everyone, everywhere
              </h2>
              <p>
                Create fully customized day by day itinerary of places to stay
                and things to do, transfers during your Seychelles Travels. You
                can save your trip plan so you can keep working on it later or
                finalized it and book your trip.
              </p>
              <div className="text-center">
                {loginData ? (
                  <a
                    className="btn_1 rounded h5 fw-bold"
                    href={`${Tripurl}/hstotriplogin/${btoa(loginData.email)}`}
                  >
                    Start Planning
                  </a>
                ) : (
                  <button
                    className="btn_1 rounded h5 fw-bold"
                    onClick={onOpenLogin}
                  >
                    Start Planning
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginComponent
        open={isLoginOpen}
        onClose={onCloseLogin}
        onOpen={onOpenLogin}
        onSuccess={(email) =>
          window.location.replace(`${Tripurl}/hstotriplogin/${btoa(email)}`)
        }
      />
    </>
  );
};

export default MakeYourTrip;
