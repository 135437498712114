import React from 'react';
class BannerTabNav extends React.Component {
    render() {
        return (
            <nav>
                <ul className="font12">
                    {
                        this.props.tabs.map((tab, index) => {
                            const active = (tab === this.props.selected ? ' active' : '');
                            const tabClassSecond = (tab === 'Liveaboard' ? 'chBuses ' : 'chSprite ');
                            const tabClass = (tab === 'Accommodations' ? 'chHotels' : '');
                            const tabClass1 = (tab === 'Attractions & Things to Do' ? 'chHomestays' : '');
                            const tabClass2 = (tab === 'Packages' ? 'chHolidays' : '');
                            const tabClass3 = (tab === 'Land & Ferry Transfers' ? 'chTrains' : '');

                            return (
                                <li key={`services-${index}`} className="menu_Hotels" onClick={() => this.props.setSelectedTab(tab)}>
                                    <a className=" hsFlex_Bann hsCenter column">
                                        <span className={"chNavIcon appendBottom2 " + tabClassSecond + tabClass + tabClass1 + tabClass2 + tabClass3}></span>
                                        <span className={"fw-bold darkGreyText" + active}> {tab} </span>
                                    </a>
                                </li>
                            );
                        })
                    }
                </ul>
            </nav>
        );
    }
}
export default BannerTabNav;