import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import moment from "moment";
import SignUp from "../SignUp";
import {
  setCustomerLogin,
  LoginConfirmation,
  getGeoInfo,
  ConfirmationForgot,
} from "../axios";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const LoginComponent = ({ open, onClose, onOpen, onSuccess }) => {
  const [forgotModalIsOpen, setForgotModalIsOpen] = useState(false);
  const [changeLoginScreenClass, setChangeLoginScreenClass] = useState(
    "containerLogin"
  );
  const [facebookLogin, setFacebookLogin] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [passwordLoginError, setPasswordLoginError] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [errorForgotEmail, setErrorForgotEmail] = useState(false);
  const [forgotEmailError, setForgotEmailError] = useState(false);
  const [geoInfo, setGeoInfo] = useState({
    country: "",
    country_code: "",
    currency: "",
    ip_address: "",
  });

  useEffect(() => {
    getGeoInfo().then(
      ({ country_name, country_calling_code, currency, ip }) => {
        setGeoInfo({
          country: country_name,
          country_code: country_calling_code,
          currency: currency,
          ip_address: ip,
        });
      }
    );
  }, []);

  const openSecondModal = () => {
    onClose();
    setForgotModalIsOpen(true);
  };

  const closeSecondModal = () => setForgotModalIsOpen(false);

  const LoginFaceBook = () => setFacebookLogin(true);

  const changeLoginScreen = () =>
    setChangeLoginScreenClass("containerLogin right-panel-active");

  const changeLoginReverse = () => setChangeLoginScreenClass("containerLogin");

  const changeLoginEmail = ({ target: { value } }) => {
    const email = validateEmail(value);
    setLoginEmail(value);
    setEmailError(email == true ? false : true);
  };

  const changeLoginPassword = ({ target: { value } }) => {
    setLoginPassword(value);
    if (value.length > 5 && value.length < 15) {
      setPasswordLoginError(false);
    } else {
      setPasswordLoginError(true);
    }
  };

  const ForgotConfirm = (e) => {
    e.preventDefault();
    let CheckEmailError = false;
    let emailNew = forgotEmail;

    const email = validateEmail(emailNew);
    let emailNewData = "";
    if (email == true) {
      emailNewData = emailNew;
      setForgotEmailError(false);
      CheckEmailError = true;
    } else {
      CheckEmailError = false;
      setForgotEmailError(true);
    }

    if (CheckEmailError == true) {
      ConfirmationForgot(emailNewData).then((data) => {
        if (data == "not_correct") {
          setErrorForgotEmail(true);
        } else {
          setTimeout(() => {
            window.location.reload(true);
          }, 2000);
        }
      });
    }
  };

  const ChangeForgotEmail = ({ target: { value } }) => {
    const email = validateEmail(value);
    setForgotEmail(email);
    if (email == true) {
      setForgotEmailError(false);
    } else {
      setForgotEmailError(true);
    }
  };

  const handleSocialLogin = ({
    first_name,
    last_name,
    email,
    profile_image,
    birthday,
    gender,
    login_source,
    mobile_number,
    country,
    country_code,
    currency,
    ip_address,
    password,
    verified,
  }) => {
    const dataSearch = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      country_code: country_code,
      verified: verified,
      uid: "",
    };

    let JsonData = JSON.stringify(dataSearch);

    localStorage.setItem("userDetails", JsonData);

    setCustomerLogin(
      first_name,
      last_name,
      email,
      profile_image,
      birthday,
      gender,
      login_source,
      mobile_number,
      country,
      country_code,
      currency,
      ip_address,
      password,
      verified
    ).then((data) => {
      if (data.id > 0) {
        if (onSuccess) {
          onSuccess(email);
        } else {
          window.location.reload(true);
        }
      }
    });
    onClose();
  };

  const responseFacebook = (response) => {
    if (response.accessToken) {
      let first_name = response.first_name;
      let last_name = response.last_name;
      let email = response.email;
      let profile_image = response.picture.data.url;
      let birthday = moment(response.birthday).format("Y-MM-DD");
      let gender = response.gender;
      let login_source = 1;
      let mobile_number = "";
      let country = geoInfo.country;
      let country_code = geoInfo.country_code;
      let currency = geoInfo.currency;
      let ip_address = geoInfo.ip_address;
      let password = "";
      let verified = true;

      handleSocialLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country,
        country_code,
        currency,
        ip_address,
        password,
        verified
      );
    }
  };

  const responseGoogle = (response) => {
    if (response.accessToken) {
      let first_name = response.profileObj.givenName;
      let last_name = response.profileObj.familyName;
      let email = response.profileObj.email;
      let profile_image = response.profileObj.imageUrl;
      let birthday = "";
      let gender = "";
      let login_source = 2;
      let mobile_number = "";
      let country = geoInfo.country;
      let country_code = geoInfo.country_code;
      let currency = geoInfo.currency;
      let ip_address = geoInfo.ip_address;
      let password = "";
      let verified = true;

      handleSocialLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country,
        country_code,
        currency,
        ip_address,
        password,
        verified
      );
    }
  };

  const LoginConfirm = (e) => {
    e.preventDefault();
    let CheckEmailError = false;
    let CheckPasswordLoginError = false;
    setEmailError(false);
    setPasswordLoginError(false);
    setErrorPassword(false);
    let emailNew = loginEmail;

    const email = validateEmail(emailNew);
    let emailNewData = "";
    if (email == true) {
      emailNewData = emailNew;
      setEmailError(false);
      CheckEmailError = true;
    } else {
      CheckEmailError = false;
      setEmailError(true);
    }

    let password = loginPassword;

    if (password.length > 5 && password.length < 15) {
      CheckPasswordLoginError = true;

      setPasswordLoginError(false);
    } else {
      CheckPasswordLoginError = false;

      setPasswordLoginError(true);
    }
    if (CheckEmailError == true && CheckPasswordLoginError == true) {
      setLoginLoader(true);
      LoginConfirmation(emailNewData, password)
        .then((data) => {
          if (data == "not_correct") {
            setErrorPassword(true);
          } else {
            let Verified = "";
            if (data.verified == 1) {
              Verified = true;
            }

            const dataSearch = {
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              country_code: data.country_code,
              verified: Verified,
              uid: data.id,
            };

            let JsonData = JSON.stringify(dataSearch);

            localStorage.setItem("userDetails", JsonData);
            if (onSuccess) {
              onSuccess(data.email);
            } else {
              window.location.reload(true);
            }
          }
        })
        .then(() => setLoginLoader(false));
    }
  };

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={onClose}
        contentLabel="Create Account"
        className="LoginModal"
      >
        <div className={changeLoginScreenClass}>
          <div className="form-container sign-up-container">
            <form className="FormCon">
              <h1>Create Account</h1>
              <SignUp
                country_code={geoInfo.country_code}
                currency={geoInfo.currency}
                ip_address={geoInfo.ip_address}
                country={geoInfo.country}
              />
            </form>
          </div>
          {facebookLogin && (
            <FacebookLogin
              // appId="426993597895758"
              appId="1194539465165185"
              autoLoad={true}
              fields="first_name, last_name, email, picture, birthday,gender"
              scope="public_profile, email, user_birthday,user_gender"
              returnScopes={true}
              callback={responseFacebook}
            />
          )}

          <div className="form-container sign-in-container">
            <form className="FormCon">
              <h1>LOG IN</h1>
              {errorPassword && (
                <p className="error"> Email or Password is not correct !</p>
              )}
              <input
                type="email"
                placeholder="Email"
                value={loginEmail}
                onChange={changeLoginEmail}
              />
              {emailError && (
                <p className="error"> Please enter a valid Email ID!</p>
              )}
              <input
                type="password"
                placeholder="Password"
                value={loginPassword}
                onChange={changeLoginPassword}
              />
              {passwordLoginError && (
                <p className="error">
                  The password that you've entered is incorrect
                </p>
              )}

              <a className="cursor-pointer" onClick={openSecondModal}>
                Forgot your password?
              </a>
              <button
                onClick={LoginConfirm}
                disabled={loginLoader}
              >
                LOG IN
              </button>
              <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                <div
                  className="SocialmakeFlex fbLoginBtn flexOne"
                  onClick={LoginFaceBook}
                >
                  <span className=" popupSprite popupFbIcon"></span>
                  <span
                    className="font14 hs-white latoBold latoBold"
                    style={{ textTransform: "uppercase" }}
                  >
                    Login with Facebook
                  </span>
                </div>
              </div>
              <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                <div className="SocialmakeFlex googleLoginBtn flexOne">
                  <span style={{ width: "100%" }}>
                    <GoogleLogin
                      clientId="445734814791-opepjrj50d5gdimufcicecofrl7rvb90.apps.googleusercontent.com"
                      buttonText="Login with Google"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <button className="ghost" onClick={changeLoginReverse}>
                  LOG IN
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
                <button className="ghost" onClick={changeLoginScreen}>
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={forgotModalIsOpen}
        onRequestClose={closeSecondModal}
        contentLabel="Forgot Password"
      >
        <div className={changeLoginScreenClass}>
          <div className="form-container sign-in-container">
            <form className="FormCon">
              <h1>Forgot Password</h1>
              {errorForgotEmail && (
                <p className="error"> Email is not correct !</p>
              )}
              <input
                type="email"
                placeholder="Email"
                value={forgotEmail}
                onChange={ChangeForgotEmail}
              />
              {forgotEmailError && (
                <p className="error"> Email is not valid !</p>
              )}
              <button onClick={ForgotConfirm}>Submit</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1>Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <button
                  className="ghost"
                  onClick={() => {
                    closeSecondModal();
                    onOpen();
                  }}
                >
                  LOG IN
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LoginComponent;
