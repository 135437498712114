import React from "react";
import { Link } from "react-router-dom";
import TawkToWidget from "./TawkToWidget";

const GridContainer = ({ size, heading, children }) => {
  return <div className={`col-md-${size} col-12 mb-3 text-center text-md-start`}>
    <div className="h5 text-decoration-underline">{heading}</div>
    {children}
  </div>
}

const FooterLinks = ({ data }) => {
  if (!data) return null;

  return (
    <>
      <ul className="links">
        {data.map((item, index) => (
          <li key={index}>
            <Link to={item.link}>{item.text}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

// const SocialLinks = () => {
//   const socialLinksData = [
//     { icon: "facebook", link: "#" },
//     { icon: "twitter", link: "#" },
//     { icon: "instagram", link: "#" },
//   ];

//   return (
//     <ul>
//       <li>Follow us</li>
//       {socialLinksData.map((item, index) => (
//         <li key={`social-${index}`}>
//           <a href={item.link}>
//             <i className={`fa fa-${item.icon}`} aria-hidden="true"></i>
//           </a>
//         </li>
//       ))}
//     </ul>
//   );
// };

const CopyRight = () => (
  <div className="row border-top bg-body-secondary">
    <div className="col-12 text-center">
      <div className="mb-0">
        <span>© {new Date().getFullYear()} Holidays Seychelles</span>
      </div>
    </div>
  </div>
);

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row pt-5">
          <GridContainer size={4} heading='Reach Us'>
            <ul className="contacts">
              {/* <p><img src="images/logo/logo.png" alt="" className="logo_normal" /></p> */}
              <li>
                <i className="fa fa-map-marker text-primary me-1" aria-hidden="true"></i><span className="fw-bold"> ESPACE Seychelles Ltd</span><br />
                Au CAP, Mahe Seychelles,<br />
                P.O Box 2021, Anse Aux Pins,<br />
                Mahe, Seychelles.
              </li>
              <li>
                <a href="tel:+248 437 6666">
                  <i className="fa fa-phone text-primary me-1" aria-hidden="true"></i> +248 437 6666
                </a>
              </li>
              <li>
                <a href="mailto:info@holidays-seychelles.com">
                  <i className="fa fa-envelope-o text-primary me-1" aria-hidden="true"></i> info@holidays-seychelles.com
                </a>
              </li>
            </ul>
            {/* <div className="follow_us">
              <SocialLinks />
            </div> */}
          </GridContainer>
          <GridContainer size={3} heading="Holidays Seychelles">
            <FooterLinks
              data={[
                { text: "Home", link: "/" },
                { text: "About Us", link: "/aboutUs" },
                { text: "Terms of Use", link: "/terms" },
                { text: "FAQ", link: "/faq" },
                { text: "Privacy Policy", link: "/privacyPolicy" },
              ]}
            />
          </GridContainer>
          <GridContainer size={3} heading="Where To Go">
            <FooterLinks
              data={[
                { text: "Accommodations", link: "/" },
                { text: "Attractions & Things to Do", link: "/" },
                { text: "Packages", link: "/" },
                { text: "Land & Ferry Transfers", link: "/" },
                { text: "Liveaboard", link: "/" },
              ]}
            />
          </GridContainer>
          <GridContainer size={2} heading="Visit Planner">
            <FooterLinks
              data={[
                { text: "Visit Planner", link: "/visitplanner" },
                { text: "Before your Trip", link: "/beforeyourtrip" },
                { text: "During Your Trip", link: "/duringyourtrip" },
                { text: "Family and Groups", link: "/family" },
                { text: "Travel Blog", link: "/travelblog" },
              ]}
            />
          </GridContainer>
        </div>
        <CopyRight />
      </div>
      <TawkToWidget />
    </footer>
  );
}
