import React, { Component } from "react";
import "./NewHeader.css";
import { getGeoInfo, getCustomerLogin } from "../axios";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { Base64 } from "js-base64";
import LoginComponent from "../Auth/LoginComponent";
class NewHeader extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      nav: false,
      isLoginOpen: false,
      country: "",
      country_code: "",
      currency: "",
      ip_address: "",
      customerEmailForData: localStorage.getItem("userDetails") || "abc",
      customerDetails: {},
      profileNew: false,
      profileNewAgain: false,
    };
  }

  handleScroll = () => {
    if (this.props.page == "inner") {
      if (window.pageYOffset > 60) {
        if (!this.state.nav) {
          this.setState({ nav: true });
        }
      } else {
        if (this.state.nav) {
          this.setState({ nav: false });
        }
      }
    } else if (this.props.page == "inner_listing") {
      if (window.pageYOffset > 60) {
        if (!this.state.nav) {
          this.setState({ nav: true });
        }
      } else {
        if (this.state.nav) {
          this.setState({ nav: false });
        }
      }
    }
  };

  onOpenLogin = () => this.setState({ isLoginOpen: true });
  onCloseLogin = () => this.setState({ isLoginOpen: false });

  componentDidMount() {
    let userData = localStorage.getItem("userDetails");

    getGeoInfo().then(
      (data) => {
        this.setState({ country: data.country_name });
        this.setState({ country_code: data.country_calling_code });
        this.setState({ currency: data.currency });
        this.setState({ ip_address: data.ip });
      },
      (error) => { }
    );

    if (userData != null) {

      let newData = JSON.parse(userData);
      getCustomerLogin(newData.email).then(
        (data) => {

          this.setState({ customerDetails: data });

          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);
          localStorage.setItem("userDetails", JsonData);
        }
      );
    }

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("click", this.globalClickListener);
  }

  globalClickListener = (nativeEvent) => {
    this.setState({ profileNew: false, profileNewAgain: false }, () => {
      window.removeEventListener("click", this.globalClickListener);
    });
  };

  OpenProfilelinkAgain = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ profileNewAgain: !prevState.profileNewAgain }),
      () => {
        if (this.state.profileNewAgain) {
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  logoutChange = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  render() {
    return (
      <div
        className={`chHeaderWrapper ${this.state.nav == true ? " Nav" : " stickyHeader"}`}
        style={{ animation: "slideInDown 0.00s linear" }}
      >
        <div className="chHeaderContainer">
          <span className="logoContainer">
            <a className="chMmtLogo" href="/">
              <img
                src="images/logo/seyLogo.png"
                alt="Holiday Seychelles"
                style={{ width: 100 }}
              />
            </a>
          </span>

          {/* <BannerTabNav
            tabs={['Accommodations', 'Attractions & Things to Do', 'Packages', 'Land & Ferry Transfers', 'Liveaboard']} 
            selected={this.props.Location}>
          </BannerTabNav> */}
          <nav>
            <ul className="font12">
              <li className="menu_Hotels">
                <a
                  href={"/?t=" + Base64.btoa(1)}
                  smooth={true}
                  className=" hsFlex_Bann hsCenter column"
                >
                  <span className="chNavIcon appendBottom2 chSprite chHotels "></span>
                  <span className={`darkGreyText ${this.props.tabName === 1 ? " active" : " "}`}
                  >
                    {" "}
                    Accommodations{" "}
                  </span>
                </a>
              </li>
              <li className="menu_Villasmore">
                <a
                  href={"/?t=" + Base64.btoa(2)}
                  smooth={true}
                  className="hsFlex_Bann hsCenter column"
                >
                  <span className="chNavIcon appendBottom2 chSprite chHomestays"></span>
                  <span
                    className={`darkGreyText ${this.props.tabName === 2 ? " active" : " "
                      }`}
                  >
                    {" "}
                    Attractions &amp; Things to Do
                  </span>
                </a>
              </li>

              <li className="menu_Holidays">
                <a
                  href={"/?t=" + Base64.btoa(3)}
                  className="hsFlex_Bann hsCenter column"
                >
                  <span className="chNavIcon appendBottom2 chSprite chHolidays"></span>
                  <span
                    className={`darkGreyText ${this.props.tabName === 3 ? " active" : " "
                      }`}
                  >
                    {" "}
                    Packages{" "}
                  </span>
                </a>
              </li>

              <li className="menu_Trains">
                <a
                  href={"/?t=" + Base64.btoa(4)}
                  className="hsFlex_Bann hsCenter column"
                >
                  <span className="chNavIcon appendBottom2 chSprite chTrains"></span>
                  <span
                    className={`darkGreyText ${this.props.tabName === 4 ? " active" : " "
                      }`}
                  >
                    {" "}
                    Land & Ferry Transfers{" "}
                  </span>
                </a>
              </li>

              <li className="menu_Buses">
                <a
                  href={"/?t=" + Base64.btoa(5)}
                  className="hsFlex_Bann hsCenter column"
                >
                  <span className="chNavIcon appendBottom2 chBuses"></span>
                  <span
                    className={`darkGreyText ${this.props.tabName === 5 ? " active" : " "
                      }`}
                  >
                    {" "}
                    Liveaboard{" "}
                  </span>
                </a>
              </li>
            </ul>
          </nav>

          <ul className="userSection pushRight">
            {/* <li className="hsFlex_Bann hsCenter lhMyWallet"><span className="landingSprite walletBlanceIcon appendRight10">&nbsp;</span><span></span>
                            </li> */}
            {this.state.customerEmailForData == "abc" ? (
              <li
                className="hsFlex_Bann hsCenter font10 hsRelative lhUser"
                onClick={this.onOpenLogin}
              >
                <div className="hsFlex_Bann column flexOne latoBold">
                  <p className="appendBottom3_Header">
                    {" "}
                    <img
                      src="images/icon/favicon.ico"
                      className="loginIcon"
                    />{" "}
                    Login or Create Account
                  </p>
                </div>
              </li>
            ) : (
              <li
                className="hs-LoginFlex hsCenter font10 hsRelative hsUser userLoggedIn"
                onClick={this.OpenProfilelinkAgain}
              >
                {this.state.customerDetails.profile_pic == "" ? (
                  <span className="userNameIcon whiteText hs-LoginFlex perfectCenter latoBlack appendRight10">
                    <span>
                      {this.state.customerDetails?.first_name?.charAt(0)}
                    </span>
                  </span>
                ) : (
                  // <span className="userNameImage  appendRight10">
                  //   <img
                  //     style={{ width: "100%", borderRadius: "100%" }}
                  //     src={this.state.customerDetails.profile_pic}
                  //   />
                  // </span>
                  <span className="userNameIcon whiteText hs-LoginFlex perfectCenter latoBlack appendRight10">
                    <span>
                      {this.state.customerDetails?.first_name?.charAt(0)}
                    </span>
                  </span>
                )}
                <div className="hs-LoginFlex column userNameText  latoBold">
                  <p
                    data-cy="loggedInUser"
                    className="appendBottom3 truncate customerName"
                  >
                    Hey {this.state.customerDetails.first_name}
                  </p>
                </div>
                {this.state.profileNewAgain && (
                  <div className="userDropdown fadeInDown hs-LoginFlex column dropbanner">
                    <a className="userItems hs-LoginFlex" href="/PlanTrips">
                      <span className="landingSprite userItemMyTrips appendRight20"></span>
                      <div>
                        <p className="font16 latoBold blackText appendBottom5">
                          My Trips
                        </p>
                        <p className="font12 lineHeight16 blackText">
                          Plan Trips and Manage Bookings
                        </p>
                      </div>
                    </a>
                    {/* <a className="userItems hs-LoginFlex" href="/ExpreriancePlanTrips" target="_blank">
                                                <span className="landingSprite userItemMyTrips appendRight20"></span>
                                                <div>
                                                    <p className="font16 latoBold blackText appendBottom5">My Expreriance Trips</p>
                                                    <p className="font12 lineHeight16 blackText">See booking details, Expreriance planned your own trip, Cancel Booking &amp; more.</p>
                                                </div>
                                            </a> */}

                    {/* <a className="userItems hs-LoginFlex" href="/Wallet" target="_blank">
                                                <span className="landingSprite userItemMyWallet appendRight20"></span>
                                                <div>
                                                    <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">My Wallet
                                                <span className="myWalletTag font12 whiteText appendLeft5 latoBold"> ₹ 0</span>
                                                    </p>

                                                    <p className="font12 lineHeight16 blackText">Use your wallet money to avail even greater discounts</p>
                                                </div></a> */}

                    <a className="userItems hs-LoginFlex" href="/Profile">
                      <span className="landingSprite userItemMyProfile appendRight20"></span>
                      <div>
                        <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">
                          My Profile
                        </p>
                        <p className="font12 lineHeight16 blackText">
                          Manage your profile, login details and password
                        </p>
                      </div>
                    </a>
                    <a
                      className="userItems hs-LoginFlex appendBottom10"
                      onClick={this.logoutChange}
                    >
                      <span className="landingSprite userItemLogout appendRight20"></span>
                      <div>
                        <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">
                          Logout
                        </p>
                      </div>
                    </a>
                  </div>
                )}
              </li>
            )}
          </ul>
        </div>
        <LoginComponent open={this.state.isLoginOpen} onClose={this.onCloseLogin} onOpen={this.onOpenLogin} />
      </div>
    );
  }
}

export default withCookies(NewHeader);
