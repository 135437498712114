import React, { useState, useEffect } from "react";
import "./TourOfferListing.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { Base64 } from "js-base64";
import { decode } from "html-entities";
import moment from "moment";

function TourOfferListingComponent(props) {
  const [ImageChanger, setImageChanger] = useState();
  const [HotelData, setHotelData] = useState(props.OfferData);
  // const [gallery, setGallery] = useState(props.gallery);
  const onMouseOutFun = (image) => {
    setImageChanger(image);
  };
  const onMouseOverFun = (image) => {
    setImageChanger(image);
  };

  const getAmountHotel = (data) => {
    const data12 = Object.entries(data.forMeal);
    const rateId = data12.filter(([key, value]) => value == data.amount);

    const Offer1 = data.specialOffer.filter(
      (item, key) =>
        item != "no_offer" && item.roomSpecial.rate_plan_id == rateId[0][0]
    );

    if (Offer1.length > 0) {
      let result = [];

      Offer1.map((i, j) => {
     
        if (i.combineoffer == "nocombine") {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data.amount * parseInt(i.specialdata.pay_stay_length) -
              data.amount * parseInt(i.specialdata.pay_stay_value);
            const disA =
              (dis / (data.amount * parseInt(i.specialdata.pay_stay_length))) *
              100;
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data.amount - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        } else {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data.amount * parseInt(i.specialdata.pay_stay_length) -
              data.amount * parseInt(i.specialdata.pay_stay_value);
            const disA =
              (dis / (data.amount * parseInt(i.specialdata.pay_stay_length))) *
              100;
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data.amount - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        }
      });

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount =
        data.amount - (Math.round(Amount * data.amount) / 100).toFixed(2);
      return (
        <>
          <div className="containerListing">
            <p
              className="grayText font16 lineThrough appendBottom5"
              id="hlistpg_hotel_cut_price"
            >
              <span>€</span>
              {data.amount.toFixed(2)}
            </p>
            {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
            <p
              className="latoBlack font16 blackText appendBottom5 appendLeft5"
              id="hlistpg_hotel_shown_price"
            >
              <span>€</span>
              {balanceAmount.toFixed(2)}
            </p>
          </div>
          <p class="hrtlCenter appendBottom10 directionRtl">
            <span class="redText font12">
              <span>Save </span>
              <span class="latoBold" id="hlistpg_hotel_saved_price">
                <span>€</span>
                {(Math.round(Amount * data.amount) / 100).toFixed(2)}
              </span>
            </span>
            <span class="spriteNew discountIcon relative appendLeft5">
              <span
                class="latoBold font11 whiteText discountText"
                id="hlistpg_hotel_saved_price_percentage"
                style={{
                  lineHeight: "15px",
                  paddingRight: "5px",
                }}
              >
                {parseInt(Amount)}%
              </span>
            </span>
          </p>
        </>
      );
    } else {
      return (
        <>
          {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
          <p
            className="latoBlack font26 blackText appendBottom5"
            id="hlistpg_hotel_shown_price"
          >
            <span>€</span>
            {data.amount.toFixed(2)}
          </p>
        </>
      );
    }
  };

  return (
    <>
      {HotelData != null &&
        HotelData.map((item, index) => {
          // if (item != 'nodata') {
          // let checkin = new Date();
          // let dateString = new Date(); // date string
          // let actualDate = new Date(dateString); // convert to actual date
          // let checkout = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate() + 1);
          // let querySearch = [{ "adultCounter": 1, "childCounter": 0, "roomCounter": 1, "isExpanded": true }]

          // let JsonState = {
          //   defaultZoneval: HotelData.specialOffers[index].property_name,
          //   startDate: moment(checkin).format("Y-MM-DD"),
          //   endDate: moment(checkout).format("Y-MM-DD"),
          //   guestCombination: querySearch,
          //   accopage: 1,
          //   accosize: 20
          // };
          // const hotelArr = JSON.stringify(JsonState);
          let querySearch = [
            {
              adultCounter: 1,
              childCounter: 0,
              roomCounter: 1,
              isExpanded: true,
            },
          ];

          let JsonState = {
            defaultZoneval: item.property_name,
            startDate: moment(item.checkin_date).format("Y-MM-DD"),
            endDate: moment(item.checkout_date).format("Y-MM-DD"),
            guestCombination: querySearch,
            accopage: 1,
            accosize: 20,
          };
          const hotelArr = JSON.stringify(JsonState);
          return (
            <>
              <div className="Container-Tile">
                <a
                  href={
                    "/hotelDetails?hotelid=" +
                    Base64.btoa(item.property_id) +
                    "&query=" +
                    encodeURIComponent(hotelArr)
                  }
                  target="_blank"
                >
                  <>
                    <div className="Image-Section">
                      <LazyLoadImage
                        src={item.image}
                        className="gallery__img"
                        placeholderSrc={
                          process.env.PUBLIC_URL + "images/placeholder.png"
                        }
                      />
                    </div>
                    <div className="Text-Section">
                      <div className="Text-Contents">
                        <div className="Text-Ratings">
                          <p
                            className="latoBlack font16 blackText appendBottom10"
                            style={{ marginRight: 10 }}
                          >
                            <span> {item.property_name.substring(0, 80)}</span>
                          </p>
                        </div>
                        <div className="Small-Text">
                          <p className="address" style={{ color: "gray" }}>
                            {item?.address_line_1?.substring(0, 40)}
                          </p>
                          <p
                            style={{
                              fontSize: "12px",
                              color: "#555",
                              marginTop: 5,
                              textAlign: "justify",
                              marginRight: 15,
                            }}
                          >
                            {ReactHtmlParser(
                              `${item.description.substring(0, 200)}...`
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="priceDetails textRight">
                        <div className="priceDetailsTop">
                          <p className="font12 latoBold appendBottom3 darkText">
                            included in this price
                          </p>
                        </div>
                        <div className="padding20 makeFlex column">
                        <p className="font12 grayText appendBottom5">
                        Per Night From{" "}</p>

                          {item.HotelOfferAmount == "" ? (
                            <p
                              className="latoBlack font16 blackText appendBottom5"
                              id="hlistpg_hotel_shown_price"
                            >
                              <span>€</span>
                              {(Math.round(item.NewAmount * 100) / 100).toFixed(
                                2
                              )}
                            </p>
                          ) : (
                            <>
                              {" "}
                              {item.PercentageAm == 0 ? (
                                <>
                                  {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
                                  <p
                                    className="latoBlack font16 blackText appendBottom5"
                                    id="hlistpg_hotel_shown_price"
                                  >
                                    <span>€</span>
                                    {(
                                      Math.round(item.Amount * 100) / 100
                                    ).toFixed(2)}
                                  </p>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <div className="containerListing">
                                    <p
                                      className="grayText font16 lineThrough appendBottom5"
                                      id="hlistpg_hotel_cut_price"
                                    >
                                      <span>€</span>
                                      {(
                                        Math.round(item.Amount * 100) / 100
                                      ).toFixed(2)}
                                    </p>
                                    {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
                                    <p
                                      className="latoBlack font16 blackText appendBottom5 appendLeft5"
                                      id="hlistpg_hotel_shown_price"
                                    >
                                      <span>€</span>
                                      {(
                                        Math.round(
                                          item.HotelOfferAmount * 100
                                        ) / 100
                                      ).toFixed(2)}
                                    </p>
                                  </div>
                                  <p class="hrtlCenter appendBottom10 directionRtl">
                                    <span class="redText font12">
                                      <span>Save </span>
                                      <span
                                        class="latoBold"
                                        id="hlistpg_hotel_saved_price"
                                      >
                                        <span>€</span>
                                        {(
                                          Math.round(
                                            item.HotelOfferAmNew * 100
                                          ) / 100
                                        ).toFixed(2)}
                                      </span>
                                    </span>
                                    <span class="spriteNew discountIcon relative appendLeft5">
                                      <span
                                        class="latoBold font11 whiteText discountText"
                                        id="hlistpg_hotel_saved_price_percentage"
                                        style={{
                                          lineHeight: "15px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        {item.PercentageAm}%
                                      </span>
                                    </span>
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </a>
              </div>

              <div class="title__placeHolder font12 pc__bottomBox">
                <div class="persuasion ">
                  <div class="persuasion__item pc__cashbackDeal">
                    <span class="sprite infoIconGreen"></span>
                    <span>{item.HotelOfferName.substring(0, 100)}</span>
                  </div>
                </div>
              </div>
            </>
          );
          // }
        })}
    </>
  );
}

export default TourOfferListingComponent;
