import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import NewHeader from "../Component/NewHeader/NewHeader.js";
import Footer from "../Component/Footer";
import {Helmet} from "react-helmet";

const Family = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
      <div className="MainStaticMainComponentContaint">
      <Helmet>
      <title>Familiy and Groups : Holidays Seychelles</title>
      <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
    </Helmet>
    <h1 style={{}}> Familiy and Groups : Holidays Seychelles</h1>
        <NewHeader page={"inner_listing"} />
        <div
          className="StaticlandingCard appendBottom20 StaticSection page-section"
          style={{ marginTop: 100 }}
        >
          <div className="StaticContainer" id="container">
            <div class="row">
              <div className="aboutPage">
                <h1 className="aboutHeading">Family and Groups</h1>
                <p>
                  <i>
                    {" "}
                    We are your local connection in the Republic of Seychelles!
                  </i>
                </p>
                <p className="text">
                  All the APPs in the website will allow you to book in groups.
                  However, some groups may be limited in size. In this case you
                  will have two options:
                </p>
                <ol>
                  <li>
                    1. Create another booking if the availability is there.
                  </li>
                  <li>
                    2. Contact us via your booking page so that we can organize
                    that for you.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Family;
