import React, { useState } from "react";
import axios from "axios";

const NewsLatterComponent = () => {
  const [emailnewsletter, setemailnewsletter] = useState("");
  const [errors, setErrors] = useState({
    error_email_newsletter: "",
    success_email_newsletter: "",
  });

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  function handleChange(e) {
    e.preventDefault();

    const { name, value } = e.target;
    let error = errors;

    switch (name) {
      case "email_newsletter":
        if (value.trim() === "") {
          error.error_email_newsletter = "Please enter a valid email address";
        } else if (validEmailRegex.test(value) == "") {
          error.error_email_newsletter = "Email address is not valid!";
        } else {
          error.error_email_newsletter = "";
        }
        break;

      default:
        break;
    }
    setErrors({ ...errors, [name]: value });
    setemailnewsletter(value);
  }

  const onSubmit = async (event) => {
    event.preventDefault(); // Prevent default submission
    const { name, value } = event.target;
    let error = errors;

    axios
      .post(
        "https://o8hieakppc.execute-api.us-east-2.amazonaws.com/Newsletter",
        {
          email: emailnewsletter,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YWRtaW46MTIzNA==",
            "x-api-key": "neeraj@123",
          },
        }
      )
      .then(function (response) {
        // htandle success

        if (response.data.message === "Already exist") {
          error.success_email_newsletter = "";
          error.error_email_newsletter = "Email already exist";
          setErrors({ ...errors, [name]: value });
        } else if (response.data.message === "successfully inserted") {
          error.error_email_newsletter = "";
          error.success_email_newsletter = "Thanks! for joining us";
          setErrors({ ...errors, [name]: value });
          setemailnewsletter("");
        } else if (response.data.message === "Email required") {
          error.success_email_newsletter = "";
          error.error_email_newsletter = "Please enter your email ID";
          setErrors({ ...errors, [name]: value });
        } else {
          error.success_email_newsletter = "";
          error.error_email_newsletter =
            "There is some problem please try again";
          setErrors({ ...errors, [name]: value });
        }
      })
      .catch(function (error) {
        // handle error
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div
            className="newsd"
            style={{
              backgroundColor: "#00224f",
              padding: 40,
              borderRadius: 10,
            }}
          >
            <div className="col-md-6 col-sm-12 col-xs-12 offset-3">
              <div id="newsletter">
                <h6 style={{ color: "#fff", fontWeight: 400 }}>
                  Save time, save money!
                </h6>
                <p>
                  Subscribe to our newsletter and receive email packed with
                  exclusive offers, promotions and updates.
                </p>
                <div id="message-newsletter"></div>
                <form
                  onSubmit={onSubmit}
                  method="post"
                  action="#"
                  name="newsletter_form"
                  // onSubmit={onSubmit}
                  autoComplete="new-password"
                >
                  <div className="form-group">
                    <input
                      type="email"
                      name="email_newsletter"
                      id="email_newsletter"
                      className="form-control"
                      placeholder="Your email"
                      style={{
                        border: "1px solid #eee",
                        backgroundColor: "#fff",
                        borderRadius: 3,
                      }}
                      onChange={handleChange}
                      autoComplete="off"
                      value={emailnewsletter}
                    />
                    <input
                      type="submit"
                      value="Subscribe"
                      id="submit-newsletter"
                    />
                  </div>
                  <p>
                    {errors.error_email_newsletter.length > 0 && (
                      <span className="errornews">
                        {errors.error_email_newsletter}
                      </span>
                    )}
                    {errors.success_email_newsletter.length > 0 && (
                      <span className="succesnews" style={{ color: "Green" }}>
                        {errors.success_email_newsletter}
                      </span>
                    )}
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLatterComponent;
