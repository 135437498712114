import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import NewHeader from "../Component/NewHeader/NewHeader.js";
import Footer from "../Component/Footer";
import {Helmet} from "react-helmet";

const Buildyourtrip = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
      <div className="MainStaticMainComponentContaint">
      <Helmet>
      <title>Before Your Trip : Holidays Seychelles</title>
      <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
    </Helmet>
    <h1 style={{}}> Before Your Trip : Holidays Seychelles</h1>
        <NewHeader page={"inner_listing"} />
        <div
          className="StaticlandingCard appendBottom20 StaticSection page-section"
          style={{ marginTop: 100 }}
        >
          <div className="StaticContainer" id="container">
            <div class="row">
              <div className="aboutPage">
                <h1 className="aboutHeading">Before Your Trip</h1>
                <p>
                  <i>
                    {" "}
                    We are your local connection in the Republic of Seychelles!
                  </i>
                </p>
                <p className="text">
                  This website consists multiple APPs working together to
                  provide you with the best service and experience. Before you
                  start making bookings it is best that you search a little bit
                  about our website and get experience what it offers.
                </p>
                <p className="text">
                  This website is your one stop shop. You will be able to book
                  everything that you will need for a very relax and successful
                  holiday.{" "}
                </p>
                <p className="text">
                  Remember that Seychelles are islands and each island may have
                  its own set of activities. Therefore, if you book
                  accommodation in Mahe, be careful to book your events on the
                  same island.
                </p>
                <p className="text">
                  You may need to ask yourself some basic and fundamental
                  questions:
                </p>
                <p className="text">
                  Example. Where would you like to stay? Mahe, Praslin, La Digue
                  or other islands? Would you need transfer or ferry ticket or
                  both to get there? Would you need transfer on the other island
                  to your hotel? Then work your way back in reverse to ensure
                  that you have all you need for your return trip.
                </p>
                <p className="text">
                  Whilst you are searching you can simply add to “My Trip”. Once
                  you are done searching you can go to “My Trip” and edit it to
                  keep what you want. You will be able to share your itinerary
                  by email to others that are accompanying you on the trip.
                  After the review you can confirm the bookings.
                </p>
                <p className="text">
                  The booking confirmation requires payment for the entire
                  booking. We will be using secure payment gateway to accomplish
                  that for us. The Payment gateway is CPI (Credit card Industry
                  standard) compliant. We will not be keeping your payment
                  details on our servers. This payment information will be
                  transferred by secured SSL socket to the payment gateway
                  secured server for normal credit card processing and
                  validation. After the confirmed payment is received your
                  booking will also be confirmed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Buildyourtrip;
