import React from "react";

const MobileAppComponent = () => {
  return (
    <div className="container mb-4 mt-3">
      <div className="row align-items-center EventlandingCard py-4 mobile-background">
        <div className="col-md-2 text-center">
          <img
            src="mobile-app-screen.png"
            alt="Mobile App"
            className="img-fluid rounded"
            style={{
              maxHeight: "30vh",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="col-md-6 text-white">
          <div className="mb-3 h2 fw-bold">Experience Our <span className="text-warning fw-bold">SeyVoyage</span> Mobile App</div>
          <p>
            Stay connected with our mobile app, available for both iOS and
            Android. Download now to enjoy exclusive features and seamless
            navigation.
          </p>
        </div>
        <div className="col-md-2 text-center">
          <img
            src="seyvoyage-qr-code.png"
            alt="SeyVoyage App Qr Code"
            className="img-fluid rounded mb-2"
            style={{
              maxHeight: "20vh",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="col-md-2 text-center">
          {[
            {
              href:
                "https://play.google.com/store/apps/details?id=com.hscustomer&hl=en",
              src: "google-play.png",
              alt: "Google Play",
            },
            {
              href: "https://apps.apple.com/in/app/seyvoyage/id6737127678",
              src: "app-store.png",
              alt: "App Store",
            },
          ].map(({ href, src, alt }, index) => (
            <a key={`app-${index}`} href={href} target="_blank">
              <img
                src={src}
                alt={alt}
                className="img-fluid rounded p-1 w-75"
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileAppComponent;
