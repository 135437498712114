/* eslint-disable */
import React, { Component } from "react";
import InputRange from "react-input-range";
import "./ActivityListing.css";
import {
  PackageZoneData,
  getTourPackageList,
  getTourFilterPackage,
  getalltransport
} from "../Component/axios";
import Autosuggest from "react-autosuggest";
import NewHeader from "../Component/NewHeader/NewHeader";
import moment from "moment";
import {Helmet} from "react-helmet";
import "react-google-flight-datepicker/dist/main.css";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import HotelListSkeleton from "../Component/HostelList/HotelListSkeleton";
import TourPackageList from "../TourList/TourPackageList";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import  ExperiencesSoldout  from "./SoldOut/ExperiencesSoldout";
import TawkToWidget from "../Component/TawkToWidget";
const SortArr = [
  { id: "4", name: "Recommended" },
  { id: "1", name: "Price - Low to High" },
  { id: "2", name: "Price - High to Low" },
];

const Duration = [
  { id: "1", name: "Up to 1 hour" },
  { id: "2", name: "1 to 4 hours" },
  { id: "3", name: "4 hours to 1 day" },
  { id: "4", name: "1 to 3 days" },
  { id: "5", name: "3+ days" },
];

const TimeOfDays = [
  { id: "1", name: "Morning", smallLabel:"Starts before 12 pm" },
  { id: "2", name: "Afternoon", smallLabel:"Starts after 12 pm"  },
  { id: "3", name: "Evening and night", smallLabel:"Starts after 5 pm"  },
];

export default class PackageListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownVisible: false,
     // selected: "Accommodations",
      searchInput: "",
      error: null,
      val: "",
      //showResults: false,
      SelectedPlace: null,
      nav: false,
      searchData: "",
      sorting: false,
      sortingSelect: "Recommended",
      isDropdownOpen: false,
      sortingId: null,
      value: "",
      suggestionsPackage: [],
      showResultsPackage: false,
      defaultZonevalPackage: "Seychelles",
      selectPackageDay: moment(new Date()).format("ddd, D MMM yy"),
      selectPackageDayNew: new Date(),
      checkinShowPackage: false,
      packageData: null,
      FiltersData: null,
      valuePrice: { min: 0, max: 10 },
      appliedFilterVal: [],
      checked: false,
      checkedItems: new Map(),
      packageList: null,
      packageListFilter: null,
      checkedTime: false,
      checked: [],
      expanded: [],
      CategotyThem:null
    };
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const defaultZonevalPackageVal = urlParams.get("defaultZonevalPackageVal");
    const dateSelected = urlParams.get("dateSelected");
   
    this.setState({ defaultZonevalPackage: defaultZonevalPackageVal });
    this.setState({ selectPackageDayNew: dateSelected });
    this.setState({
      selectPackageDay: moment(dateSelected).format("ddd, D MMM yy"),
    });
    
    let JsonState = {
      defaultZonevalPackageVal: defaultZonevalPackageVal,
      dateSelected: moment(dateSelected).format("Y-MM-DD"),
    };
    const packageArr = JSON.stringify(JsonState);

    PackageZoneData().then(
      (data) => {
        this.setState({
          itemsZonePackage: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    const tour_type= {tour_type:"Package"}
    const tourtype = JSON.stringify(tour_type);
      getalltransport(tourtype).then(
        (data) => {
       
          this.setState({ CategotyThem:data.transport_mode_name});
        },
        (error) => {
          this.setState({
            error,
          });
        }
      );
    getTourPackageList(packageArr).then(
      (data) => {
        // this.setState({ packageData: data });
        if (data == undefined) {
          this.setState({
            packageData: null,
          });
        } else {
          // this.setState({
          //     packageData: data,
          // });
          this.setState({
            packageListFilter: data,
          });

          if (data == "no_package") {
            this.setState({
              packageData: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    let JsonState1 = {
      defaultZonevalVal: defaultZonevalPackageVal,
      dateSelected: moment(dateSelected).format("Y-MM-DD"),
    };
    const packageArr1 = JSON.stringify(JsonState1);

    getTourFilterPackage(packageArr1).then(
      (data) => {
       
        this.setState({ FiltersData: data });
        this.setState({ valuePrice: { min: 0, max: data?.max_price.toFixed(2) } });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    window.addEventListener("scroll", this.handleScroll);
  }

  SearchListing = () => {
    this.setState({
      FiltersData: null,
    });
    this.setState({
      packageData: null,
    });
    this.setState({
      sortingSelect: "Recommended",
    });

    let JsonState = {
      defaultZonevalPackageVal: this.state.defaultZonevalPackage,
      dateSelected: moment(this.state.selectPackageDayNew).format("Y-MM-DD"),
    };
    const hotelArr = JSON.stringify(JsonState);
  
    PackageZoneData().then(
      (data) => {
        this.setState({
          itemsZonePackage: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    getTourPackageList(hotelArr).then(
      (data) => {
        // this.setState({ packageData: data });
        if (data == undefined) {
          this.setState({
            packageData: null,
          });
        } else {
          // this.setState({
          //     packageData: data,
          // });
          this.setState({
            packageListFilter: data,
          });

          if (data == "no_package") {
            this.setState({
              packageData: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    let JsonState1 = {
      defaultZonevalVal: this.state.defaultZonevalPackage,
      dateSelected: moment(this.state.selectPackageDayNew).format("Y-MM-DD"),
    };
    const hotelArr1 = JSON.stringify(JsonState1);

    getTourFilterPackage(hotelArr1).then(
      (data) => {
        this.setState({ FiltersData: data });
        this.setState({ valuePrice: { min: 0, max: data?.max_price.toFixed(2) } });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  SortingFunction = (data) => {
    if (data != "no_package") {
    
      const sorArr = data;
      const zeroElement = sorArr[0];

      setTimeout(() => {
        // const getValue = (price) => +price;
        // sorArr.sort(
        //   (a, b) =>
        //     getValue(
        //       Object.values(a).map((i, j) => {
        //         return i.tour_price;
        //       })
        //     ) -
        //     getValue(
        //       Object.values(b).map((i, j) => {
        //         return i.tour_price;
        //       })
        //     )
        // );

        this.setState({
          packageData: sorArr,
        });
        this.setState({ packageListFilter: sorArr });
      }, 2000);
    }
  };

  sortingSearch = (data) => {
  
    const sorArr = data;
    if (this.state.sortingSelect == "Price - Low to High") {

      setTimeout(() => {
        if (sorArr[0].matched) {
          const sliceArr = sorArr.shift();
          const getValue = (price) => +price;

          sorArr.sort(
            (a, b) =>
              getValue(
                Object.values(a).map((i, j) => {
                  return i.tour_price;
                })
              ) -
              getValue(
                Object.values(b).map((i, j) => {
                  return i.tour_price;
                })
              )
          );

          this.setState({
            packageData: sorArr,
          });

          this.setState({ packageListFilter: sorArr });
        } else {
          const getValue = (price) => +price;
          sorArr.sort(
            (a, b) =>
              getValue(
                Object.values(a).map((i, j) => {
                  return i.tour_price;
                })
              ) -
              getValue(
                Object.values(b).map((i, j) => {
                  return i.tour_price;
                })
              )
          );

        

          this.setState({
            packageData: sorArr,
          });
          this.setState({ packageListFilter: sorArr });
        }
      }, 2000);
    } else if (this.state.sortingSelect == "Price - High to Low") {
      setTimeout(() => {
        const getValue = (price) => -price;

        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_price;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_price;
              })
            )
        );

        this.setState({
          packageData: sorArr,
        });
        this.setState({ packageListFilter: sorArr });
      }, 1000);
    } else if (this.state.sortingSelect == "Recommended") {
      setTimeout(() => {

        const getValue = (price) => price;

        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_price;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_price;
              })
            )
        );
        this.setState({
          activityData: sorArr,
        });
        this.setState({ packageListFilter: sorArr });
      }, 1000);
    }
  };

  componentWillUnmount() {
    // document.removeEventListener('click', this.globalClickListener)
    window.removeEventListener("click", this.globalClickListener);
    window.removeEventListener("scroll", this.handleScroll);
  }
  globalClickListener = (nativeEvent) => {
    this.setState(
      {
        isDropdownOpen: false,
        dropdownVisible: false,
        showResultsPackage: false,
        checkinShowPackage: false,
       
      },
      () => {
     
        // document.removeEventListener('click', this.globalClickListener)
        window.removeEventListener("click", this.globalClickListener);
      }
    );
  };

  checkinandcheckoutPackage = (syntheticEvent) => {
    this.setState({ showResultsPackage: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ checkinShowPackage: !prevState.checkinShowPackage }),
      () => {
        if (this.state.checkinShowPackage) {
       
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    
  };

  handleBodyClick = (event) => {
    event.stopPropagation();
  };

  showSorting = (syntheticEvent) => {
  
    syntheticEvent.stopPropagation();
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
    this.setState(
      (prevState) => ({ sorting: !prevState.sorting }),
      () => {
        if (this.state.sorting) {
        
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  getSuggestionsPackage = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZonePackage.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  onSuggestionsFetchRequestedPackage = ({ value }) => {
    this.setState({
      suggestionsPackage: this.getSuggestionsPackage(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequestedPackage = () => {
    this.setState({
      suggestionsPackage: [],
    });
  };

  onSuggestionSelectedPackage = () => {
    this.setState({
      showResultsPackage: false,
    });
  };

  getSuggestionValuePackage = (suggestion) => suggestion.Name;

  // Use your imagination to render suggestions.
  renderSuggestionPackage = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImageLocation">
          <img
          src="images/locationacco.png"
          className="locationImageContent"
          />
        </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );

  renderSuggestionsContainerPackage = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        {/* <div> {children}</div> */}
        {query.length === 0 ? (
            <div>
                {/* <p class="hsw_sectionTitle font12 latoBlack greyText">Popular</p> */}
        <ul role="listbox" class="react-autosuggest__suggestions-list">
        <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionPackage("Seychelles")}>
                <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
                
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
                
                    <div className="flexOne">
                    <p className="locusLabel appendBottom5 fontSizeChange">
                    Seychelles
                    </p>
                    </div>
                    </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionPackage("Mahe Island")}>
                <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
                
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
                
                    <div className="flexOne">
                    <p className="locusLabel appendBottom5 fontSizeChange">
                    Mahe Island
                    </p>
                    </div>
                    </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionPackage("Praslin Island")}>
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
      
            <div className="locationImageLocation">
                <img
                src="images/locationacco.png"
                className="locationImageContent"
                />
            </div>
       
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
          Praslin Island
          </p>
        </div>
      </div>
      </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionPackage("La Digue Island")}>
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
      
        <div className="locationImageLocation">
            <img
            src="images/locationacco.png"
            className="locationImageContent"
            />
        </div>
       
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
          La Digue Island
          </p>
        </div>
      </div>
    </div>
          </li>
          
        </ul></div>
         ) : (
            <div> {children}</div>
          )}
      </div>
    );
  };

  showZonelistdivPackage = (syntheticEvent) => {
    this.setState({ checkinShowPackage: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsPackage: !prevState.showResultsPackage }),
      () => {
        if (this.state.showResultsPackage) {
         
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  onChangePackage = (event, { newValue }) => {
    this.setState({
      value: newValue,
      defaultZonevalPackage: newValue,
    });
  };

  handleChangeSuggetionPackage = (newValue) => {
    this.setState({
      value: newValue,
      defaultZonevalPackage: newValue,
    });
    this.setState({
        showResultsActivity: false,
      });
  };
  handlePackageDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      // If the day is disabled, do nothing
      return;
    } 
    this.setState({ selectPackageDayNew: selected ? undefined : day  });
    this.setState({ selectPackageDay: moment(day).format("ddd, D MMM yy") });
    this.setState({ checkinShowPackage: false });
  };

  handleScroll = () => {
    if (window.pageYOffset > 10) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };

  handleSortingChange = (e) => {
    this.setState({ isDropdownOpen: false });
    this.setState({
      packageData: null,
    });

    const sorArr = this.state.packageData;
  
    if (e.id == 2) {
      setTimeout(() => {
        const getValue = (price) => -price;

        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_price;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_price;
              })
            )
        );

  
        this.setState({
          packageData: sorArr,
        });
      }, 1000);
    } else if (e.id == 3) {
      setTimeout(() => {
        const getValue = (star) => -star;
        sorArr.sort((a, b) => getValue() - getValue());

        this.setState({
          packageData: sorArr,
        });
      }, 1000);
    } else if (e.id == 1) {
      setTimeout(() => {
        const getValue = (price) => +price;
        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_price;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_price;
              })
            )
        );

        this.setState({
          packageData: sorArr,
        });
      }, 1000);
    } else if (e.id == 4) {
      setTimeout(() => {
        const getValue = (id) => id;
        sorArr.sort(
          (a, b) =>
            getValue(
              Object.values(a).map((i, j) => {
                return i.tour_data.id;
              })
            ) -
            getValue(
              Object.values(b).map((i, j) => {
                return i.tour_data.id;
              })
            )
        );


        this.setState({
          packageData: sorArr,
        });
        // this.setState({
        //   SkeletonData: false,
        // });
      }, 1000);
    }

    this.setState({
      sortingSelect: e.name,
    });

    this.setState({
      sortingId: e.id,
    });
  };

  CheckAppliedFilterLocation = () => {
  
    this.setState({ packageData: null });

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      const name = target.name;
      let applied = [...this.state.appliedFilterVal];
      const index = applied.findIndex((x) => x.label == name);
      // Remove the filter if it exists
      applied.splice(index, 1);
      this.setState({
        appliedFilterVal: applied,
      });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const packagedData = [...this.state.packageListFilter];

    const result = [];

  setTimeout(() => {
    packagedData && packagedData.map((i, j) => {
      if (i != "no_package") {
          Object.values(i).map((key1, index) => {
              if(this.state.appliedFilterVal.length> 0){
              this.state.appliedFilterVal.map((item, index) => {
                 
                  if(this.state.defaultZonevalPackage.toLowerCase().trim() === 'seychelles'){
                    if(key1.tour_data.tour_region_name.toLowerCase().trim() === item.label) {
                      result.push(i);
                    }
                  }else{
                    if(key1.tour_data.tour_location.toLowerCase().trim() === item.label){
                      result.push(i);
                    }
                  }
                });
            }
          });
        }
      });
  }, 1000); 
 
    setTimeout(() => {
      this.setState({ packageData: result });
    }, 1000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ packageData: packagedData });
      }
    }, 1000);
  };
  CheckAppliedFilterCategory = (nodecheck) => {

    this.setState({checked : nodecheck})
    this.setState({ packageData: null });
    this.setState({
      SkeletonData: true,
    });

    
 const packageData =  [...this.state.packageListFilter];
if(nodecheck.length){
 
const result = [];
      packageData && packageData.map((i, j) => {
        if (i != "no_package") {
          Object.values(i).map((key1, index) => {
            if (nodecheck.length > 0) {
             nodecheck.map((item, index) => {
                  if (key1.tour_data.transport_mode_name.toLowerCase().trim() === item) {
                    result.push(i);
                  }
             
              });
            }
          });
        }
      });
 
    setTimeout(() => {
             this.setState({ packageData: result });
            this.setState({
              SkeletonData: false,
            });
          }, 1000);
}else{
      setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ packageData: packageData });
        this.setState({
          SkeletonData: false,
        });
      }
    }, 1000);
}

  };


  
  CheckAppliedFilter = () => {
   
    this.setState({ packageData: null });

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
  
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      const name = target.name;
      let applied = [...this.state.appliedFilterVal];
      const index = applied.findIndex((x) => x.label == name);
      // Remove the filter if it exists
      applied.splice(index, 1);
      this.setState({
        appliedFilterVal: applied,
      });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const packageData = [...this.state.packageListFilter];

    const result = [];

    setTimeout(() => {
      packageData.map((i, j) => {
        if (i != 'no_package') {
          Object.values(i).map((key1, index) => {
            if (this.state.appliedFilterVal.length > 0) {
              this.state.appliedFilterVal.map((item, index) => {
                let Minhours = 0;
                let Maxhours = 0;
                if(key1.tour_duration.flexible_id ==1){
                  if(key1.tour_duration.flexible_time ==2){
                  Minhours = parseInt(key1.tour_duration.flexible_min_hours);
                  Maxhours = parseInt(key1.tour_duration.flexible_max_hours);
                  }else{
                     Minhours = (parseInt(key1.tour_duration.flexible_min_hours)/60);
                    Maxhours = (parseInt(key1.tour_duration.flexible_max_hours)/60);
                  }
                }else{
                  Minhours = parseInt(key1.tour_duration.flexible_min_hours); 
                  Maxhours = parseInt(key1.tour_duration.flexible_max_hours); 
                }
                if (item.label == "Up to 1 hour") {
                  const minvalue = 0;
                  const maxvalue = 1;
                  if (
                    Minhours >= minvalue &&
                    Maxhours <= maxvalue
                  ) {
                    result.push(i);
                  }
                
                } else if (item.label == "1 to 4 hours") {
                  const minvalue = 1;
                  const maxvalue = 4;
                  if (
                    Minhours >= minvalue &&
                    Maxhours <= maxvalue
                  ) {
                    result.push(i);
                  }
                } else if (item.label == "4 hours to 1 day") {
                  const minvalue = 4;
                  const maxvalue = 24;
                  if (
                    Minhours >= minvalue &&
                    Maxhours <= maxvalue
                  ) {
                    result.push(i);
                  }
                } else if (item.label == "1 to 3 days") {
                  const mindays = 1;
                  const maxdays = 3;
                  if (
                    parseInt(key1.tour_duration.day_number) >= mindays &&
                    parseInt(key1.tour_duration.day_number) <= maxdays
                  ) {
                    result.push(i);
                  }
                } else if (item.label == "3+ days") {
                  const mindays = 3;

                  if (
                    parseInt(key1.tour_duration.day_number) >= mindays &&
                    parseInt(key1.tour_duration.day_number) < mindays
                  ) {
                    result.push(i);
                  }
                }
              });
            } else {
              result.push(i);
            }
          });

        }

      });
    }, 1000);

    setTimeout(() => {
      this.setState({ packageData: result });
    }, 1000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ packageData: packageData });
      }
    }, 1000);
  };


  //Time Data

 
  CheckTimeOfDaysFilter = () => {
   
    this.setState({ packageData: null });
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
        const name = target.name;
        let applied = [...this.state.appliedFilterVal];
        const index = applied.findIndex((x) => x.label === name);
        // Remove the filter if it exists
        applied.splice(index, 1);
        this.setState({
          appliedFilterVal: applied,
        });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const packageData = [...this.state.packageListFilter];

    const result = [];

    setTimeout(() => {
      packageData.map((i, j) => {
        {
          Object.values(i).map((key1, index) => {
            let checkTime = false;
            if (key1.tour_time) {
              key1.tour_time.map((key2, index) => {
                if (this.state.appliedFilterVal.length > 0) {
                  this.state.appliedFilterVal.map((item, index) => {
                    if (item.label == "Morning") {
                      const from_time = "05:59 am";
                      const lost_time = "12:01 pm";
                      const maxvalue = 1;
                      var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                      var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                        "HH:mm"
                      );
                      var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                      const startTime = dt1; //'01/01/2011 10:20:45';
                      const endTime = dt2; //'01/01/2011 5:10:10';
                      var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                      if (
                        parseInt(endTime.replace(regExp, "$1$2$3")) >
                          parseInt(startTime.replace(regExp, "$1$2$3")) &&
                        parseInt(endTime.replace(regExp, "$1$2$3")) <
                          parseInt(dt3.replace(regExp, "$1$2$3"))
                      ) {
                        checkTime = true;
                        return checkTime;
                      }
                    } else if (item.label == "Afternoon") {
                      const from_time = "12:01 pm";
                      const lost_time = "05:00 pm";
                      const maxvalue = 1;
                      var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                      var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                        "HH:mm"
                      );
                      var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                      const startTime = dt1; //'01/01/2011 10:20:45';
                      const endTime = dt2; //'01/01/2011 5:10:10';
                      var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                      if (
                        parseInt(endTime.replace(regExp, "$1$2$3")) >
                          parseInt(startTime.replace(regExp, "$1$2$3")) &&
                        parseInt(endTime.replace(regExp, "$1$2$3")) <
                          parseInt(dt3.replace(regExp, "$1$2$3"))
                      ) {
                        checkTime = true;
                        return checkTime;
                      }
                    } else if (item.label == "Evening and night") {
                      const from_time = "05:01 pm";
                      const lost_time = "12:01 am";
                      const maxvalue = 1;
                      var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                      var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                        "HH:mm"
                      );
                      var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                      const startTime = dt1; //'01/01/2011 10:20:45';
                      const endTime = dt2; //'01/01/2011 5:10:10';
                      var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                      if (
                        parseInt(endTime.replace(regExp, "$1$2$3")) >
                          parseInt(startTime.replace(regExp, "$1$2$3")) &&
                        parseInt(endTime.replace(regExp, "$1$2$3")) <
                          parseInt(dt3.replace(regExp, "$1$2$3"))
                      ) {
                        checkTime = true;
                        return checkTime;
                      }
                    }
                  });
                }
              });
            }


            if (checkTime) {
              result.push(i);
            }
          });
        }
      });
    }, 1000);

   
    setTimeout(() => {
      this.setState({ packageData: result });
    }, 1000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ packageData: packageData });
      }
    }, 1000);
  };


  changePrice = (value) => {
    this.setState({ packageData: null });
  
    let name = value.min + "-" + value.max;
    let applied = [...this.state.appliedFilterVal];

    let catlength = applied.length;
    let PriceRangeVal = "abc";
   
    applied.map((i, j) => {
      if (i.value == "priceRange") {
        PriceRangeVal = j;
        return PriceRangeVal;
      }
    });

 
    if (PriceRangeVal == "abc") {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: "priceRange" }],
      });
    } else {
      applied[PriceRangeVal] = { label: name, value: "priceRange" };
      this.setState({
        appliedFilterVal: applied,
      });
    }

    const packagedData = [...this.state.packageListFilter];

    const result = [];

    packagedData.map((i, j) => {
      {
        Object.values(i).map((key1, index) => {
        
          if (
            parseInt(key1.tour_price) >= value.min &&
            parseInt(key1.tour_price) <= value.max
          ) {
            result.push(i);
          }
        });
      }
    });

  
    setTimeout(() => {
      this.setState({ packageData: result });
    }, 1000);
  };

  RemoveSingleFilter = (i, index, maxValue) => {
  
    this.setState({ packageData: null });

    let applied = [...this.state.appliedFilterVal];

    applied.splice(index, 1);

    this.setState({
      appliedFilterVal: applied,
    });

    document.getElementsByName(i.label).checked = false;

    this.setState((prevState) => prevState.checkedItems.set(i.label, false));

    let PriceRange = { min: 0, max: maxValue };

    this.setState({ valuePrice: PriceRange });

    const packagedData = [...this.state.packageListFilter];

    const result = [];

   
    setTimeout(() => {
      packagedData && packagedData.map((i, j) => {
        if (i != "no_package") {
            Object.values(i).map((key1, index) => {
                if(this.state.appliedFilterVal.length> 0){
                this.state.appliedFilterVal.map((item, index) => {
                   
                    if(this.state.defaultZonevalPackage.toLowerCase().trim() === 'seychelles'){
                      if(key1.tour_data.tour_region_name.toLowerCase().trim() === item.label) {
                        result.push(i);
                      }
                    }else{
                      if(key1.tour_data.tour_location.toLowerCase().trim() === item.label){
                        result.push(i);
                      }
                    }
                  });
              }
            });
          }
        });
    }, 1000); 
      

    setTimeout(() => {
      packagedData.map((i, j) => {
        Object.values(i).map((key1, index) => {
          let checkTime = false;
          // Duration Data Filter
          if (this.state.appliedFilterVal.length > 0) {
            this.state.appliedFilterVal.map((item, index) => {
              let Minhours = 0;
              let Maxhours = 0;
              if(key1.tour_duration.flexible_id ==1){
                if(key1.tour_duration.flexible_time ==2){
                Minhours = parseInt(key1.tour_duration.flexible_min_hours);
                Maxhours = parseInt(key1.tour_duration.flexible_max_hours);
                }else{
                   Minhours = (parseInt(key1.tour_duration.flexible_min_hours)/60);
                  Maxhours = (parseInt(key1.tour_duration.flexible_max_hours)/60);
                }
              }else{
                Minhours = parseInt(key1.tour_duration.flexible_min_hours); 
                Maxhours = parseInt(key1.tour_duration.flexible_max_hours); 
              }
              if (item.label == "Up to 1 hour") {
                const minvalue = 0;
                const maxvalue = 1;
                if (
                  Minhours >= minvalue &&
                  Maxhours <= maxvalue
                ) {
                  result.push(i);
                }
              
              } else if (item.label == "1 to 4 hours") {
                const minvalue = 1;
                const maxvalue = 4;
                if (
                  Minhours >= minvalue &&
                  Maxhours <= maxvalue
                ) {
                  result.push(i);
                }
              } else if (item.label == "4 hours to 1 day") {
                const minvalue = 4;
                const maxvalue = 24;
                if (
                  Minhours >= minvalue &&
                  Maxhours <= maxvalue
                ) {
                  result.push(i);
                }
              } else if (item.label == "1 to 3 days") {
                const mindays = 1;
                const maxdays = 3;
                if (
                  parseInt(key1.tour_duration.day_number) >= mindays &&
                  parseInt(key1.tour_duration.day_number) <= maxdays
                ) {
                  result.push(i);
                }
              } else if (item.label == "3+ days") {
                const mindays = 3;

                if (
                  parseInt(key1.tour_duration.day_number) >= mindays &&
                  parseInt(key1.tour_duration.day_number) < mindays
                ) {
                  result.push(i);
                }
              }
            });
          } else {
            result.push(i);
          }
        });
      });
    }, 1000);

    setTimeout(() => {
      packagedData.map((i, j) => {
        {
          Object.values(i).map((key1, index) => {
            let checkTime = false;
           
            key1.tour_time.map((key2, index) => {
              if (this.state.appliedFilterVal.length > 0) {
                this.state.appliedFilterVal.map((item, index) => {
                  if (item.label == "Morning") {
                    const from_time = "05:59 am";
                    const lost_time = "12:01 pm";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  } else if (item.label == "Afternoon") {
                    const from_time = "12:01 pm";
                    const lost_time = "05:00 pm";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  } else if (item.label == "Evening and night") {
                    const from_time = "05:01 pm";
                    const lost_time = "12:01 am";
                    const maxvalue = 1;
                    var dt1 = moment(from_time, ["h:mm A"]).format("HH:mm");
                    var dt2 = moment(key2.from_time, ["h:mm A"]).format(
                      "HH:mm"
                    );
                    var dt3 = moment(lost_time, ["h:mm A"]).format("HH:mm");

                    const startTime = dt1; //'01/01/2011 10:20:45';
                    const endTime = dt2; //'01/01/2011 5:10:10';
                    var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
                    if (
                      parseInt(endTime.replace(regExp, "$1$2$3")) >
                        parseInt(startTime.replace(regExp, "$1$2$3")) &&
                      parseInt(endTime.replace(regExp, "$1$2$3")) <
                        parseInt(dt3.replace(regExp, "$1$2$3"))
                    ) {
                      checkTime = true;
                      return checkTime;
                    }
                  }
                });
              }
            });

            if (checkTime) {
              result.push(i);
            }
          });
        }
      });
    }, 1000);

   
    setTimeout(() => {
      this.setState({ packageData: result });
    }, 1000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ packageData: packagedData });
      }
    }, 1000);
  };

  RemoveMultipleFilter = (maxValue) => {
    this.setState({ packageData: null });
    this.setState({
      appliedFilterVal: [],
    });
    this.setState({ packageList: null });
    let PriceRange = { min: 0, max: maxValue };

    this.setState({ valuePrice: PriceRange });

    const clearCheckedItems = new Map();
    this.setState({ checkedItems: clearCheckedItems });

    const packagedData = [...this.state.packageListFilter];

    setTimeout(() => {
      this.setState({ packageData: packagedData });
    }, 1000);
  };

  render() {
    const menuClass = `panel-dropdown${
      this.state.dropdownVisible ? " active" : ""
    }`;
    const { value, suggestionsPackage } = this.state;

    const inputPropsPackage = {
      placeholder: "Enter Location or package name",
      type: "search",
      value,
      onChange: this.onChangePackage,
    };
    return (
      <React.Fragment>
        <div>
        <Helmet>
      <title>Best packages in Seychelles- Holidays Seychelles</title>
        <meta name="description" content="Get best booking rates and packages for beautiful attractions in Seychelles." />
        <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
      </Helmet>
      <h1 style={{color:'#fff'}}>Best packages in Seychelles- Holidays Seychelles</h1>
          <NewHeader page={"inner_listing"} tabName={3} />
          <div
            className={`${
              this.state.nav == true
                ? "_Hlisting_header_new"
                : "_Hlisting_header"
            }`}
          >
            <div className="container hsw">
              <div className="hsw_inner">
                <div
                  className="hsw_inputBoxActivity  "
                  onClick={this.showZonelistdivPackage}
                >
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                 Location or package name
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZonevalPackage.length >= 50
                      ? `${this.state.defaultZonevalPackage.substring(
                          0,
                          50
                        )}...`
                      : this.state.defaultZonevalPackage}
                  </p>
                  <div className="focused_div">
                    {this.state.showResultsPackage ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 30 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestionsPackage}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequestedPackage
                          }
                          onSuggestionSelected={
                            this.onSuggestionSelectedPackage
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequestedPackage
                          }
                          getSuggestionValue={this.getSuggestionValuePackage}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainerPackage
                          }
                          renderSuggestion={this.renderSuggestionPackage}
                          inputProps={inputPropsPackage}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className="hsw_inputBoxActivity"
                  onClick={this.checkinandcheckoutPackage}
                >
                  <span className="checkinandcheckout">
                    <label
                      for="checkin"
                      className="lbl_input latoBold font12 blueText capText"
                    >
                      Add when you want to go
                    </label>
                  </span>
                  <p className="hsw_inputField font16 whiteText">
                    {this.state.selectPackageDay}
                  </p>
                  <div onClick={this.handleBodyClick}>
                    {this.state.checkinShowPackage && (
                      <DayPicker
                        className="activityDate"
                        onDayClick={this.handlePackageDayClick}
                        selectedDays={this.state.selectPackageDayNew}
                        disabledDays={{ before: new Date() }}
                        month={new Date()} // Ensure the current month is displayed initially
                        fromMonth={new Date()} // Disable navigation to months before today
                      />
                    )}
                  </div>
                </div>
                <span
                  className="primaryBtn hsw_searchButton"
                  onClick={this.SearchListing}
                >
                  Search
                </span>
              </div>
            </div>
          </div>
      

          <div className="container makeFlex spaceBetween">
            <div
              className="container makeFlex spaceBetween"
              style={{ marginTop: 50, padding: "10px" }}
            >
              {/* {this.state.packageData == "no_package" || this.state.packageData == null?(
                <></>
              ) : (
                <> */}
                  {this.state.FiltersData == null ? (
                    <>
                      <div className="filterWrap appendRight30">
                        <div className="appendBottom35">
                          <p className="makeFlex spaceBetween end">
                            <span className="latoBold font20 blackText">
                              <Skeleton style={{ width: 240, height: 30 }} />
                            </span>
                          </p>

                          <div
                            className="filterRow"
                            id="hlistpg_fr_star_category"
                          >
                            <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                              <Skeleton style={{ width: 240, height: 26 }} />
                            </div>
                            <ul className="filterList">
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                              <li>
                                <label>
                                  <Skeleton style={{ width: 20, height: 20 }} />
                                  <Skeleton
                                    style={{
                                      width: 190,
                                      height: 15,
                                      marginLeft: 10,
                                    }}
                                  />
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          className="filterRow"
                          id="hlistpg_fr_star_category"
                        >
                          <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                            <Skeleton style={{ width: 240, height: 26 }} />
                          </div>
                          <ul className="filterList">
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                          </ul>
                        </div>

                        <div
                          className="filterRow"
                          id="hlistpg_fr_star_category"
                        >
                          <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                            <Skeleton style={{ width: 240, height: 26 }} />
                          </div>
                          <ul className="filterList">
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                            <li>
                              <label>
                                <Skeleton style={{ width: 20, height: 20 }} />
                                <Skeleton
                                  style={{
                                    width: 190,
                                    height: 15,
                                    marginLeft: 10,
                                  }}
                                />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                     ) : this.state.FiltersData == "no_package" ? (
                      <></>
                  ) : (
                    <div className="filterWrap appendRight30">
                      {this.state.appliedFilterVal.length > 0 ? (
                        <div className="appliedFiltersContainer">
                          <div
                            className="makeFlex spaceBetween end appendBottom20"
                            style={{
                              marginBottom: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="font20 blackText">
                              Applied Filters
                            </span>
                            <a
                              className="latoBold font12 capText"
                              href="javascript:;"
                              onClick={() =>
                                this.RemoveMultipleFilter(
                                  this.state.FiltersData.max_price
                                )
                              }
                            >
                              Clear
                            </a>
                          </div>
                          <ul className="appliedFilters">
                            {this.state.appliedFilterVal.map((i, j) => (
                              <li>
                                <span className="latoBold" style={{textTransform: 'capitalize'}}>{i.label}</span>
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    this.RemoveSingleFilter(
                                      i,
                                      j,
                                      this.state.FiltersData.max_price
                                    )
                                  }
                                ></a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <p
                          className="makeFlex spaceBetween end"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <span className="font20 blackText">
                            Select Filters
                          </span>
                        </p>
                      )}
                        
                        <div
                        className="filterRow"
                        id="hlistpg_fr_star_category"
                        style={{ marginBottom: 30 }}
                      >
                        <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                          {" "}
                          Price
                        </div>
                        <InputRange
                          maxValue={this.state.FiltersData.max_price}
                          minValue={0}
                          value={this.state.valuePrice}
                          onChange={(value) =>
                            this.setState({ valuePrice: value })
                          }
                          onChangeComplete={(value) => this.changePrice(value)}
                        />
                        <span className="minValue">
                          <FontAwesomeIcon
                            icon={faEuroSign}
                            style={{ fontSize: "13px", color: "#555" }}
                          ></FontAwesomeIcon>{" "}
                          {this.state.valuePrice.min}
                        </span>
                        <span className="maxValue">
                          <FontAwesomeIcon
                            icon={faEuroSign}
                            style={{ fontSize: "13px", color: "#555" }}
                          ></FontAwesomeIcon>{" "}
                          {this.state.valuePrice.max}
                        </span>
                      </div>
                        <div className="filterRow" id="hlistpg_fr_star_category">
                          <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                            Location
                          </div>
                          <ul className="filterList">
                        {Object.values(this.state.FiltersData.location).sort((a, b) => parseFloat(b.count) - parseFloat(a.count)).map(
                          (i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilterLocation}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.packageData == null ? true : false
                                  }
                                />
                                <span className="cat_star" style={{textTransform:'capitalize'}}>
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score">
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          )
                        )}
                      </ul>
                        </div>
                        {/* <div className="filterRow" id="hlistpg_fr_star_category">
                        <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                          Category
                        </div>
                    
                    { this.state.CategotyThem &&(<CheckboxTree
                        nodes={this.state.CategotyThem &&(this.state.CategotyThem)}
                        checked={this.state.checked}
                        expanded={this.state.expanded}
                        onCheck={checked => this.CheckAppliedFilterCategory(checked)}
                        onExpand={expanded => this.setState({ expanded })}
                        // iconsClass="fa4"
                        icons={{
                          check: <span className="rct-icon rct-icon-check" />,
                          uncheck: <span className="rct-icon rct-icon-uncheck" />,
                          halfCheck: <span className="rct-icon rct-icon-half-check" />,
                          expandClose: <span className="rct-icon rct-icon-expand-close" />,
                          expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                          expandAll: <span className="rct-icon rct-icon-expand-all" />,
                          collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                          parentClose: <span className="rct-icon rct-icon-parent-close" />,
                          parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                          leaf: <span className="rct-icon rct-icon-leaf" />,
                      }}
                      disabled={this.state.packageData == null ? true : false}
                        />)}
                      </div> */}


                      <div className="appendBottom35">
                        <div
                          className="filterRow"
                          id="hlistpg_fr_star_category"
                        >
                          <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                            Duration
                          </div>
                          <ul className="filterList">
                            {Duration.map((item, index) => (
                              <li>
                                <label>
                                  <input
                                    type="checkbox"
                                    className="icheck"
                                    onChange={this.CheckAppliedFilter}
                                    name={item.name}
                                    checked={
                                      !!this.state.checkedItems.get(item.name)
                                    }
                                    disabled={
                                      this.state.packageData == null
                                        ? true
                                        : false
                                    }
                                  />
                                  <span className="cat_star">{item.name}</span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>

                       
                      </div>

                      <div className="filterRow" id="hlistpg_fr_star_category">
                    <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                      Time of Day
                    </div>
                    <ul className="filterList">
                      {TimeOfDays.map((item, index) => (
                        <li style={{marginBottom: '16px'}}>
                          <label>
                            <input
                              type="checkbox"
                              className="icheck"
                              onChange={this.CheckTimeOfDaysFilter}
                              name={item.name}
                              checked={!!this.state.checkedItems.get(item.name)}
                              disabled={
                                this.state.packageData == null ? true : false
                              }
                            />
                            <span className="cat_star">{item.name}</span><br></br>
                            <span className="cat_starsmaill">{item.smallLabel}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                      
                     
                    </div>
                  )}
                {/* </>
              )} */}

              <div className="listingWrap">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {this.state.packageData === null ? (
                   <>
                   <span style={{ color: "#55575b", paddingTop: 0 }}>
                   
                   </span>
                 </>
                  ) : (
                    <>
                      {this.state.packageData == "no_package" ? (
                         <>
                         <span style={{ color: "#55575b", paddingTop: 0 }}>
                          {/* 0 result */}
                         </span>
                       </>
                      ) : (
                        <>
                           <span style={{ color: "#55575b", paddingTop: 0 }}>
                            <b>{this.state.packageData.length == 1
                              ? "1 Result"
                              : this.state.packageData.filter(
                                  (i) => i != "no_package"
                                ).length + " Results"}</b>
                            </span>
                        </>
                      )}
                    </>
                  )}
                  {this.state.packageData !== null &&  this.state.packageData != "no_package" &&( 
                  <div
                    className="hsw_viewMap_content hsw_viewMap"
                    style={{ display: "flex", paddingTop: 0 }}
                  >
                    <span
                      class="latoBold"
                      style={{ color: "#55575b", fontWeight: "600 !important" }}
                    >
                      {" "}
                      Sort By:&nbsp;
                    </span>
                    <div class="customSelect">
                      <span
                        class="customSelectTitle  latoBold"
                        style={{ color: "#008cff" }}
                        onClick={this.showSorting}
                      >
                        {this.state.sortingSelect}
                      </span>
                      {this.state.isDropdownOpen && (
                        <ul class="customSelectOptions latoBold" style={{left: '35%', top: '23px'}}>
                          {SortArr.map((item, index) => (
                            <li
                              className={
                                this.state.sortingSelect == item.name
                                  ? "selectedActiveSort"
                                  : ""
                              }
                              key={index}
                              onClick={() => this.handleSortingChange(item)}
                            >
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>)}

                </div>
                <div className="container container-custom margin_30_95">
                  {this.state.packageData === null ? (
                    <>
                      <HotelListSkeleton />
                      <HotelListSkeleton />
                      <HotelListSkeleton />
                    </>
                  ) : this.state.packageData == "no_package"  || this.state.packageData.length === 0 ? (
                    <ExperiencesSoldout experienceurl={'?t=Mw=='}/>
                  ) : (
                    <>
                      {this.state.packageData.map((i, j) => {
                        if (i != "no_package") {
                          return (
                            <>
                              {Object.values(i).map((key1, index) => (
                                <TourPackageList
                                  tour_gallery={key1?.tour_gallery}
                                  tour_description={key1?.tour_description}
                                  tour_price={key1?.tour_price}
                                  tour_data={key1?.tour_data}
                                  dateSelected={this.state.selectPackageDayNew}
                                  tour_day={key1?.tour_day}
                                  tour_time={key1?.tour_timing}
                                  tour_exclusion={key1?.tour_exclusion}
                                  tour_inclusion={key1?.tour_inclusion}
                                  specialOffer={key1?.specialOffer}
                                  forMeal={key1?.forMeal}
                                  tour_price_details={key1?.tour_price_details}
                                  tour_price_schedule={key1?.tour_price_schedule}
                                  tour_price_schedule_new={key1?.tour_price_schedule_new}
                                  tour_duration={key1?.tour_duration}
                                  freeroomCancellation={key1?.freeroomCancellation}
                                />
                              ))}
                            </>
                          );
                        }
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TawkToWidget />
      </React.Fragment>
    );
  }
}
