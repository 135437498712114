import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import NewHeader from "../Component/NewHeader/NewHeader.js";
import Footer from '../Component/Footer';
import {Helmet} from "react-helmet";

const Visitplanner = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
    <div className="MainStaticMainComponentContaint">
    <Helmet>
      <title>Best Visit Planner : Holidays Seychelles</title>
      <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
    </Helmet>
    <h1 style={{}}> Best Visit Planner : Holidays Seychelles</h1>
      <NewHeader page={"inner_listing"} />
      <div
        className="StaticlandingCard appendBottom20 StaticSection page-section" style={{marginTop:100}}>

            <div className="StaticContainer" id="container">
                <div class="row">
                <div className="aboutPage">
                    <h1 className="aboutHeading">Visit Planner</h1>
                    <p>
                        <i> We are your local connection in the Republic of Seychelles!</i>
                    </p>
                    <p className="text">The Visit Planner “Trip Builder” is an APP that will allow you to build your itinerary that can be shared by email to family and friends in the form of a PDF brochure.</p>
                    <p className="text">You will be able to compare and edit the itinerary in the “My Trip” Area. My Trip is a secure environment controlled by a user name and password that only you can access. </p>
                    <p className="text">Whilst building your trip, all promotional offers and deals will also be available to you. These will be clearly visible in your brochure.</p>
                    <p className="text">One point of concern. This website only displays accommodations and experiences with availability at the time that you are searching. <spna style={{ fontWeight: "bold" }}>WE DO NOT MAINTAIN THIS AVAILABILITY FOR YOU.</spna> The availability is released after 10 minutes of your initial search so that other visitors can book. When you decide to confirm your bookings. We have to check availability again and we do not promise that it will be there. It is a first come first serve basis.</p>
                   
                </div>
                </div>
            </div>
      </div>
      </div>
      <Footer />
    </div>


  );
};

export default Visitplanner;
