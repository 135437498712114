import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        height: 40,
        fontStyle: 'bold',
        overflowY: 'scroll',
        justifyContent: 'center',
    },
    refrence: {
      width: '20%',
      textAlign: 'left',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
      paddingTop: 15,
      height: 40,
  },
    description: {
        width: '40%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        paddingTop: 15,
        height: 40,
    },
    qty: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingLeft: 8,
        paddingTop: 15,
        height: 40,
    },
    rate: {
      width: '10%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'right',
      paddingRight: 8,
      paddingTop: 15,
      height: 40,
  }
    // rate: {
    //     width: '15%',
    //     borderRightColor: borderColor,
    //     borderRightWidth: 1,
    //     textAlign: 'right',
    //     paddingRight: 8,
    // },
    // amount: {
    //     width: '15%',
    //     textAlign: 'right',
    //     paddingRight: 8,
    // },
  });
 

const InvoiceTableRow = (items) => {
  // let  items = [
  //       {
  //         sno: 1,
  //         desc: "ad sunt culpa occaecat qui",
  //         qty: 5,
  //         rate: 405.89,
  //       }
  //     ]
  var items1 = (JSON.stringify(items));
  var items2 = JSON.parse(items1);
  var items3 = JSON.parse(items2.items);
    const rows =  ( 
        <View style={styles.row} key={1}>
            <Text style={styles.refrence}>{items3.booking_refrence_id}</Text>
            <Text style={styles.description}>{items3.property_name}</Text>
            <Text style={styles.qty}>{items3.checkin_date}-{items3.checkout_date}</Text>
            <Text style={styles.rate}>{items3.numberof_night}</Text>
            {/* <Text style={styles.rate}>{item.rate}</Text>
            <Text style={styles.amount}>{(item.qty * item.rate).toFixed(2)}</Text> */}
            
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};


  
export default InvoiceTableRow