import React, { useEffect } from "react";
import "./styles.css";
import NewHeader from "../Component/NewHeader/NewHeader";
import Footer from "../Component/Footer";
import {Helmet} from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
      <div className="MainStaticMainComponentContaint">
      <Helmet>
      <title>Terms of Use : Holidays Seychelles</title>
      <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
    </Helmet>
    <h1 style={{}}> Terms of Use : Holidays Seychelles</h1>
        <NewHeader page={"inner_listing"} />
        <div
          className="StaticlandingCard appendBottom20 StaticSection page-section"
          style={{ marginTop: 100 }}
        >
          <div className="StaticContainer" id="container">
            <div class="row">
              <div className="aboutPage">
                <h1 className="aboutHeading">Terms of Use</h1>

                <h3>Disclaimer</h3>
                <p className="text">
                  We have done our upmost to provide accurate information.
                  However, within the technological sphere there are always
                  space for bugs, errors, problems or other limitations to
                  manifest itself without warning. The owner of this site
                  assumes no liability or responsibility for any errors or
                  omissions on this site, or for any loss or damage arising
                  directly or indirectly from the possession, publication or use
                  of or reliance on information obtained from this website. It
                  is provided in good faith, but without express or implied
                  warranty.
                </p>
                <h3>Accuracy of information</h3>
                <p className="text">
                  Holidays (Seychelles) attempts to ensure that the information
                  contained in its web site and those of its partners is
                  accurate and up to date, but people intending to act on any
                  information contained on these sites should first check with
                  Holidays (Seychelles) or the local e-marketplace
                  owner/operator to ascertain whether any later information is
                  available
                </p>
                <h3>Obligations</h3>
                <p className="text">
                  All bookings made from this website will be respected by
                  contract between Holidays (Seychelles) and the supplier.
                  However, in normal operation it is not a perfect world and
                  issues may arise. When such issue arise the supplier will meet
                  their obligations with and equivalent product.
                </p>
                <h3>External links</h3>
                <p className="text">
                  A project of this size rely on many third party API that
                  provide connectivity between systems. Holidays (Seychelles)
                  and partner web sites, in addition to links to other partner
                  sites, may contain links to other websites not associated with
                  Holidays (Seychelles). Such links are not endorsements of any
                  information, products or services in such sites, and no
                  information in external sites has been endorsed or approved by
                  us
                  <br />
                  If you have any questions in relation to the above please
                  contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
