import React, { Component } from "react";
import "./PlanTrips.css";
import { Helmet } from "react-helmet";
import NewHeader from "../NewHeader/NewHeader";
import EmptyImage from "../../assets/images/Empty.png";
import CancelledImage from "../../assets/images/Cancelled.png";
import Completed from "../../assets/images/completed-empty.png";
import { ScrollLink, Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faChild,
  faGlassMartiniAlt,
  faWifi,
  faEuroSign,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import {
  getUpcomingBooking,
  getCancelledBooking,
  getCompletedBooking,
  CancelBookingConfirm,
  CancelBookingConfirmHs,
  getExprerianceUpcomingBooking,
  getExprerianceCancelledBooking,
  getExprerianceCompletedBooking,
  CancelBookingConfirmHsTour,
  getwishlistCartData,
  WishlistDelete,
  Tripurl
} from "../axios";

import Skeleton from "react-loading-skeleton";
import moment from "moment";
// import PDF, { Text, AddPage, Line, Image, Table, Html } from 'jspdf-react';
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./Invoice/Invoice";
import InvoiceAcco from "./InvoiceAcco/Invoice";
import invoice from "./Invoice/invoice_data";

const styleH1 = {
  fontSize: "15px",
  textAlign: "center",
  color: "red",
};

const invisibleStyle = {};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
// Create Document Component
// const MyDocument = (props) => (
//   // <Document>
//   //   <Page size="A4" style={styles.page}>
//   //     <View style={styles.section}>
//   //       <Text>Section #1</Text>
//   //     </View>
//   //     <View style={styles.section}>
//   //       <Text>Section #2</Text>
//   //     </View>
//   //   </Page>
//   // </Document>

//   <Invoice invoice={props} />
// );
class PlanTrips extends Component {
  constructor(props) {
    super(props);
    this.state = {
       plannedTrips: true,
      upcoming: false,
      cancelled: false,
      Completed: false,
      ChangeColor: "reviewPlanned",
      upcomingData: null,
      cancelledData: null,
      completedData: null,
      ChangeColorNew: "tripStatusContainer reviewPlannedNew",
      upcomingTourData: null,
      cancelledTourData: null,
      completedTourData: null,
      wishlistData: null,
      emailID:null
    };
  }

  componentDidMount() {
    this.setState({ plannedTrips: true });
    this.setState({ upcoming: false });
    this.setState({ cancelled: false });
    this.setState({ Completed: false });
    this.setState({ ChangeColor: "reviewUpcoming" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewUpcomingNew" });

    const userArr = localStorage.getItem("userDetails");
    const userArrNew = JSON.parse(userArr);
    const customer_id = userArrNew?.uid;
    const email_id = userArrNew?.email;

    if(!email_id){
      this.props.history.push("/");
    }
  
    this.setState({ emailID: email_id });
    let booking_status = 1;

    // getCustomerLogin(email_id).then(
    //   (data) => {
    //     this.setState({ CustomerDetails: data });
    //   },
    //   (error) => {}
    // );

    getwishlistCartData(customer_id).then(
      (data) => {
        this.setState({ wishlistData: data });
      },
      (error) => {}
    );
    // getUpcomingBooking(customer_id, booking_status).then(
    //   (data) => {
    //     this.setState({ upcomingData: data });
    //   },
    //   (error) => {}
    // );

    // getExprerianceUpcomingBooking(customer_id, booking_status).then(
    //   (data) => {
    //     this.setState({ upcomingTourData: data });
    //   },
    //   (error) => {}
    // );
  }
  PlannedChange = () => {
    this.setState({ plannedTrips: true });
    this.setState({ upcoming: false });
    this.setState({ cancelled: false });
    this.setState({ Completed: false });
    this.setState({ ChangeColor: "reviewPlanned" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewPlannedNew" });
    const userArr = localStorage.getItem("userDetails");
    const userArrNew = JSON.parse(userArr);
    const customer_id = userArrNew.uid;
    getwishlistCartData(customer_id).then(
      (data) => {
        this.setState({ wishlistData: data });
      },
      (error) => {}
    );

  };

  upcomingChange = () => {
     this.setState({ plannedTrips: false });
    this.setState({ upcoming: true });
    this.setState({ cancelled: false });
    this.setState({ Completed: false });
    this.setState({ ChangeColor: "reviewUpcoming" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewUpcomingNew" });

    const userArr = localStorage.getItem("userDetails");
    const userArrNew = JSON.parse(userArr);
    const customer_id = userArrNew.uid;
    let booking_status = 1;
    getUpcomingBooking(customer_id, booking_status).then(
      (data) => {
        this.setState({ upcomingData: data });
      },
      (error) => {}
    );

    getExprerianceUpcomingBooking(customer_id, booking_status).then(
      (data) => {
        this.setState({ upcomingTourData: data });
      },
      (error) => {}
    );
  };

  cancelledChange = () => {
    this.setState({ plannedTrips: false });
    this.setState({ upcoming: false });
    this.setState({ cancelled: true });
    this.setState({ Completed: false });
    this.setState({ ChangeColor: "reviewCancelled" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewCancelledNew" });

    const userArr = localStorage.getItem("userDetails");
    const userArrNew = JSON.parse(userArr);
    const customer_id = userArrNew.uid;
    let booking_status = 3;
    getCancelledBooking(customer_id, booking_status).then(
      (data) => {
        this.setState({ cancelledData: data });
      },
      (error) => {}
    );

    //Expreriance Cancelled
    getExprerianceCancelledBooking(customer_id, booking_status).then(
      (data) => {
        this.setState({ cancelledTourData: data });
      },
      (error) => {}
    );
  };

  CompletedChange = () => {
    this.setState({ plannedTrips: false });
    this.setState({ upcoming: false });
    this.setState({ cancelled: false });
    this.setState({ Completed: true });
    this.setState({ ChangeColor: "reviewCompleted" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewCompletedNew" });

    const userArr = localStorage.getItem("userDetails");
    const userArrNew = JSON.parse(userArr);
    const customer_id = userArrNew.uid;
    let booking_status = 1;
    getCompletedBooking(customer_id, booking_status).then(
      (data) => {
        this.setState({ completedData: data });
      },
      (error) => {}
    );
    //Expreriance Completed
    getExprerianceCompletedBooking(customer_id, booking_status).then(
      (data) => {
        this.setState({ completedTourData: data });
      },
      (error) => {}
    );
  };
  CancelBookingConfirmButton = (Locator, CancellationPolicy) => {

    let CurrentDate = new Date();
    let CurrentDateNew = moment(CurrentDate).format("YYYY-MM-DD");

    let PercentagePrice = 0;
    CancellationPolicy.map((item, key) => {
      let d1 = new Date("2021-05-10");
      let d2 = new Date(item.attributes.DateFrom);
      if (d1.getTime() >= d2.getTime()) {
        PercentagePrice = item.attributes.PercentPrice;
        return PercentagePrice;
      }
    });


    CancelBookingConfirm(Locator, PercentagePrice).then(
      (data) => {
        // this.setState({ completedData: data });
        window.location.reload(true);
      },
      (error) => {}
    );
  };

  //Hotal HS Cancellation

  CancelBookingConfirmButtonHs = (hsbooking_id) => {
    CancelBookingConfirmHs(hsbooking_id).then(
      (data) => {
        window.location.reload(true);
      },
      (error) => {}
    );
  };

  //Tour HS Cancellation
  CancelBookingConfirmButtonHsTour = (hstourbooking_id) => {
    CancelBookingConfirmHsTour(hstourbooking_id).then(
      (data) => {
        window.location.reload(true);
      },
      (error) => {}
    );
  };


  handleRemove = (wishlist_id) => {
    let text =
    "Are you sure you want to delete this item.";
    if (window.confirm(text) == true) {
      WishlistDelete(
        wishlist_id
      ).then(
        (data) => {
          // const url = "/mytripplan";
          // window.location.replace(url);
          window.location.reload(true);
        },
        (error) => {}
      );
    }
  };

  starsCount = (HotelCategory) => {
    if (HotelCategory == 1) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelCategory == 2) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelCategory == 3) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelCategory == 4) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelCategory == 5) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
        </>
      );
    }
  };
  render() {
    // const GeneratePdf = () => (

    //     <PDFDownloadLink document={<MyDocument bookingID  />} fileName="somename.pdf">
    //       {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Invoice')}
    //     </PDFDownloadLink>

    // )

    // bookingID={hstourbooking_id} bookingName={item.tour
    //   .tour_name} bookingAmount={this.state.upcomingTourData.expreriance.tourbookingArr[
    //     index
    //   ].tour_charges.slice(5, 13)} bookingData={moment(
    //     this.state
    //       .upcomingTourData
    //       .expreriance
    //       .tourbookingArr[index]
    //       .tour_booking_date
    //   ).format("D MMMM Y")} />}

    return (
      <div>
        <Helmet>
          <title> Plan your Best Trip @ Holiday Seychelles</title>
          <meta
            name="description"
            content="Plan your honeymoon in one of the beautiful places in the world - Holiday Seychelles."
          />
          <meta
            name="keywords"
            content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"
          />
        </Helmet>
        <h1 style={{}}> Plan your Best Trip @ Holiday Seychelles</h1>
        <NewHeader page={"inner_listing"} />
        <div className="TripContainer" id="container">
          <div className={this.state.ChangeColor} style={{ marginTop: "-5px" }}>
            <div className="landingContainer">
              <h4>My Trips</h4>
              <p>Plan Trips and Manage Bookings</p>
            </div>
          </div>

          <div className={this.state.ChangeColorNew}>
            <div className="tripStatusWrap">
              <ul>
                <li
                  className={
                    this.state.plannedTrips && "active ColorPlannedTrips"
                  }
                  onClick={this.PlannedChange}
                >
                  <a className="mytripmakeFlex">
                    <span className="cm__myTripSprt--tabUpcoming cm__myTripSprt appendRight10"></span>
                    <span className="tripStatusWrap__statusText">
                      <span className="font16 latoBlack capText">
                        Planned Trips
                      </span>
                      <span className="font12 latoBold"></span>
                    </span>
                  </a>
                </li>
                <li
                  className={this.state.upcoming && "active ColorUpcoming"}
                  onClick={this.upcomingChange}
                >
                  <a className="mytripmakeFlex">
                    <span className="cm__myTripSprt--tabUpcoming cm__myTripSprt appendRight10"></span>
                    <span className="tripStatusWrap__statusText">
                      <span className="font16 latoBlack capText">Confirmed</span>
                      <span className="font12 latoBold"></span>
                    </span>
                  </a>
                </li>
                <li
                  className={this.state.cancelled && "active ColorCancelled"}
                  onClick={this.cancelledChange}
                >
                  <a className="mytripmakeFlex">
                    <span className="cm__myTripSprt--tabCancelled--inactive cm__myTripSprt appendRight10"></span>
                    <span className="tripStatusWrap__statusText">
                      <span className="font16 latoBlack capText">
                        Cancelled
                      </span>
                      <span className="font12 latoBold"></span>
                    </span>
                  </a>
                </li>
                <li
                  className={this.state.Completed && "active ColorCompleted"}
                  onClick={this.CompletedChange}
                >
                  <a className="mytripmakeFlex">
                    <span className="cm__myTripSprt--tabCompleted--inactive cm__myTripSprt appendRight10"></span>
                    <span className="tripStatusWrap__statusText">
                      <span className="font16 latoBlack capText">
                        Completed
                      </span>
                      <span className="font12 latoBold"></span>
                    </span>
                  </a>
                </li>
              </ul>
              <div className="txtFild "></div>
            </div>
          </div>
          <div className="contentWrapper myTrips">
            <div>
              <div className="tripListingWrap">
                <div>
                  {this.state.plannedTrips == true ? (
                    <div className="myTripLstngLobCrd forPlanned">
                      {/* <div className="emptyErr">
                        <div className="emptyErr--imgCont">
                          <img src={EmptyImage} alt="Upcoming Image" />
                        </div>
                        <div>
                          <p className="emptyErr--heading font22 latoBlack appendBottom15">
                            Looks empty, you've no planned trips.
                          </p>
                          <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                            When you book a trip, you will see your itinerary
                            here.
                          </p>
                          <p>
                            <a href="/">
                              <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                Plan a trip
                              </button>
                            </a>
                          </p>
                        
                        </div>
                      </div> */}
                      {this.state.wishlistData == "" ? (
                        <div className="emptyErr">
                          <div className="emptyErr--imgCont">
                            <img src={EmptyImage} alt="Upcoming Image" />
                          </div>
                          <div>
                            <p className="emptyErr--heading font22 latoBlack appendBottom15">
                              Looks empty, you've no Trip Plan's .
                            </p>
                            <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                              When you save your cart, you will see Plan's here
                              here.
                            </p>
                            <p style={{margin: 10}}>
                              <a href="/">
                                <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                  Plan a trip
                                </button>
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.wishlistData == null ? (
                            <>
                              <div
                                className="Container-Tile"
                                style={{ height: 250 }}
                              >
                                <div className="ContainerFlex">
                                  <div
                                    className="ContainerImage"
                                    style={{ padding: "15px 10px 10px 10px" }}
                                  >
                                    <Skeleton
                                      style={{ width: "100%", height: "165px" }}
                                    />
                                  </div>
                                  <div
                                    className="ContainerContent"
                                    style={{ padding: "10px 10px 10px 40px" }}
                                  >
                                    <div
                                      className="nameContainer"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="paymakeFlex appendBottom5">
                                        <h3 className="latoBlack font22 blackText">
                                          <Skeleton
                                            style={{ width: "200px" }}
                                          />
                                        </h3>
                                        <span
                                          className="sRating"
                                          style={{
                                            display: "inline-flex",
                                            marginTop: "5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Skeleton
                                            style={{ width: "100px" }}
                                          />
                                        </span>
                                      </div>
                                      <p>
                                        <Skeleton style={{ width: "300px" }} />
                                      </p>
                                      <h3
                                        className="latoBlack font22 blackText"
                                        style={{
                                          marginTop: 10,
                                          fontSize: 18,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Skeleton style={{ width: "150px" }} />
                                      </h3>

                                      <div class="chkContBooking">
                                        <div class="chkCont__col">
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText 444">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="chkCont__night">
                                            <span>
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="Container-Tile">
                                <div className="ContainerFlex">
                                  <div class="container">
                                    <div class="row">
                                      <div
                                        style={{
                                          //height: "180px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <table className="table table-bordered">
                                          <thead className="thead-dark">
                                            <tr>
                                              <th scope="col">#</th>
                                              {/* <th scope="col">
                                                                Reference ID 
                                                                </th> */}
                                              <th scope="col">Plan Name</th>
                                              {/* <th scope="col">
                                                Quotation Form
                                              </th> */}
                                              <th scope="col">Plan Date</th>
                                               <th scope="col">
                                                View and Manage Trip
                                              </th>
                                              <th scope="col">Action</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {this.state.wishlistData &&
                                              this.state.wishlistData.map(
                                                (item, newindex) => {
                                               // const newIteam= JSON.parse(item.plan_details);
                                                  // const TotalPrice = newIteam.reduce(
                                                  //   (amount, newdata) => parseFloat(newdata.totalamountincluded) + amount,
                                                  //   0
                                                  // );
                                              
                                                  // const TotalPriceAll = newIteam.reduce(
                                                  //   (amount, newdata) => parseFloat(newdata.totalAmount) + amount,
                                                  //   0
                                                  // );
                                                 
                                              
                                                  // const TaxandFee = 0;
                                                  // let TotalPriceInclude = TaxandFee + TotalPrice;
                                                  // let CommissionPrice = (parseFloat(this.state.Commission) / 100) * TotalPriceInclude;
                                                  // let FinalPrice = parseFloat(TotalPriceInclude - CommissionPrice);
                                                  // let OfferPrice = (TotalPriceAll-TotalPriceInclude);
                                                  // let JsonStatePDFAcco = {
                                                  //   booking_amount: TotalPriceInclude,
                                                  //   total_main_amount:TotalPriceAll,
                                                  //    offer_discount: OfferPrice,
                                                  //   numberof_night: 2,
                                                  //   agentdetails: this.state.CustomerDetails,
                                                  //   child: newIteam,
                                                  // };
                                                  // const propertypdf = JSON.stringify(
                                                  //   JsonStatePDFAcco
                                                  // );
                                                 
                                                  return (
                                                    <>
                                                      <tr>
                                                        <th scope="row">
                                                          {newindex + 1}
                                                        </th>

                                                        <td>
                                                          {item.plan_name}
                                                        </td>
                                                        {/* <td>
                                                        {
                                                            <PDFDownloadLink
                                                              document={
                                                                <InvoiceAgent
                                                                invoice={
                                                                  propertypdf
                                                                }
                                                                />
                                                              }
                                                              fileName="Quotation.pdf"
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "#ff0000",
                                                                fontWeight:
                                                                  "400",
                                                              }}
                                                            >
                                                              {({
                                                                blob,
                                                                url,
                                                                loading,
                                                                error,
                                                              }) =>
                                                                loading
                                                                  ? "Loading document..."
                                                                  : "Download Quotation"
                                                              }
                                                            </PDFDownloadLink>
                                                          }
                                                        </td> */}
                                                        <td>
                                                          {/* {moment(
                                                            item.created_date
                                                          ).format("D MMM Y")} */}
                                                            {moment(
                                                            item.checkin_date
                                                          ).format("D MMM Y")} to  {moment(
                                                            item.checkout_date
                                                          ).format("D MMM Y")}
                                                        </td>
                                                       <td> <a href={`${Tripurl}/mytripsavedtoitinerary/${btoa(this.state.emailID)}?plan_id=${btoa(item?.id)}`}>View and Manage Trip</a></td> 
                                                        <td> 
                                                        <FontAwesomeIcon
                                                          icon={faTrash}
                                                          style={{
                                                            fontSize: "14px",
                                                            marginRight: "5px",
                                                            cursor: "pointer",
                                                            marginTop: "10px",
                                                          }}
                                                          onClick={() => {
                                                            this.handleRemove(item?.id)}}
                                                        ></FontAwesomeIcon>
                                                          {/* <button
                                                           onClick={() => {
                                                            this.handleClickWishlist(item?.id, item.plan_details)}}
                                                          class="btn btn-outline-danger"
                                                          style={{lineHeight: '15px' }}
                                                        >
                                                          Open To Cart
                                                        </button> */}
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.upcoming == true ? (
                    <div className="myTripLstngLobCrd forUpcoming">
                      {this.state.upcomingData == "noUpcoming" &&
                      this.state.upcomingTourData == "noUpcoming" ? (
                        <div className="emptyErr">
                          <div className="emptyErr--imgCont">
                            <img src={EmptyImage} alt="Upcoming Image" />
                          </div>
                          <div>
                            <p className="emptyErr--heading font22 latoBlack appendBottom15">
                              Looks empty, you've no upcoming bookings.
                            </p>
                            <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                              When you book a trip, you will see your itinerary
                              here.
                            </p>
                            <p>
                              <a href="/">
                                <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                  Plan a trip
                                </button>
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.upcomingData == null ||
                          this.state.upcomingTourData == null ? (
                            <>
                              <div
                                className="Container-Tile"
                                style={{ height: 250 }}
                              >
                                <div className="ContainerFlex">
                                  <div
                                    className="ContainerImage"
                                    style={{ padding: "15px 10px 10px 10px" }}
                                  >
                                    <Skeleton
                                      style={{ width: "100%", height: "165px" }}
                                    />
                                  </div>
                                  <div
                                    className="ContainerContent"
                                    style={{ padding: "10px 10px 10px 40px" }}
                                  >
                                    <div
                                      className="nameContainer"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="paymakeFlex appendBottom5">
                                        <h3 className="latoBlack font22 blackText">
                                          <Skeleton
                                            style={{ width: "200px" }}
                                          />
                                        </h3>
                                        <span
                                          className="sRating"
                                          style={{
                                            display: "inline-flex",
                                            marginTop: "5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Skeleton
                                            style={{ width: "100px" }}
                                          />
                                        </span>
                                      </div>
                                      <p>
                                        <Skeleton style={{ width: "300px" }} />
                                      </p>
                                      <h3
                                        className="latoBlack font22 blackText"
                                        style={{
                                          marginTop: 10,
                                          fontSize: 18,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Skeleton style={{ width: "150px" }} />
                                      </h3>

                                      <div class="chkContBooking">
                                        <div class="chkCont__col">
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText 444">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="chkCont__night">
                                            <span>
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.upcomingData.ejuniper && (
                                <>
                                  {" "}
                                  {this.state.upcomingData.ejuniper.propertyData.map(
                                    (item, index) => {
                                      const DataArrShow =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.Items
                                          .HotelItem;
                                      if (Array.isArray(DataArrShow)) {
                                        const locatorIndex = item.locatorIndex;
                                        let HotelCategory = "";
                                        let StatusMsg = "";
                                        let numberOfNights = "";
                                        let HotelName = "";
                                        let HotelRoom = "";
                                        let PriceAmount = "";
                                        let price_uplift = "";
                                        let UpliftAmount = "";
                                        let AmountWithUpliftOld = "";
                                        let AddressInfo = "";
                                        let StartDate = "";
                                        let EndDate = "";
                                        let CancellationPolicy = "";
                                        let Locator = "";
                                        let propertypdf = "";
                                        DataArrShow.map(
                                          (BookingEjuniferitem, index) => {
                                            if (locatorIndex == index) {
                                              const DataArr = BookingEjuniferitem;

                                              const HotelCategoryNew =
                                                DataArr.HotelInfo.HotelCategory;

                                              HotelName =
                                                DataArr.HotelInfo.Name;
                                              HotelRoom =
                                                DataArr.HotelRooms.HotelRoom
                                                  .Name;

                                              HotelCategory = HotelCategoryNew.charAt(
                                                0
                                              );

                                              let date1 = new Date(
                                                DataArr.attributes.Start
                                              );
                                              let date2 = new Date(
                                                DataArr.attributes.End
                                              );

                                              let timeDiff = Math.abs(
                                                date1.getTime() -
                                                  date2.getTime()
                                              );
                                              numberOfNights = Math.ceil(
                                                timeDiff / (1000 * 3600 * 24)
                                              );
                                              StartDate =
                                                DataArr.attributes.Start;
                                              EndDate = DataArr.attributes.End;
                                              const Status =
                                                item.ReadBookingResponse
                                                  .BookingRS.Reservations
                                                  .Reservation.attributes
                                                  .Status;
                                              Locator =
                                                item.ReadBookingResponse
                                                  .BookingRS.Reservations
                                                  .Reservation.attributes
                                                  .Locator;
                                              CancellationPolicy =
                                                DataArr.CancellationPolicy
                                                  .PolicyRules.Rule;
                                              PriceAmount = parseFloat(
                                                DataArr.Prices.Price
                                                  .TotalFixAmounts.Service
                                                  .attributes.Amount
                                              );

                                              price_uplift = parseFloat(
                                                this.state.upcomingData.ejuniper
                                                  .uplift[index]
                                              );
                                              UpliftAmount = parseFloat(
                                                (price_uplift / 100) *
                                                  PriceAmount
                                              );
                                              AmountWithUpliftOld = parseFloat(
                                                PriceAmount + UpliftAmount
                                              ); // Amount

                                              if (Status == "PAG") {
                                                StatusMsg =
                                                  "Booking confirmed and paid";
                                              } else if (Status == "OK") {
                                                StatusMsg = "Booking confirmed";
                                              } else if (Status == "CON") {
                                                StatusMsg = "Booking confirmed";
                                              } else if (Status == "CAN") {
                                                StatusMsg = "Booking cancelled";
                                              } else if (Status == "CAC") {
                                                StatusMsg = "Booking cancelled";
                                              } else if (Status == "PRE") {
                                                StatusMsg =
                                                  "Booking on request";
                                              } else if (Status == "PDI") {
                                                StatusMsg =
                                                  "Booking on request";
                                              } else if (Status == "QUO") {
                                                StatusMsg = "Quotation";
                                              }

                                              let JsonStatePDFAcco = {
                                                property_name:
                                                  DataArr.HotelInfo.Name,
                                                booking_amount: AmountWithUpliftOld,
                                                checkin_date: moment(
                                                  DataArr.attributes.Start
                                                ).format("D MMM Y"),
                                                checkout_date: moment(
                                                  DataArr.attributes.End
                                                ).format("D MMM Y"),
                                                booking_refrence_id: this.state
                                                  .upcomingData.ejuniper
                                                  .bookingArr[index]
                                                  .booking_refrence_id,
                                                b_date: moment(
                                                  this.state.upcomingData
                                                    .ejuniper.bookingArr[index]
                                                    .added_on
                                                ).format("D MMM Y"),
                                                numberof_night: numberOfNights,
                                              };
                                              propertypdf = JSON.stringify(
                                                JsonStatePDFAcco
                                              );
                                            }
                                          }
                                        );
                                        return (
                                          <>
                                            <div className="Container-Tile">
                                              <div className="ContainerFlex">
                                                <div
                                                  className="ContainerImage"
                                                  style={{
                                                    padding:
                                                      "15px 1px 10px 1px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      this.state.upcomingData
                                                        .ejuniper
                                                        .property_image[
                                                        index
                                                      ][0]
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="ContainerContent"
                                                  style={{
                                                    padding:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <div
                                                    className="nameContainer"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="paymakeFlexNew">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <h3
                                                          className="latoBlack font22 blackText"
                                                          style={{
                                                            fontSize: 22, marginBottom: 10
                                                          }}
                                                        >
                                                          {HotelName}
                                                        </h3>
                                                        <span
                                                          className="sRating"
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            marginTop: "5px",
                                                            marginLeft: "10px",
                                                          }}
                                                        >
                                                          {this.starsCount(
                                                            HotelCategory
                                                          )}
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <span
                                                          className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                          onClick={() =>
                                                            this.CancelBookingConfirmButton(
                                                              Locator,
                                                              CancellationPolicy
                                                            )
                                                          }
                                                          id="Cancel_Button"
                                                          style={{
                                                            borderRadius: 5,
                                                          }}
                                                        >
                                                          Cancel Booking
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        float: "right",
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <span
                                                        className="font14 latoBold myTripBtn"
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        {
                                                          <PDFDownloadLink
                                                            document={
                                                              <InvoiceAcco
                                                                invoice={
                                                                  propertypdf
                                                                }
                                                              />
                                                            }
                                                            fileName="accommodation.pdf"
                                                            style={{
                                                              fontSize: "9px",
                                                              color: "#000",
                                                              fontWeight: "100",
                                                            }}
                                                          >
                                                            {({
                                                              blob,
                                                              url,
                                                              loading,
                                                              error,
                                                            }) =>
                                                              loading
                                                                ? "Loading document..."
                                                                : "Download Payment Voucher"
                                                            }
                                                          </PDFDownloadLink>
                                                        }
                                                      </span>
                                                    </div>
                                                    <p>
                                                      {AddressInfo}
                                                    </p>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        marginTop: 5,
                                                        fontSize: 18,
                                                        marginBottom: 2,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {HotelRoom &&
                                                        HotelRoom.replace(
                                                          "</br>",
                                                          ", "
                                                        )}
                                                    </h3>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Amount :{" "}
                                                      <FontAwesomeIcon
                                                        icon={faEuroSign}
                                                        style={{ fontSize: 16 }}
                                                        color="#000"
                                                      ></FontAwesomeIcon>{" "}
                                                      {AmountWithUpliftOld}
                                                    </h3>
                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Status: {StatusMsg}
                                                    </h3>

                                                    <div
                                                      class="chkContBooking"
                                                      style={{ marginTop: 12 }}
                                                    >
                                                      <div class="chkCont__col">
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK IN
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              StartDate
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText 444">
                                                            {moment(
                                                              StartDate
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                        <div class="chkCont__night">
                                                          <span>
                                                            {numberOfNights}{" "}
                                                            Nights
                                                          </span>
                                                        </div>
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK OUT
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              EndDate
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText">
                                                            {moment(
                                                              EndDate
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      } else {
                                        const DataArr =
                                          item.ReadBookingResponse.BookingRS
                                            .Reservations.Reservation.Items
                                            .HotelItem;
                                        const HotelCategoryNew =
                                          DataArr.HotelInfo.HotelCategory;

                                        let PriceAmount = parseFloat(
                                          DataArr.Prices.Price.TotalFixAmounts
                                            .Service.attributes.Amount
                                        );
                                        let price_uplift = parseFloat(
                                          this.state.upcomingData.ejuniper
                                            .uplift[index]
                                        );
                                        let UpliftAmount = parseFloat(
                                          (price_uplift / 100) * PriceAmount
                                        );
                                        let AmountWithUpliftOld = parseFloat(
                                          PriceAmount + UpliftAmount
                                        ); // Amount

                                        const HotelCategory = HotelCategoryNew.charAt(
                                          0
                                        );
                                        let date1 = new Date(
                                          DataArr.attributes.Start
                                        );
                                        let date2 = new Date(
                                          DataArr.attributes.End
                                        );

                                        let timeDiff = Math.abs(
                                          date1.getTime() - date2.getTime()
                                        );
                                        let numberOfNights = Math.ceil(
                                          timeDiff / (1000 * 3600 * 24)
                                        );

                                        const Status =
                                          item.ReadBookingResponse.BookingRS
                                            .Reservations.Reservation.attributes
                                            .Status;
                                        const Locator =
                                          item.ReadBookingResponse.BookingRS
                                            .Reservations.Reservation.attributes
                                            .Locator;
                                        const CancellationPolicy =
                                          DataArr.CancellationPolicy.PolicyRules
                                            .Rule;

                                        let StatusMsg = "";
                                        if (Status == "PAG") {
                                          StatusMsg =
                                            "Booking confirmed and paid";
                                        } else if (Status == "CON") {
                                          StatusMsg = "Booking confirmed";
                                        } else if (Status == "CAN") {
                                          StatusMsg = "Booking cancelled";
                                        } else if (Status == "CAC") {
                                          StatusMsg = "Booking cancelled";
                                        } else if (Status == "PRE") {
                                          StatusMsg = "Booking on request";
                                        } else if (Status == "PDI") {
                                          StatusMsg = "Booking on request";
                                        } else if (Status == "QUO") {
                                          StatusMsg = "Quotation";
                                        }

                                        let JsonStatePDFAcco = {
                                          property_name: DataArr.HotelInfo.Name,
                                          booking_amount: AmountWithUpliftOld,
                                          checkin_date: moment(
                                            DataArr.attributes.Start
                                          ).format("D MMM Y"),
                                          checkout_date: moment(
                                            DataArr.attributes.End
                                          ).format("D MMM Y"),
                                          booking_refrence_id: this.state
                                            .upcomingData.ejuniper.bookingArr[
                                            index
                                          ].booking_refrence_id,
                                          b_date: moment(
                                            this.state.upcomingData.ejuniper
                                              .bookingArr[index].added_on
                                          ).format("D MMM Y"),
                                          numberof_night: numberOfNights,
                                        };
                                        const propertypdf = JSON.stringify(
                                          JsonStatePDFAcco
                                        );
                                        return (
                                          <>
                                            <div className="Container-Tile">
                                              <div className="ContainerFlex">
                                                <div
                                                  className="ContainerImage"
                                                  style={{
                                                    padding:
                                                      "15px 1px 10px 1px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      this.state.upcomingData
                                                        .ejuniper
                                                        .property_image[
                                                        index
                                                      ][0]
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="ContainerContent"
                                                  style={{
                                                    padding:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <div
                                                    className="nameContainer"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="paymakeFlexNew">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <h3
                                                          className="latoBlack font22 blackText"
                                                          style={{
                                                            fontSize: 22, marginBottom: 10
                                                          }}
                                                        >
                                                          {DataArr.HotelInfo.Name.replace(
                                                            "</br>",
                                                            ", "
                                                          )}
                                                        </h3>
                                                        <span
                                                          className="sRating"
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            marginTop: "5px",
                                                            marginLeft: "10px",
                                                          }}
                                                        >
                                                          {this.starsCount(
                                                            HotelCategory
                                                          )}
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <span
                                                          className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                          onClick={() =>
                                                            this.CancelBookingConfirmButton(
                                                              Locator,
                                                              CancellationPolicy
                                                            )
                                                          }
                                                          id="Cancel_Button"
                                                          style={{
                                                            borderRadius: 5,
                                                          }}
                                                        >
                                                          Cancel Booking
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        float: "right",
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <span
                                                        className="font14 latoBold myTripBtn"
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        {
                                                          <PDFDownloadLink
                                                            document={
                                                              <InvoiceAcco
                                                                invoice={
                                                                  propertypdf
                                                                }
                                                              />
                                                            }
                                                            fileName="accommodation.pdf"
                                                            style={{
                                                              fontSize: "9px",
                                                              color: "#000",
                                                              fontWeight: "100",
                                                            }}
                                                          >
                                                            {({
                                                              blob,
                                                              url,
                                                              loading,
                                                              error,
                                                            }) =>
                                                              loading
                                                                ? "Loading document..."
                                                                : "Download Payment Voucher"
                                                            }
                                                          </PDFDownloadLink>
                                                        }
                                                      </span>
                                                    </div>
                                                    <p>
                                                      {
                                                        DataArr.HotelInfo
                                                          .Address
                                                      }
                                                    </p>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        marginTop: 5,
                                                        fontSize: 18,
                                                        marginBottom: 2,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {DataArr.HotelRooms
                                                        .HotelRoom.Name &&
                                                        DataArr.HotelRooms.HotelRoom.Name.replace(
                                                          "</br>",
                                                          ", "
                                                        )}
                                                    </h3>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Amount :{" "}
                                                      <FontAwesomeIcon
                                                        icon={faEuroSign}
                                                        style={{ fontSize: 16 }}
                                                        color="#000"
                                                      ></FontAwesomeIcon>{" "}
                                                      {AmountWithUpliftOld.toFixed(
                                                        2
                                                      )}
                                                    </h3>
                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Status: {StatusMsg}
                                                    </h3>

                                                    <div
                                                      class="chkContBooking"
                                                      style={{ marginTop: 12 }}
                                                    >
                                                      <div class="chkCont__col">
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK IN
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              DataArr.attributes
                                                                .Start
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText 444">
                                                            {moment(
                                                              DataArr.attributes
                                                                .Start
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                        <div class="chkCont__night">
                                                          <span>
                                                            {numberOfNights}{" "}
                                                            Nights
                                                          </span>
                                                        </div>
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK OUT
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              DataArr.attributes
                                                                .End
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText">
                                                            {moment(
                                                              DataArr.attributes
                                                                .End
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </>
                              )}

                              {this.state.upcomingData.hotel_link && (
                                <>
                                  {" "}
                                  {this.state.upcomingData.hotel_link.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.upcomingData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.upcomingData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.upcomingData
                                        .hotel_link.bookingArr[index]
                                        .booking_status;
                                      let hsbooking_id = this.state.upcomingData
                                        .hotel_link.bookingArr[index].id;

                                      let JsonStatePDFAcco = {
                                        property_name:
                                          item.property.property_name,
                                        booking_amount: this.state.upcomingData.hotel_link.bookingArr[
                                          index
                                        ].room_charges.slice(5, 13),
                                        checkin_date: moment(
                                          this.state.upcomingData.hotel_link
                                            .bookingArr[index]
                                            .booking_checked_in
                                        ).format("D MMM Y"),
                                        checkout_date: moment(
                                          this.state.upcomingData.hotel_link
                                            .bookingArr[index]
                                            .booking_checked_out
                                        ).format("D MMM Y"),
                                        booking_refrence_id: this.state
                                          .upcomingData.hotel_link.bookingArr[
                                          index
                                        ].booking_refrence_id,
                                        b_date: moment(
                                          this.state.upcomingData.hotel_link
                                            .bookingArr[index].added_on
                                        ).format("D MMM Y"),
                                        numberof_night: numberOfNights,
                                      };
                                      const propertypdf = JSON.stringify(
                                        JsonStatePDFAcco
                                      );
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.upcomingData
                                                      .hotel_link
                                                      .property_image[index][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{  fontSize: 22, marginBottom: 10 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span
                                                        className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                        id="Cancel_Button"
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                        onClick={() =>
                                                          this.CancelBookingConfirmButtonHl(
                                                            hsbooking_id
                                                          )
                                                        }
                                                      >
                                                        Cancel Booking
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      float: "right",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <span
                                                      className="font14 latoBold myTripBtn"
                                                      style={{
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      {
                                                        <PDFDownloadLink
                                                          document={
                                                            <InvoiceAcco
                                                              invoice={
                                                                propertypdf
                                                              }
                                                            />
                                                          }
                                                          fileName="accommodation.pdf"
                                                          style={{
                                                            fontSize: "9px",
                                                            color: "#000",
                                                            fontWeight: "100",
                                                          }}
                                                        >
                                                          {({
                                                            blob,
                                                            url,
                                                            loading,
                                                            error,
                                                          }) =>
                                                            loading
                                                              ? "Loading document..."
                                                              : "Download Payment Voucher"
                                                          }
                                                        </PDFDownloadLink>
                                                      }
                                                    </span>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.property_rooms.map(
                                                      (i) =>
                                                        ReactHtmlParser(
                                                          i.property_rooms &&
                                                            i.property_rooms.replace(
                                                              "</br>",
                                                              ", "
                                                            )
                                                        ).join(",")
                                                    )}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.upcomingData.hotel_link.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {this.state.upcomingData.hs_portal && (
                                <>
                                  {" "}
                                  {this.state.upcomingData.hs_portal.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.upcomingData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.upcomingData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.upcomingData
                                        .hs_portal.bookingArr[index]
                                        .booking_status;
                                      let hsbooking_id = this.state.upcomingData
                                        .hs_portal.bookingArr[index].id;

                                      let JsonStatePDFAcco = {
                                        property_name:
                                          item.property.property_name,
                                        booking_amount: this.state.upcomingData.hs_portal.bookingArr[
                                          index
                                        ].room_charges.slice(5, 13),
                                        checkin_date: moment(
                                          this.state.upcomingData.hs_portal
                                            .bookingArr[index]
                                            .booking_checked_in
                                        ).format("D MMM Y"),
                                        checkout_date: moment(
                                          this.state.upcomingData.hs_portal
                                            .bookingArr[index]
                                            .booking_checked_out
                                        ).format("D MMM Y"),
                                        booking_refrence_id: this.state
                                          .upcomingData.hs_portal.bookingArr[
                                          index
                                        ].booking_refrence_id,
                                        b_date: moment(
                                          this.state.upcomingData.hs_portal
                                            .bookingArr[index].added_on
                                        ).format("D MMM Y"),
                                        numberof_night: numberOfNights,
                                      };
                                      const propertypdf = JSON.stringify(
                                        JsonStatePDFAcco
                                      );

                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.upcomingData
                                                      .hs_portal.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 22, marginBottom: 10 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span
                                                        className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                        id="Cancel_Button"
                                                        onClick={() =>
                                                          this.CancelBookingConfirmButtonHs(
                                                            hsbooking_id
                                                          )
                                                        }
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        Cancel Booking
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      float: "right",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <span
                                                      className="font14 latoBold myTripBtn"
                                                      style={{
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      {
                                                        <PDFDownloadLink
                                                          document={
                                                            <InvoiceAcco
                                                              invoice={
                                                                propertypdf
                                                              }
                                                            />
                                                          }
                                                          fileName="accommodation.pdf"
                                                          style={{
                                                            fontSize: "9px",
                                                            color: "#000",
                                                            fontWeight: "100",
                                                          }}
                                                        >
                                                          {({
                                                            blob,
                                                            url,
                                                            loading,
                                                            error,
                                                          }) =>
                                                            loading
                                                              ? "Loading document..."
                                                              : "Download Payment Voucher"
                                                          }
                                                        </PDFDownloadLink>
                                                      }
                                                    </span>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.property_rooms
                                                      .map(
                                                        (i) =>
                                                          i.property_rooms &&
                                                          i.property_rooms.replace(
                                                            "</br>",
                                                            ", "
                                                          )
                                                      )
                                                      .join(",")}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.upcomingData.hs_portal.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {this.state.upcomingTourData.expreriance && (
                                <>
                                  {" "}
                                  {this.state.upcomingTourData.expreriance.tourData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.upcomingTourData.expreriance.tourbookingArr[
                                          index
                                        ].tour_booking_date
                                      );
                                      let date2 = new Date(
                                        this.state.upcomingTourData.expreriance.tourbookingArr[
                                          index
                                        ].tour_booking_date
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.upcomingTourData
                                        .expreriance.tourbookingArr[index]
                                        .booking_status;
                                      let hstourbooking_id = this.state
                                        .upcomingTourData.expreriance
                                        .tourbookingArr[index].id;

                                      //Start PDF Data For Experice Listing
                                      let totalNumber = 0;
                                      if (
                                        this.state.upcomingTourData.expreriance
                                          .tourData[index].tour_guest_detail[0]
                                          .price_type == 1
                                      ) {
                                        let number_adult =
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_adult * 1,
                                            0
                                          ) > 0 &&
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_adult * 1,
                                            0
                                          );

                                        let number_child =
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_child * 1,
                                            0
                                          ) > 0 &&
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_child * 1,
                                            0
                                          );

                                        let number_infant =
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_infant * 1,
                                            0
                                          ) > 0 &&
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_infant * 1,
                                            0
                                          );

                                        let number_youth =
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_youth * 1,
                                            0
                                          ) > 0 &&
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_youth * 1,
                                            0
                                          );

                                        let number_senior =
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_senior * 1,
                                            0
                                          ) > 0 &&
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_senior * 1,
                                            0
                                          );
                                        totalNumber =
                                          number_adult +
                                          number_child +
                                          number_infant +
                                          number_youth +
                                          number_senior;
                                      } else {
                                        totalNumber =
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_traveler * 1,
                                            0
                                          ) > 0 &&
                                          this.state.upcomingTourData.expreriance.tourData[
                                            index
                                          ].tour_guest_detail.reduce(
                                            (a, v) => a + v.number_traveler * 1,
                                            0
                                          );
                                      }

                                      let JsonStatePDF = {
                                        tour_name: item.tour.tour_name,
                                        booking_amount: this.state.upcomingTourData.expreriance.tourbookingArr[
                                          index
                                        ].tour_charges.slice(5, 13),
                                        booking_date: moment(
                                          this.state.upcomingTourData
                                            .expreriance.tourbookingArr[index]
                                            .tour_booking_date
                                        ).format("D MMM Y"),
                                        booking_refrence_id: this.state
                                          .upcomingTourData.expreriance
                                          .tourbookingArr[index]
                                          .booking_refrence_id,
                                        b_date: moment(
                                          this.state.upcomingTourData
                                            .expreriance.tourbookingArr[index]
                                            .added_on
                                        ).format("D MMM Y"),
                                        numberof_travelers: totalNumber,
                                      };
                                      const experiencepdf = JSON.stringify(
                                        JsonStatePDF
                                      );
                                      //End PDF Data For Experice Listing

                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.upcomingTourData
                                                      .expreriance.tour_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 22, marginBottom: 10 }}
                                                      >
                                                        {item.tour.tour_name}
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.tour
                                                            .physical_rating
                                                        )}
                                                      </span>
                                                    </div>

                                                    <div>
                                                      <span
                                                        className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                        id="Cancel_Button"
                                                        onClick={() =>
                                                          this.CancelBookingConfirmButtonHsTour(
                                                            hstourbooking_id
                                                          )
                                                        }
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        Cancel Booking
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      float: "right",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <span
                                                      className="font14 latoBold myTripBtn"
                                                      style={{
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      {
                                                        <PDFDownloadLink
                                                          document={
                                                            <Invoice
                                                              invoice={
                                                                experiencepdf
                                                              }
                                                            />
                                                          }
                                                          fileName="experiences.pdf"
                                                          style={{
                                                            fontSize: "9px",
                                                            color: "#000",
                                                            fontWeight: "100",
                                                          }}
                                                        >
                                                          {({
                                                            blob,
                                                            url,
                                                            loading,
                                                            error,
                                                          }) =>
                                                            loading
                                                              ? "Loading document..."
                                                              : "Download Payment Voucher"
                                                          }
                                                        </PDFDownloadLink>
                                                      }
                                                    </span>
                                                  </div>
                                                  <p>
                                                    {item.tour.tour_location}
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {/* {
                                                      item.property_rooms[0]
                                                        .property_rooms
                                                    } */}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.upcomingTourData.expreriance.tourbookingArr[
                                                      index
                                                    ].tour_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  {/* <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .upcomingTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .upcomingTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div> */}

                                                  <div class="chkContBooking">
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          Date
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {" "}
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              this.state
                                                                .upcomingTourData
                                                                .expreriance
                                                                .tourbookingArr[
                                                                index
                                                              ]
                                                                .tour_booking_date
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText 444">
                                                            {moment(
                                                              this.state
                                                                .upcomingTourData
                                                                .expreriance
                                                                .tourbookingArr[
                                                                index
                                                              ]
                                                                .tour_booking_date
                                                            ).format("dddd")}
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span></span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          Time
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {this.state
                                                            .upcomingTourData
                                                            .expreriance
                                                            .tourData[index]
                                                            .tour_guest_detail[0]
                                                            .departure_time ==
                                                          null ? (
                                                            <>
                                                              Coordinated Times
                                                            </>
                                                          ) : (
                                                            <>
                                                              {
                                                                this.state
                                                                  .upcomingTourData
                                                                  .expreriance
                                                                  .tourData[
                                                                  index
                                                                ]
                                                                  .tour_guest_detail[0]
                                                                  .departure_time
                                                              }
                                                            </>
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div class="chkCont__col">
                                                      <p class="font16 blackText">
                                                        {this.state
                                                          .upcomingTourData
                                                          .expreriance.tourData[
                                                          index
                                                        ].tour_guest_detail[0]
                                                          .price_type == 1 ? (
                                                          <>
                                                            {this.state.upcomingTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_adult *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.upcomingTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_adult *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Adults,&nbsp;
                                                              </>
                                                            )}

                                                            {this.state.upcomingTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_child *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.upcomingTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_child *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Children&nbsp;
                                                              </>
                                                            )}

                                                            {this.state.upcomingTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_infant *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.upcomingTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_infant *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Infant,&nbsp;
                                                              </>
                                                            )}

                                                            {this.state.upcomingTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_youth *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.upcomingTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_youth *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Youth,&nbsp;
                                                              </>
                                                            )}

                                                            {this.state.upcomingTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_senior *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.upcomingTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_senior *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Senior,&nbsp;
                                                              </>
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {this.state.upcomingTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_traveler *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.upcomingTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_traveler *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Travelers,&nbsp;
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  {this.state.cancelled == true ? (
                    <div className="myTripLstngLobCrd forCancelled">
                      {this.state.cancelledData == "noCancelled" &&
                      this.state.cancelledTourData == "noCancelled" ? (
                        <div className="emptyErr">
                          <div className="emptyErr--imgCont">
                            <img src={CancelledImage} alt="Upcoming Image" />
                          </div>
                          <div>
                            <p className="emptyErr--heading font22 latoBlack appendBottom15">
                              Looks empty, you've no cancelled bookings.
                            </p>
                            <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                              When you book a trip, you will see your itinerary
                              here.
                            </p>
                            <p>
                              <a href="/">
                                <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                  Plan a trip
                                </button>
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.cancelledData == null ||
                          this.state.cancelledTourData == null ? (
                            <>
                              <div
                                className="Container-Tile"
                                style={{ height: 250 }}
                              >
                                <div className="ContainerFlex">
                                  <div
                                    className="ContainerImage"
                                    style={{ padding: "15px 10px 10px 10px" }}
                                  >
                                    <Skeleton
                                      style={{ width: "100%", height: "165px" }}
                                    />
                                  </div>
                                  <div
                                    className="ContainerContent"
                                    style={{ padding: "10px 10px 10px 40px" }}
                                  >
                                    <div
                                      className="nameContainer"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="paymakeFlex appendBottom5">
                                        <h3 className="latoBlack font22 blackText">
                                          <Skeleton
                                            style={{ width: "200px" }}
                                          />
                                        </h3>
                                        <span
                                          className="sRating"
                                          style={{
                                            display: "inline-flex",
                                            marginTop: "5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Skeleton
                                            style={{ width: "100px" }}
                                          />
                                        </span>
                                      </div>
                                      <p>
                                        <Skeleton style={{ width: "300px" }} />
                                      </p>
                                      <h3
                                        className="latoBlack font22 blackText"
                                        style={{
                                          marginTop: 10,
                                          fontSize: 18,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Skeleton style={{ width: "150px" }} />
                                      </h3>

                                      <div class="chkContBooking">
                                        <div class="chkCont__col">
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText 444">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="chkCont__night">
                                            <span>
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.cancelledData.ejuniper && (
                                <>
                                  {this.state.cancelledData.ejuniper.propertyData.map(
                                    (item, index) => {
                                      const DataArrShow =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.Items
                                          .HotelItem;
                                      if (Array.isArray(DataArrShow)) {
                                        const locatorIndex = item.locatorIndex;
                                        let HotelCategory = "";
                                        let StatusMsg = "";
                                        let numberOfNights = "";
                                        let HotelName = "";
                                        let HotelRoom = "";
                                        let PriceAmount = "";
                                        let price_uplift = "";
                                        let UpliftAmount = "";
                                        let AmountWithUpliftOld = "";
                                        let AddressInfo = "";
                                        let StartDate = "";
                                        let EndDate = "";
                                        DataArrShow.map(
                                          (BookingEjuniferitem, index) => {
                                            if (locatorIndex == index) {
                                              const DataArr = BookingEjuniferitem;

                                              const HotelCategoryNew =
                                                DataArr.HotelInfo.HotelCategory;

                                              HotelName =
                                                DataArr.HotelInfo.Name;
                                              HotelRoom =
                                                DataArr.HotelRooms.HotelRoom
                                                  .Name;

                                              HotelCategory = HotelCategoryNew.charAt(
                                                0
                                              );

                                              let date1 = new Date(
                                                DataArr.attributes.Start
                                              );
                                              let date2 = new Date(
                                                DataArr.attributes.End
                                              );

                                              let timeDiff = Math.abs(
                                                date1.getTime() -
                                                  date2.getTime()
                                              );
                                              numberOfNights = Math.ceil(
                                                timeDiff / (1000 * 3600 * 24)
                                              );
                                              const Status =
                                                item.ReadBookingResponse
                                                  .BookingRS.Reservations
                                                  .Reservation.attributes
                                                  .Status;
                                              const Locator =
                                                item.ReadBookingResponse
                                                  .BookingRS.Reservations
                                                  .Reservation.attributes
                                                  .Locator;
                                              PriceAmount = parseFloat(
                                                DataArr.Prices.Price
                                                  .TotalFixAmounts.Service
                                                  .attributes.Amount
                                              );

                                              price_uplift = parseFloat(
                                                this.state.cancelledData
                                                  .ejuniper.uplift[index]
                                              );
                                              UpliftAmount = parseFloat(
                                                (price_uplift / 100) *
                                                  PriceAmount
                                              );
                                              AmountWithUpliftOld = parseFloat(
                                                PriceAmount + UpliftAmount
                                              ); // Amount

                                              AddressInfo =
                                                DataArr.HotelInfo.Address;
                                              StartDate =
                                                DataArr.attributes.Start;
                                              EndDate = DataArr.attributes.End;

                                              if (Status == "PAG") {
                                                StatusMsg =
                                                  "Booking confirmed and paid";
                                              } else if (Status == "CON") {
                                                StatusMsg = "Booking confirmed";
                                              } else if (Status == "CAN") {
                                                StatusMsg = "Booking cancelled";
                                              } else if (Status == "CAC") {
                                                StatusMsg = "Booking cancelled";
                                              } else if (Status == "PRE") {
                                                StatusMsg =
                                                  "Booking on request";
                                              } else if (Status == "PDI") {
                                                StatusMsg =
                                                  "Booking on request";
                                              } else if (Status == "QUO") {
                                                StatusMsg = "Quotation";
                                              }
                                            }
                                          }
                                        );

                                        return (
                                          <>
                                            <div className="Container-Tile">
                                              <div className="ContainerFlex">
                                                <div
                                                  className="ContainerImage"
                                                  style={{
                                                    padding:
                                                      "15px 1px 10px 1px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      this.state.cancelledData
                                                        .ejuniper
                                                        .property_image[
                                                        index
                                                      ][0]
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="ContainerContent"
                                                  style={{
                                                    padding:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <div
                                                    className="nameContainer"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="paymakeFlexNew">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <h3
                                                          className="latoBlack font22 blackText"
                                                          style={{ fontSize: 22, marginBottom: 10}}
                                                        >
                                                          {HotelName}
                                                        </h3>
                                                        <span
                                                          className="sRating"
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            marginTop: "5px",
                                                            marginLeft: "10px",
                                                          }}
                                                        >
                                                          {this.starsCount(
                                                            HotelCategory
                                                          )}
                                                        </span>
                                                      </div>
                                                      <div></div>
                                                    </div>
                                                    <p
                                                      style={{ marginTop: 15 }}
                                                    >
                                                      {AddressInfo}
                                                    </p>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        marginTop: 5,
                                                        fontSize: 18,
                                                        marginBottom: 2,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {HotelRoom &&
                                                        HotelRoom.replace(
                                                          "</br>",
                                                          ", "
                                                        )}
                                                    </h3>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Amount :{" "}
                                                      <FontAwesomeIcon
                                                        icon={faEuroSign}
                                                        style={{ fontSize: 16 }}
                                                        color="#000"
                                                      ></FontAwesomeIcon>{" "}
                                                      {AmountWithUpliftOld}
                                                    </h3>
                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Status: {StatusMsg}
                                                      { this.state.cancelledData.ejuniper.bookingArr[index].cancel_by==1?(<span className="cancelled-by-admin">Admin</span>):(<></>)}
                                                    </h3>

                                                    <div
                                                      class="chkContBooking"
                                                      style={{ marginTop: 12 }}
                                                    >
                                                      <div class="chkCont__col">
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK IN
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              StartDate
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText 444">
                                                            {moment(
                                                              StartDate
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                        <div class="chkCont__night">
                                                          <span>
                                                            {numberOfNights}{" "}
                                                            Nights
                                                          </span>
                                                        </div>
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK OUT
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              EndDate
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText">
                                                            {moment(
                                                              EndDate
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      } else {
                                        // const DataArr = BookingEjuniferitem;
                                        let DataArr =
                                          item.ReadBookingResponse.BookingRS
                                            .Reservations.Reservation.Items
                                            .HotelItem;
                                        const HotelCategoryNew =
                                          DataArr.HotelInfo.HotelCategory;

                                        const HotelCategory = HotelCategoryNew.charAt(
                                          0
                                        );
                                        let PriceAmount = parseFloat(
                                          DataArr.Prices.Price.TotalFixAmounts
                                            .Service.attributes.Amount
                                        );

                                        let price_uplift = parseFloat(
                                          this.state.cancelledData.ejuniper
                                            .uplift[index]
                                        );
                                        let UpliftAmount = parseFloat(
                                          (price_uplift / 100) * PriceAmount
                                        );
                                        let AmountWithUpliftOld = parseFloat(
                                          PriceAmount + UpliftAmount
                                        ); // Amount
                                        let date1 = new Date(
                                          DataArr.attributes.Start
                                        );
                                        let date2 = new Date(
                                          DataArr.attributes.End
                                        );

                                        let timeDiff = Math.abs(
                                          date1.getTime() - date2.getTime()
                                        );
                                        let numberOfNights = Math.ceil(
                                          timeDiff / (1000 * 3600 * 24)
                                        );
                                        const Status =
                                          item.ReadBookingResponse.BookingRS
                                            .Reservations.Reservation.attributes
                                            .Status;
                                        let StatusMsg = "";
                                        if (Status == "PAG") {
                                          StatusMsg =
                                            "Booking confirmed and paid";
                                        } else if (Status == "CON") {
                                          StatusMsg = "Booking confirmed";
                                        } else if (Status == "CAN") {
                                          StatusMsg = "Booking cancelled";
                                        } else if (Status == "CAC") {
                                          StatusMsg = "Booking cancelled";
                                        } else if (Status == "PRE") {
                                          StatusMsg = "Booking on request";
                                        } else if (Status == "PDI") {
                                          StatusMsg = "Booking on request";
                                        } else if (Status == "QUO") {
                                          StatusMsg = "Quotation";
                                        }
                                        const HotelRoom =
                                          DataArr.HotelRooms.HotelRoom.Name;
                                        return (
                                          <>
                                            <div className="Container-Tile">
                                              <div className="ContainerFlex">
                                                <div
                                                  className="ContainerImage"
                                                  style={{
                                                    padding:
                                                      "15px 1px 10px 1px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      this.state.cancelledData
                                                        .ejuniper
                                                        .property_image[
                                                        index
                                                      ][0]
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="ContainerContent"
                                                  style={{
                                                    padding:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <div
                                                    className="nameContainer"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="paymakeFlexNew">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <h3
                                                          className="latoBlack font22 blackText"
                                                          style={{ fontSize: 22, marginBottom: 10}}
                                                        >
                                                          {
                                                            DataArr.HotelInfo
                                                              .Name
                                                          }
                                                        </h3>
                                                        <span
                                                          className="sRating"
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            marginTop: "5px",
                                                            marginLeft: "10px",
                                                          }}
                                                        >
                                                          {this.starsCount(
                                                            HotelCategory
                                                          )}
                                                        </span>
                                                      </div>
                                                      <div></div>
                                                    </div>
                                                    <p>
                                                      {
                                                        DataArr.HotelInfo
                                                          .Address
                                                      }
                                                    </p>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        marginTop: 5,
                                                        fontSize: 18,
                                                        marginBottom: 2,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {DataArr.HotelRooms
                                                        .HotelRoom.Name &&
                                                        DataArr.HotelRooms.HotelRoom.Name.replace(
                                                          "</br>",
                                                          ", "
                                                        )}
                                                    </h3>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Amount :{" "}
                                                      <FontAwesomeIcon
                                                        icon={faEuroSign}
                                                        style={{ fontSize: 16 }}
                                                        color="#000"
                                                      ></FontAwesomeIcon>{" "}
                                                      {AmountWithUpliftOld.toFixed(
                                                        2
                                                      )}
                                                    </h3>
                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Status: {StatusMsg}
                                                      {this.state.cancelledData.ejuniper.bookingArr[index].cancel_by==1?(<span className="cancelled-by-admin">Admin</span>):(<></>)}
                                                    </h3>

                                                    <div
                                                      class="chkContBooking"
                                                      style={{ marginTop: 12 }}
                                                    >
                                                      <div class="chkCont__col">
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK IN
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              DataArr.attributes
                                                                .Start
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText 444">
                                                            {moment(
                                                              DataArr.attributes
                                                                .Start
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                        <div class="chkCont__night">
                                                          <span>
                                                            {numberOfNights}{" "}
                                                            Nights
                                                          </span>
                                                        </div>
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK OUT
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              DataArr.attributes
                                                                .End
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText">
                                                            {moment(
                                                              DataArr.attributes
                                                                .End
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </>
                              )}

                              {this.state.cancelledData.hotel_link && (
                                <>
                                  {this.state.cancelledData.hotel_link.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.cancelledData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.cancelledData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.cancelledData
                                        .hotel_link.bookingArr[index]
                                        .booking_status;
                                        const cancel_byNew = this.state.cancelledData
                                        .hotel_link.bookingArr[index].cancel_by;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 3) {
                                        StatusMsg = "Booking Cancelled";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.cancelledData
                                                      .hotel_link
                                                      .property_image[index][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 22, marginBottom: 10 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.property_rooms
                                                      .map(
                                                        (i) =>
                                                          i.property_rooms &&
                                                          i.property_rooms.replace(
                                                            "</br>",
                                                            ", "
                                                          )
                                                      )
                                                      .join(",")}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.cancelledData.hotel_link.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                    {cancel_byNew==1?(<span className="cancelled-by-admin">Admin</span>):(<></>)}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}{" "}
                                </>
                              )}

                              {this.state.cancelledData.hs_portal && (
                                <>
                                  {this.state.cancelledData.hs_portal.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.cancelledData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.cancelledData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.cancelledData
                                        .hs_portal.bookingArr[index]
                                        .booking_status;
                                        const cancel_byNew = this.state.cancelledData
                                        .hs_portal.bookingArr[index].cancel_by;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 3) {
                                        StatusMsg = "Booking Cancelled";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.cancelledData
                                                      .hs_portal.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 22, marginBottom: 10 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.property_rooms
                                                      .map(
                                                        (i) =>
                                                          i.property_rooms &&
                                                          i.property_rooms.replace(
                                                            "</br>",
                                                            ", "
                                                          )
                                                      )
                                                      .join(",")}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.cancelledData.hs_portal.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                    {cancel_byNew==1?(<span className="cancelled-by-admin">Admin</span>):(<></>)}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {this.state.cancelledTourData.expreriance && (
                                <>
                                  {" "}
                                  {this.state.cancelledTourData.expreriance.tourData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.cancelledTourData.expreriance.tourbookingArr[
                                          index
                                        ].tour_booking_date
                                      );
                                      let date2 = new Date(
                                        this.state.cancelledTourData.expreriance.tourbookingArr[
                                          index
                                        ].tour_booking_date
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state
                                        .cancelledTourData.expreriance
                                        .tourbookingArr[index].booking_status;
                                        const cancel_byNew = this.state
                                        .cancelledTourData.expreriance
                                        .tourbookingArr[index].cancel_by;
                                      let hstourbooking_id = this.state
                                        .cancelledTourData.expreriance
                                        .tourbookingArr[index].id;

                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 3) {
                                        StatusMsg = "Booking Cancelled";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.cancelledTourData
                                                      .expreriance.tour_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 22, marginBottom: 10}}
                                                      >
                                                        {item.tour.tour_name}
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.tour
                                                            .physical_rating
                                                        )}
                                                      </span>
                                                    </div>
                                                    {/* <div>
                                                      <span
                                                        className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                        id="Cancel_Button"
                                                        onClick={() =>
                                                          this.CancelBookingConfirmButtonHsTour(
                                                            hstourbooking_id
                                                          )
                                                        }
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        Cancel Booking
                                                      </span>
                                                    </div> */}
                                                  </div>
                                                  <p>
                                                    {item.tour.tour_location}
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {/* {
                                                      item.property_rooms[0]
                                                        .property_rooms
                                                    } */}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.cancelledTourData.expreriance.tourbookingArr[
                                                      index
                                                    ].tour_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                    {cancel_byNew==1?(<span className="cancelled-by-admin">Admin</span>):(<></>)}
                                                  </h3>

                                                  {/* <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .cancelledTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .cancelledTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div> */}
                                                  <div class="chkContBooking">
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          Date
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {" "}
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              this.state
                                                                .cancelledTourData
                                                                .expreriance
                                                                .tourbookingArr[
                                                                index
                                                              ]
                                                                .tour_booking_date
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText 444">
                                                            {moment(
                                                              this.state
                                                                .cancelledTourData
                                                                .expreriance
                                                                .tourbookingArr[
                                                                index
                                                              ]
                                                                .tour_booking_date
                                                            ).format("dddd")}
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span></span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          Time
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {this.state
                                                            .cancelledTourData
                                                            .expreriance
                                                            .tourData[index]
                                                            .tour_guest_detail[0]
                                                            .departure_time ==
                                                          null ? (
                                                            <>
                                                              Coordinated Times
                                                            </>
                                                          ) : (
                                                            <>
                                                              {
                                                                this.state
                                                                  .cancelledTourData
                                                                  .expreriance
                                                                  .tourData[
                                                                  index
                                                                ]
                                                                  .tour_guest_detail[0]
                                                                  .departure_time
                                                              }
                                                            </>
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div class="chkCont__col">
                                                      <p class="font16 blackText">
                                                        {this.state
                                                          .cancelledTourData
                                                          .expreriance.tourData[
                                                          index
                                                        ].tour_guest_detail[0]
                                                          .price_type == 1 ? (
                                                          <>
                                                            {this.state.cancelledTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_adult *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.cancelledTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_adult *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Adults,&nbsp;
                                                              </>
                                                            )}

                                                            {this.state.cancelledTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_child *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.cancelledTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_child *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Children&nbsp;
                                                              </>
                                                            )}

                                                            {this.state.cancelledTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_infant *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.cancelledTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_infant *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Infant,&nbsp;
                                                              </>
                                                            )}

                                                            {this.state.cancelledTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_youth *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.cancelledTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_youth *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Youth,&nbsp;
                                                              </>
                                                            )}

                                                            {this.state.cancelledTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_senior *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.cancelledTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_senior *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Senior,&nbsp;
                                                              </>
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {this.state.cancelledTourData.expreriance.tourData[
                                                              index
                                                            ].tour_guest_detail.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.number_traveler *
                                                                  1,
                                                              0
                                                            ) > 0 && (
                                                              <>
                                                                <span class="latoBlack">
                                                                  {this.state.cancelledTourData.expreriance.tourData[
                                                                    index
                                                                  ].tour_guest_detail.reduce(
                                                                    (a, v) =>
                                                                      a +
                                                                      v.number_traveler *
                                                                        1,
                                                                    0
                                                                  )}
                                                                </span>
                                                                &nbsp;Travelers,&nbsp;
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <> </>
                  )}

                  {this.state.Completed == true ? (
                    <div className="myTripLstngLobCrd forCompleted">
                      {this.state.completedData == "noCompleted" &&
                      this.state.completedTourData == "noCompleted" ? (
                        <div className="emptyErr">
                          <div className="emptyErr--imgCont">
                            <img src={Completed} alt="Upcoming Image" />
                          </div>
                          <div>
                            <p className="emptyErr--heading font22 latoBlack appendBottom15">
                              Looks empty, you've no completed bookings.
                            </p>
                            <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                              When you book a trip, you will see your itinerary
                              here.
                            </p>
                            <p>
                              <a href="/">
                                <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                  Plan a trip
                                </button>
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.completedData == null ||
                          this.state.completedTourData == null ? (
                            <>
                              <div
                                className="Container-Tile"
                                style={{ height: 250 }}
                              >
                                <div className="ContainerFlex">
                                  <div
                                    className="ContainerImage"
                                    style={{ padding: "15px 10px 10px 10px" }}
                                  >
                                    <Skeleton
                                      style={{ width: "100%", height: "165px" }}
                                    />
                                  </div>
                                  <div
                                    className="ContainerContent"
                                    style={{ padding: "10px 10px 10px 40px" }}
                                  >
                                    <div
                                      className="nameContainer"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="paymakeFlex appendBottom5">
                                        <h3 className="latoBlack font22 blackText">
                                          <Skeleton
                                            style={{ width: "200px" }}
                                          />
                                        </h3>
                                        <span
                                          className="sRating"
                                          style={{
                                            display: "inline-flex",
                                            marginTop: "5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Skeleton
                                            style={{ width: "100px" }}
                                          />
                                        </span>
                                      </div>
                                      <p>
                                        <Skeleton style={{ width: "300px" }} />
                                      </p>
                                      <h3
                                        className="latoBlack font22 blackText"
                                        style={{
                                          marginTop: 10,
                                          fontSize: 18,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Skeleton style={{ width: "150px" }} />
                                      </h3>

                                      <div class="chkContBooking">
                                        <div class="chkCont__col">
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText 444">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="chkCont__night">
                                            <span>
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.completedData.ejuniper && (
                                <>
                                  {this.state.completedData.ejuniper.propertyData.map(
                                    (item, index) => {
                                      const DataArrShow =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.Items
                                          .HotelItem;
                                      if (Array.isArray(DataArrShow)) {
                                        const locatorIndex = item.locatorIndex;
                                        let HotelCategory = "";
                                        let StatusMsg = "";
                                        let numberOfNights = "";
                                        let HotelName = "";
                                        let HotelRoom = "";
                                        let PriceAmount = "";
                                        let price_uplift = "";
                                        let UpliftAmount = "";
                                        let AmountWithUpliftOld = "";
                                        let AddressInfo = "";
                                        let StartDate = "";
                                        let EndDate = "";
                                        DataArrShow.map(
                                          (BookingEjuniferitem, index) => {
                                            if (locatorIndex == index) {
                                              const DataArr = BookingEjuniferitem;

                                              const HotelCategoryNew =
                                                DataArr.HotelInfo.HotelCategory;

                                              HotelName =
                                                DataArr.HotelInfo.Name;
                                              HotelRoom =
                                                DataArr.HotelRooms.HotelRoom
                                                  .Name;

                                              HotelCategory = HotelCategoryNew.charAt(
                                                0
                                              );

                                              let date1 = new Date(
                                                DataArr.attributes.Start
                                              );
                                              let date2 = new Date(
                                                DataArr.attributes.End
                                              );

                                              let timeDiff = Math.abs(
                                                date1.getTime() -
                                                  date2.getTime()
                                              );
                                              numberOfNights = Math.ceil(
                                                timeDiff / (1000 * 3600 * 24)
                                              );
                                              const Status =
                                                item.ReadBookingResponse
                                                  .BookingRS.Reservations
                                                  .Reservation.attributes
                                                  .Status;
                                              const Locator =
                                                item.ReadBookingResponse
                                                  .BookingRS.Reservations
                                                  .Reservation.attributes
                                                  .Locator;
                                              PriceAmount = parseFloat(
                                                DataArr.Prices.Price
                                                  .TotalFixAmounts.Service
                                                  .attributes.Amount
                                              );
                                              price_uplift = parseFloat(
                                                this.state.completedData
                                                  .ejuniper.uplift[index]
                                              );
                                              UpliftAmount = parseFloat(
                                                (price_uplift / 100) *
                                                  PriceAmount
                                              );
                                              AmountWithUpliftOld = parseFloat(
                                                PriceAmount + UpliftAmount
                                              ); // Amount
                                              AddressInfo =
                                                DataArr.HotelInfo.Address;
                                              StartDate =
                                                DataArr.attributes.Start;
                                              EndDate = DataArr.attributes.End;

                                              if (Status == "PAG") {
                                                StatusMsg =
                                                  "Booking confirmed and paid";
                                              } else if (Status == "CON") {
                                                StatusMsg = "Booking confirmed";
                                              } else if (Status == "CAN") {
                                                StatusMsg = "Booking cancelled";
                                              } else if (Status == "CAC") {
                                                StatusMsg = "Booking cancelled";
                                              } else if (Status == "PRE") {
                                                StatusMsg =
                                                  "Booking on request";
                                              } else if (Status == "PDI") {
                                                StatusMsg =
                                                  "Booking on request";
                                              } else if (Status == "QUO") {
                                                StatusMsg = "Quotation";
                                              }
                                            }
                                          }
                                        );
                                        return (
                                          <>
                                            <div className="Container-Tile">
                                              <div className="ContainerFlex">
                                                <div
                                                  className="ContainerImage"
                                                  style={{
                                                    padding:
                                                      "15px 1px 10px 1px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      this.state.completedData
                                                        .ejuniper
                                                        .property_image[
                                                        index
                                                      ][0]
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="ContainerContent"
                                                  style={{
                                                    padding:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <div
                                                    className="nameContainer"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="paymakeFlexNew">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <h3
                                                          className="latoBlack font22 blackText"
                                                          style={{ fontSize: 22, marginBottom: 10 }}>
                                                          {HotelName}
                                                        </h3>
                                                        <span
                                                          className="sRating"
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            marginTop: "5px",
                                                            marginLeft: "10px",
                                                          }}
                                                        >
                                                          {this.starsCount(
                                                            HotelCategory
                                                          )}
                                                        </span>
                                                      </div>
                                                      <div></div>
                                                    </div>
                                                    <p
                                                      style={{ marginTop: 15 }}
                                                    >
                                                      {AddressInfo}
                                                    </p>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        marginTop: 5,
                                                        fontSize: 18,
                                                        marginBottom: 2,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {HotelRoom &&
                                                        HotelRoom.replace(
                                                          "</br>",
                                                          ", "
                                                        )}
                                                    </h3>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Amount :{" "}
                                                      <FontAwesomeIcon
                                                        icon={faEuroSign}
                                                        style={{ fontSize: 16 }}
                                                        color="#000"
                                                      ></FontAwesomeIcon>{" "}
                                                      {AmountWithUpliftOld}
                                                    </h3>
                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Status: {StatusMsg}
                                                    </h3>

                                                    <div
                                                      class="chkContBooking"
                                                      style={{ marginTop: 12 }}
                                                    >
                                                      <div class="chkCont__col">
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK IN
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              StartDate
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText 444">
                                                            {moment(
                                                              StartDate
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                        <div class="chkCont__night">
                                                          <span>
                                                            {numberOfNights}{" "}
                                                            Nights
                                                          </span>
                                                        </div>
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK OUT
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              EndDate
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText">
                                                            {moment(
                                                              EndDate
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      } else {
                                        const DataArr =
                                          item.ReadBookingResponse.BookingRS
                                            .Reservations.Reservation.Items
                                            .HotelItem;
                                        const HotelCategoryNew =
                                          DataArr.HotelInfo.HotelCategory;

                                        const HotelCategory = HotelCategoryNew.charAt(
                                          0
                                        );
                                        let PriceAmount = parseFloat(
                                          DataArr.Prices.Price.TotalFixAmounts
                                            .Service.attributes.Amount
                                        );

                                        let price_uplift = parseFloat(
                                          this.state.completedData.ejuniper
                                            .uplift[index]
                                        );
                                        let UpliftAmount = parseFloat(
                                          (price_uplift / 100) * PriceAmount
                                        );
                                        let AmountWithUpliftOld = parseFloat(
                                          PriceAmount + UpliftAmount
                                        ); // Amount
                                        let date1 = new Date(
                                          DataArr.attributes.Start
                                        );
                                        let date2 = new Date(
                                          DataArr.attributes.End
                                        );

                                        let timeDiff = Math.abs(
                                          date1.getTime() - date2.getTime()
                                        );
                                        let numberOfNights = Math.ceil(
                                          timeDiff / (1000 * 3600 * 24)
                                        );
                                        const Status =
                                          item.ReadBookingResponse.BookingRS
                                            .Reservations.Reservation.attributes
                                            .Status;
                                        let StatusMsg = "";
                                        if (Status == "PAG") {
                                          StatusMsg =
                                            "Booking confirmed and paid";
                                        } else if (Status == "CON") {
                                          StatusMsg = "Booking confirmed";
                                        } else if (Status == "CAN") {
                                          StatusMsg = "Booking cancelled";
                                        } else if (Status == "CAC") {
                                          StatusMsg = "Booking cancelled";
                                        } else if (Status == "PRE") {
                                          StatusMsg = "Booking on request";
                                        } else if (Status == "PDI") {
                                          StatusMsg = "Booking on request";
                                        } else if (Status == "QUO") {
                                          StatusMsg = "Quotation";
                                        }
                                        return (
                                          <>
                                            <div className="Container-Tile">
                                              <div className="ContainerFlex">
                                                <div
                                                  className="ContainerImage"
                                                  style={{
                                                    padding:
                                                      "15px 1px 10px 1px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      this.state.completedData
                                                        .ejuniper
                                                        .property_image[
                                                        index
                                                      ][0]
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="ContainerContent"
                                                  style={{
                                                    padding:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <div
                                                    className="nameContainer"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="paymakeFlexNew">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <h3
                                                          className="latoBlack font22 blackText"
                                                          style={{
                                                            fontSize: 22, marginBottom: 10
                                                          }}
                                                        >
                                                          {
                                                            DataArr.HotelInfo
                                                              .Name
                                                          }
                                                        </h3>
                                                        <span
                                                          className="sRating"
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            marginTop: "5px",
                                                            marginLeft: "10px",
                                                          }}
                                                        >
                                                          {this.starsCount(
                                                            HotelCategory
                                                          )}
                                                        </span>
                                                      </div>
                                                      <div></div>
                                                    </div>
                                                    <p>
                                                      {
                                                        DataArr.HotelInfo
                                                          .Address
                                                      }
                                                    </p>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        marginTop: 5,
                                                        fontSize: 18,
                                                        marginBottom: 2,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {DataArr.HotelRooms
                                                        .HotelRoom.Name &&
                                                        DataArr.HotelRooms.HotelRoom.Name.replace(
                                                          "</br>",
                                                          ", "
                                                        )}
                                                    </h3>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Amount :{" "}
                                                      <FontAwesomeIcon
                                                        icon={faEuroSign}
                                                        style={{ fontSize: 16 }}
                                                        color="#000"
                                                      ></FontAwesomeIcon>{" "}
                                                      {AmountWithUpliftOld.toFixed(
                                                        2
                                                      )}
                                                    </h3>
                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Status: {StatusMsg}
                                                    </h3>

                                                    <div
                                                      class="chkContBooking"
                                                      style={{ marginTop: 12 }}
                                                    >
                                                      <div class="chkCont__col">
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK IN
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              DataArr.attributes
                                                                .Start
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText 444">
                                                            {moment(
                                                              DataArr.attributes
                                                                .Start
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                        <div class="chkCont__night">
                                                          <span>
                                                            {numberOfNights}{" "}
                                                            Nights
                                                          </span>
                                                        </div>
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            CHECK OUT
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {moment(
                                                              DataArr.attributes
                                                                .End
                                                            ).format(
                                                              "D MMM Y"
                                                            )}
                                                          </span>
                                                          <span class="font10 grayText">
                                                            {moment(
                                                              DataArr.attributes
                                                                .End
                                                            ).format("dddd")}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </>
                              )}

                              {this.state.completedData.hotel_link && (
                                <>
                                  {this.state.completedData.hotel_link.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.completedData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.completedData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.completedData
                                        .hotel_link.bookingArr[index]
                                        .booking_status;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.completedData
                                                      .hotel_link
                                                      .property_image[index][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 22, marginBottom: 10 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.property_rooms
                                                      .map(
                                                        (i) =>
                                                          i.property_rooms &&
                                                          i.property_rooms.replace(
                                                            "</br>",
                                                            ", "
                                                          )
                                                      )
                                                      .join(",")}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.completedData.hotel_link.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}{" "}
                                </>
                              )}

                              {this.state.completedData.hs_portal && (
                                <>
                                  {this.state.completedData.hs_portal.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.completedData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.completedData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.completedData
                                        .hs_portal.bookingArr[index]
                                        .booking_status;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.completedData
                                                      .hs_portal.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{fontSize: 22, marginBottom: 10}}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.property_rooms
                                                      .map(
                                                        (i) =>
                                                          i.property_rooms &&
                                                          i.property_rooms.replace(
                                                            "</br>",
                                                            ", "
                                                          )
                                                      )
                                                      .join(",")}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.completedData.hs_portal.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {this.state.completedTourData.expreriance && (
                                <>
                                  {" "}
                                  {this.state.completedTourData.expreriance.tourData.map(
                                    (item, index) => {
                                      if (item != "") {
                                        let date1 = new Date(
                                          this.state.completedTourData.expreriance.tourbookingArr[
                                            index
                                          ].tour_booking_date
                                        );
                                        let date2 = new Date(
                                          this.state.completedTourData.expreriance.tourbookingArr[
                                            index
                                          ].tour_booking_date
                                        );

                                        let timeDiff = Math.abs(
                                          date1.getTime() - date2.getTime()
                                        );
                                        let numberOfNights = Math.ceil(
                                          timeDiff / (1000 * 3600 * 24)
                                        );
                                        const Status = this.state
                                          .completedTourData.expreriance
                                          .tourbookingArr[index].booking_status;
                                        let hstourbooking_id = this.state
                                          .completedTourData.expreriance
                                          .tourbookingArr[index].id;

                                        let StatusMsg = "";
                                        if (Status == 1) {
                                          StatusMsg = "Booking confirmed";
                                        }
                                        return (
                                          <>
                                            <div className="Container-Tile">
                                              <div className="ContainerFlex">
                                                <div
                                                  className="ContainerImage"
                                                  style={{
                                                    padding:
                                                      "15px 1px 10px 1px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      this.state
                                                        .completedTourData
                                                        .expreriance.tour_image[
                                                        index
                                                      ][0]
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="ContainerContent"
                                                  style={{
                                                    padding:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <div
                                                    className="nameContainer"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="paymakeFlexNew">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <h3
                                                          className="latoBlack font22 blackText"
                                                          style={{ fontSize: 22, marginBottom: 10}}
                                                        >
                                                          {item.tour.tour_name}
                                                        </h3>
                                                        <span
                                                          className="sRating"
                                                          style={{
                                                            display:
                                                              "inline-flex",
                                                            marginTop: "5px",
                                                            marginLeft: "10px",
                                                            marginRight: 10,
                                                          }}
                                                        >
                                                          {this.starsCount(
                                                            item.tour
                                                              .physical_rating
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <p>
                                                      {item.tour.tour_location}
                                                    </p>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        marginTop: 5,
                                                        fontSize: 18,
                                                        marginBottom: 2,
                                                        fontWeight: "bold",
                                                      }}
                                                    ></h3>

                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Amount :{" "}
                                                      <FontAwesomeIcon
                                                        icon={faEuroSign}
                                                        style={{ fontSize: 16 }}
                                                        color="#000"
                                                      ></FontAwesomeIcon>{" "}
                                                      {this.state.completedTourData.expreriance.tourbookingArr[
                                                        index
                                                      ].tour_charges.slice(
                                                        5,
                                                        13
                                                      )}
                                                    </h3>
                                                    <h3
                                                      className="latoBlack font22 blackText"
                                                      style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Status: {StatusMsg}
                                                    </h3>

                                                    {/* <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .completedTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("D MMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .completedTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div> */}

                                                    <div class="chkContBooking">
                                                      <div class="chkCont__col">
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            Date
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {" "}
                                                            <span class="latoBlack font24 blackText appendBottom3">
                                                              {moment(
                                                                this.state
                                                                  .completedTourData
                                                                  .expreriance
                                                                  .tourbookingArr[
                                                                  index
                                                                ]
                                                                  .tour_booking_date
                                                              ).format(
                                                                "D MMM Y"
                                                              )}
                                                            </span>
                                                            <span class="font10 grayText 444">
                                                              {moment(
                                                                this.state
                                                                  .completedTourData
                                                                  .expreriance
                                                                  .tourbookingArr[
                                                                  index
                                                                ]
                                                                  .tour_booking_date
                                                              ).format("dddd")}
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div class="chkCont__night">
                                                          <span></span>
                                                        </div>
                                                        <div class="paymakeFlex column">
                                                          <span class="font10 grayText appendBottom3">
                                                            Time
                                                          </span>
                                                          <span class="latoBlack font24 blackText appendBottom3">
                                                            {this.state
                                                              .completedTourData
                                                              .expreriance
                                                              .tourData[index]
                                                              .tour_guest_detail[0]
                                                              .departure_time ==
                                                            null ? (
                                                              <>
                                                                Coordinated
                                                                Times
                                                              </>
                                                            ) : (
                                                              <>
                                                                {
                                                                  this.state
                                                                    .completedTourData
                                                                    .expreriance
                                                                    .tourData[
                                                                    index
                                                                  ]
                                                                    .tour_guest_detail[0]
                                                                    .departure_time
                                                                }
                                                              </>
                                                            )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div class="chkCont__col">
                                                        <p class="font16 blackText">
                                                          {this.state
                                                            .completedTourData
                                                            .expreriance
                                                            .tourData[index]
                                                            .tour_guest_detail[0]
                                                            .price_type == 1 ? (
                                                            <>
                                                              {this.state.completedTourData.expreriance.tourData[
                                                                index
                                                              ].tour_guest_detail.reduce(
                                                                (a, v) =>
                                                                  a +
                                                                  v.number_adult *
                                                                    1,
                                                                0
                                                              ) > 0 && (
                                                                <>
                                                                  <span class="latoBlack">
                                                                    {this.state.completedTourData.expreriance.tourData[
                                                                      index
                                                                    ].tour_guest_detail.reduce(
                                                                      (a, v) =>
                                                                        a +
                                                                        v.number_adult *
                                                                          1,
                                                                      0
                                                                    )}
                                                                  </span>
                                                                  &nbsp;Adults,&nbsp;
                                                                </>
                                                              )}

                                                              {this.state.completedTourData.expreriance.tourData[
                                                                index
                                                              ].tour_guest_detail.reduce(
                                                                (a, v) =>
                                                                  a +
                                                                  v.number_child *
                                                                    1,
                                                                0
                                                              ) > 0 && (
                                                                <>
                                                                  <span class="latoBlack">
                                                                    {this.state.completedTourData.expreriance.tourData[
                                                                      index
                                                                    ].tour_guest_detail.reduce(
                                                                      (a, v) =>
                                                                        a +
                                                                        v.number_child *
                                                                          1,
                                                                      0
                                                                    )}
                                                                  </span>
                                                                  &nbsp;Children&nbsp;
                                                                </>
                                                              )}

                                                              {this.state.completedTourData.expreriance.tourData[
                                                                index
                                                              ].tour_guest_detail.reduce(
                                                                (a, v) =>
                                                                  a +
                                                                  v.number_infant *
                                                                    1,
                                                                0
                                                              ) > 0 && (
                                                                <>
                                                                  <span class="latoBlack">
                                                                    {this.state.completedTourData.expreriance.tourData[
                                                                      index
                                                                    ].tour_guest_detail.reduce(
                                                                      (a, v) =>
                                                                        a +
                                                                        v.number_infant *
                                                                          1,
                                                                      0
                                                                    )}
                                                                  </span>
                                                                  &nbsp;Infant,&nbsp;
                                                                </>
                                                              )}

                                                              {this.state.completedTourData.expreriance.tourData[
                                                                index
                                                              ].tour_guest_detail.reduce(
                                                                (a, v) =>
                                                                  a +
                                                                  v.number_youth *
                                                                    1,
                                                                0
                                                              ) > 0 && (
                                                                <>
                                                                  <span class="latoBlack">
                                                                    {this.state.completedTourData.expreriance.tourData[
                                                                      index
                                                                    ].tour_guest_detail.reduce(
                                                                      (a, v) =>
                                                                        a +
                                                                        v.number_youth *
                                                                          1,
                                                                      0
                                                                    )}
                                                                  </span>
                                                                  &nbsp;Youth,&nbsp;
                                                                </>
                                                              )}

                                                              {this.state.completedTourData.expreriance.tourData[
                                                                index
                                                              ].tour_guest_detail.reduce(
                                                                (a, v) =>
                                                                  a +
                                                                  v.number_senior *
                                                                    1,
                                                                0
                                                              ) > 0 && (
                                                                <>
                                                                  <span class="latoBlack">
                                                                    {this.state.completedTourData.expreriance.tourData[
                                                                      index
                                                                    ].tour_guest_detail.reduce(
                                                                      (a, v) =>
                                                                        a +
                                                                        v.number_senior *
                                                                          1,
                                                                      0
                                                                    )}
                                                                  </span>
                                                                  &nbsp;Senior,&nbsp;
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              {this.state.completedTourData.expreriance.tourData[
                                                                index
                                                              ].tour_guest_detail.reduce(
                                                                (a, v) =>
                                                                  a +
                                                                  v.number_traveler *
                                                                    1,
                                                                0
                                                              ) > 0 && (
                                                                <>
                                                                  <span class="latoBlack">
                                                                    {this.state.completedTourData.expreriance.tourData[
                                                                      index
                                                                    ].tour_guest_detail.reduce(
                                                                      (a, v) =>
                                                                        a +
                                                                        v.number_traveler *
                                                                          1,
                                                                      0
                                                                    )}
                                                                  </span>
                                                                  &nbsp;Travelers,&nbsp;
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanTrips;
