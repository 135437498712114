import React, { useState, useEffect } from "react";
import "./RatesAndDetails.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";

import { Base64 } from "js-base64";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import Modal from "react-modal";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import Slider from 'react-slider-modal';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function RatesAndDetails(props) {
  let stripHtml = (html) => {
  

    const response = html;
    // const firstParagraph = response.replace(/(<\/?(?:a|p|img|span)[^>]*>)|<[^>]+>/ig, '$1');
    // const firstParagraph1 = firstParagraph.replace(/\&nbsp;/g, '');

    return ReactHtmlParser(response);
  };

  const [openModal, setopenModal] = useState(false);
  const [roomCount, setRoomCount] = useState();
  const [RoomN, setRoomName] = useState([]);
  const [cats, setCat] = useState([]);
  const [cart, setCart] = useState([]);
  const [ComboCart, setComboCart] = useState([]);
  const [openModalHS, setopenModalHS] = useState(false);
  const [openModalRoom, setopenModalRoom] = useState(false);
  const [StartDate, setStartDate] = useState(null);
  const [Enddate, setEnddate] = useState(null);
  const [OpenDropdown, setOpenDropdown] = useState([]);
  const [OpenDropdownValue, setOpenDropdownValue] = useState([]);
 

 
 

  let openModalDiv = () => {
    setopenModal(true);
  };

  let closeModal = () => {
    setopenModal(false);
  };
  let openModalDivHS = () => {
    setopenModalHS(true);
  };

  let closeModalDivHS = () => {
    setopenModalHS(false);
  };


  let openModalDivHSRoom = () => {
    setopenModalRoom(true);
  };
  let closeModalDivHSRoom = () => {
    setopenModalRoom(false);
  };

  let openModalDivHLRoom = () => {
    setopenModalRoom(true);
  };
  let closeModalDivHLRoom = () => {
    setopenModalRoom(false);
  };
  

  let getDate = (data) => {
   
    let DataNew = "";

    if (data[0].cancellation_penalty_id == 1) {
      const date = new Date(StartDate);
      date.setDate(date.getDate() - data[0].day_before_arrival);
    
      DataNew =
        "Free Cancellation before " + moment(date).format("Do MMMM YYYY");
    } else if (data[0].cancellation_penalty_id == 3) {
      DataNew =
        "Minimum " +
        data[0].cancellation_value +
        "% cancellation charges applicable";
    }

    return DataNew;
  };

  let getDateModal = (data) => {
    
    let DataNew = "";

    if (data[0].cancellation_penalty_id == 1) {
      const date = new Date(StartDate);
      date.setDate(date.getDate() - data[0].day_before_arrival);
      
      DataNew =
        "Free Cancellation (100% refund) if you cancel this booking before " +
        moment(date).format("Do MMMM YYYY");
      //  DataNew ="Free Cancellation (100% refund) if you cancel this booking on or before start of accommodations."; 
    } else if (data[0].cancellation_penalty_id == 3) {
      DataNew =
        "Minimum " +
        data[0].cancellation_value +
        "% cancellation charges applicable";
    }

    return DataNew;
  };

  let getDateModalHS = (data) => {

    let DataNew = "";

    if (data[0].cancellation_penalty_id == 1) {
      const date = new Date(StartDate);
      date.setDate(date.getDate() - data[0].day_before_arrival);
     
      DataNew =
        "Free Cancellation (100% refund) if you cancel this booking before " +
        moment(date).format("Do MMMM YYYY");
      //DataNew ="Free Cancellation (100% refund) if you cancel this booking on or before start of accommodations."; 
    } else if (data[0].cancellation_penalty_id == 3) {
      DataNew =
        "Minimum " +
        data[0].cancellation_value +
        "% cancellation charges applicable";
    }

    return DataNew;
  };

  useEffect(() => {
    const Search = props.querySearching;
    const searching = JSON.parse(Search);
    setStartDate(searching.startDate);
    setEnddate(searching.endDate);
    setCat(searching.guestCombination);

    const roomC = searching.guestCombination.reduce(
      (a, v) => a + v.roomCounter,
      0
    );

    setRoomCount(roomC);
  }, []);

  let getHSCartData = (rate_id, amount, room_id) => {
    const Search = props.querySearching;
    const searching = JSON.parse(Search);

    const roomC = searching.guestCombination.reduce(
      (a, v) => a + v.roomCounter * 1,
      0
    );
    const adultC = searching.guestCombination.reduce(
      (a, v) => a + v.adultCounter * 1,
      0
    );
    const childC = searching.guestCombination.reduce(
      (a, v) => a + v.childCounter * 1,
      0
    );

    let CartData = [
      {
        adultCounter: adultC,
        childCounter: childC,
        roomCounter: roomC,
        id: rate_id,
        amount: amount,
        room_id: room_id
      },
    ];

    return JSON.stringify(CartData);
  };

  const getAmountHotelLink = (Offer, price, id) => {
    let data =
      OpenDropdownValue.filter((e) => e.rate_id === id).length > 0
        ? OpenDropdownValue.filter((e) => e.rate_id === id)[0].price
        : price;
    if (Offer != "no_offer") {
      let result = [];

      if (Offer.combineoffer == "nocombine") {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
        
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
        
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      } else {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      }

      const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

      const balanceAmount = data - (Math.round(Amount * data) / 100).toFixed(2);
      return (
        <>
          <div style={{display:"flex"}}>
          <p
            className="grayText font16 lineThrough appendBottom5"
            id="hlistpg_hotel_cut_price"
          >
            <span>€</span>{parseFloat(data).toFixed(2)}
          </p>
          <p
            className="latoBlack font16 blackText appendBottom5 appendLeft5"
            id="hlistpg_hotel_shown_price"
          >
            <span>€</span>{balanceAmount.toFixed(2)}
          </p>
          </div>
          <p
            class="hrtlCenter appendBottom10 directionRtl"
          >
            <span class="redText font12">
              <span>Save </span>
              <span class="latoBold" id="hlistpg_hotel_saved_price">
                <span>€</span>{((Amount * data) / 100).toFixed(2)}
              </span>
            </span>
            <span class="spriteNew  relative appendLeft5">
              <span
                class="latoBold font11 whiteText discountText"
                id="hlistpg_hotel_saved_price_percentage">
                {Amount}%
              </span>
            </span>
          </p>
        </>
      );
    } else {
      return (
        <p
          className="latoBlack font16 blackText appendBottom5"
          id="hlistpg_hotel_shown_price"
        >
          <span>€</span>{parseFloat(data).toFixed(2)}
        </p>
      );
    }
  };

  const getAmountHotelLinkCart = (Offer, price, id) => {
    let data =
      OpenDropdownValue.filter((e) => e.rate_id === id).length > 0
        ? OpenDropdownValue.filter((e) => e.rate_id === id)[0].price
        : price;
    if (Offer != "no_offer") {
      let result = [];

      if (Offer.combineoffer == "nocombine") {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
         
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
       
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      } else {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      }

      const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

      const balanceAmount = data - (Math.round(Amount * data) / 100);
      return balanceAmount;
    } else {
      return data;
    }
  };

  let bookCombo = (
    RoomName,
    Rateplanid,
    RoomAmount,
    adult,
    child,
    room_id,
    roomAvailability,
    Offer
  ) => {
    let balanceAmount = 0;
    if (Offer != "no_offer") {
      let result = [];
      Offer.map((i, j) => {
       
        if (i.specialdata.spl_offer_type_id == 1) {
          const dis = RoomAmount / parseInt(i.specialdata.pay_stay_value);
          const disA = (dis / RoomAmount) * 100;
          result.push(disA);
        } else {
          if (i.specialdata.discount_in == 2) {
            const disA = i.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = RoomAmount - i.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      });

      const Amount = result.reduce((a, v) => a + v, 0);

      balanceAmount =
        RoomAmount - (Math.round(Amount * RoomAmount) / 100).toFixed(2);
    }

    setCart((cart) => [
      ...cart,
      {
        name: RoomName,
        id: Rateplanid,
        amount: balanceAmount == 0 ? RoomAmount : balanceAmount,
        adult: adult,
        room_id: room_id,
        roomAvail: roomAvailability,
        roomCounter: 1,
        adultCounter: adult,
        childCounter: child
      },
    ]);
  };

  let addRoomtoCart = (
    RoomName,
    Rateplanid,
    RoomAmount,
    adult,
    child,
    room_id,
    roomAvailability
  ) => {
    const NewAdult =
      OpenDropdownValue.filter((e) => e.rate_id === Rateplanid).length > 0
        ? OpenDropdownValue.filter((e) => e.rate_id === Rateplanid)[0].number
        : adult;
    setCart((cart) => [
      ...cart,
      {
        name: RoomName,
        id: Rateplanid,
        amount: RoomAmount,
        adult: NewAdult,
        room_id: room_id,
        roomAvail: roomAvailability,
        roomCounter: 1,
        adultCounter: NewAdult,
        childCounter: child
      },
    ]);

    const RoomC = document.querySelectorAll(".add_roomCount_" + room_id).length;

    let RoomPopCount = RoomC + 1;

    if (RoomPopCount >= roomAvailability) {
      var RoomButton = document.querySelectorAll(".add_Room_" + room_id);
      RoomButton.forEach(function (el) {
       
        el.classList.add("btnDisbaled");
        // el.style.visibility = "hidden";
      });
    }
  };
  let clearCart = (z, i) => {
    let cartNew = [...cart];
    cartNew.splice(z, 1);
    setCart(cartNew);

    const RoomC = document.querySelectorAll(".add_roomCount_" + i.room_id)
      .length;
    var RoomButton = document.querySelectorAll(".add_Room_" + i.room_id);
    RoomButton.forEach(function (el) {
     
      el.classList.remove("btnDisbaled");
      // el.style.visibility = "hidden";
    });
  };

  const getAmountHsPortal = (Offer, price, id) => {
    
    let data =
      OpenDropdownValue.filter((e) => e.rate_id === id).length > 0
        ? OpenDropdownValue.filter((e) => e.rate_id === id)[0].price
        : price;
    if (Offer != "no_offer") {
      let result = [];

      if (Offer.combineoffer == "nocombine") {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
        
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
         
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      } else {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      }

      const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

      const balanceAmount = data - (Math.round(Amount * data) / 100).toFixed(2);
      return (
        <>
         <div style={{display:"flex"}}>
          <p
            className="grayText font16 lineThrough appendBottom5"
            id="hlistpg_hotel_cut_price"
          >
            <span>€</span>{parseFloat(data).toFixed(2)}
          </p>
          <p
            className="latoBlack font16 blackText appendBottom5 appendLeft5"
            id="hlistpg_hotel_shown_price"
          >
            <span>€</span>{balanceAmount.toFixed(2)}
          </p>
          </div>
          <p
            class="hrtlCenter appendBottom10 directionRtl"
          >
            <span class="redText font12">
              <span>Save </span>
              <span class="latoBold" id="hlistpg_hotel_saved_price">
                <span>€</span>{((Amount * data) / 100).toFixed(2)}
              </span>
            </span>
            <span class="spriteNew  relative appendLeft5">
              <span
                class="latoBold font11 whiteText discountText"
                id="hlistpg_hotel_saved_price_percentage">
                {Amount}%
              </span>
            </span>
          </p>
        </>
      );
    } else {
      return (
        <p
          className="latoBlack font16 blackText appendBottom5"
          id="hlistpg_hotel_shown_price"
        >
          <span>€</span>{parseFloat(data).toFixed(2)}
        </p>
      );
    }
  };

  //Multiple Booking Adding Cart

  const getAmountHsPortalCart = (Offer, price, id) => {
    let data =
      OpenDropdownValue.filter((e) => e.rate_id === id).length > 0
        ? OpenDropdownValue.filter((e) => e.rate_id === id)[0].price
        : price;
    if (Offer != "no_offer") {
      let result = [];

      Offer.length > 0 && Offer.map((i, j) => {
       
        if (i.combineoffer == "nocombine") {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data * parseInt(i.specialdata.pay_stay_length) -
              data * parseInt(i.specialdata.pay_stay_value);
          
            const disA =
              (dis / (data * parseInt(i.specialdata.pay_stay_length))) * 100;
           
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        } else {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data * parseInt(i.specialdata.pay_stay_length) -
              data * parseInt(i.specialdata.pay_stay_value);
            const disA =
              (dis / (data * parseInt(i.specialdata.pay_stay_length))) * 100;
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        }
      });

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount = data - (Math.round(Amount * data) / 100);

      return balanceAmount;
    } else {
      return data;
    }
  };

  const getAmountHsPortalTotalDis = (Offer, data) => {
    if (Offer != "no_offer") {
      let result = [];

      Offer.map((i, j) => {
      
        if (i.combineoffer == "nocombine") {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data.amount * parseInt(i.specialdata.pay_stay_length) -
              data.amount * parseInt(i.specialdata.pay_stay_value);
           
            const disA =
              (dis / (data.amount * parseInt(i.specialdata.pay_stay_length))) *
              100;
           
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data.amount - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        } else {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data.amount * parseInt(i.specialdata.pay_stay_length) -
              data.amount * parseInt(i.specialdata.pay_stay_value);
            const disA =
              (dis / (data.amount * parseInt(i.specialdata.pay_stay_length))) *
              100;
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data.amount - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        }
      });

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount = (Math.round(Amount * data) / 100).toFixed(2);

      return <>€{balanceAmount * roomCount}</>;
    } else {
      return <>0</>;
    }
  };

  const showSpecialOffer = (data) => {
    if (data.specialOffer != "no_offer") {
      if (data.specialOffer.combineoffer == "nocombine") {
        return data.specialOffer.name;
      } else {
        data.specialOffer.combineoffer
          .map((i, j) => {
            return data.specialOffer.combineoffer[j].offer_name;
          })
          .join(",");
      }
    }
  };
  
  const showSpecialOfferAndRate = (data) => {
    if (data.specialOffer != "no_offer") {
      if (data.specialOffer.combineoffer == "nocombine") {
        
        return [
          {'offerName': data.specialOffer.name,
          'rateDiscount':data.specialOffer.ratediscount,
          'OfferId': data.specialOffer.OfferID
        }];
      } else {
        data.specialOffer.combineoffer
          .map((i, j) => {
            return [
              {'offerName': data.specialOffer.combineoffer[j].offer_name,
              'rateDiscount':data.specialOffer.combineoffer[j].ratediscount,
              'OfferId': data.specialOffer.combineoffer[j].OfferID
            }];
          })
          .join(",");
      }
    }
  };

  const showSpecialOfferDis = (data) => {
    if (data.specialOffer != "no_offer") {
      if (data.specialOffer.combineoffer == "nocombine") {
        return data.specialOffer.discription;
      } else {
        data.specialOffer.combineoffer
          .map((i, j) => {
            return data.specialOffer.combineoffer[j].description;
          })
          .join(",");
      }
    }
  };
  const getAmountHsPortalTotalAmount = (Offer, data) => {
    if (Offer != "no_offer") {
      let result = [];

      if (Offer.combineoffer == "nocombine") {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
         
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
         
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      } else {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      }

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount = data - (Math.round(Amount * data) / 100).toFixed(2);

      return <>€{(balanceAmount * roomCount).toFixed(2)}</>;
    } else {
      return <>€{data * roomCount}</>;
    }
  };

  const getAmountHsPortalTotalAmountCart = (Offer, data) => {
    if (Offer != "no_offer") {
      let result = [];

      if (Offer.combineoffer == "nocombine") {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
        
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
        
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      } else {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      }

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount = data - (Math.round(Amount * data) / 100);

      return (balanceAmount * roomCount);
    } else {
      return data * roomCount;
    }
  };

  const getAmountHotellinkTotalDis = (Offer, data) => {
    if (Offer != "no_offer") {
      let result = [];

      if (Offer.combineoffer == "nocombine") {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data.amount * parseInt(Offer.specialdata.pay_stay_length) -
            data.amount * parseInt(Offer.specialdata.pay_stay_value);
        
          const disA =
            (dis /
              (data.amount * parseInt(Offer.specialdata.pay_stay_length))) *
            100;
         
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data.amount - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      } else {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data.amount * parseInt(Offer.specialdata.pay_stay_length) -
            data.amount * parseInt(Offer.specialdata.pay_stay_value);
          const disA =
            (dis /
              (data.amount * parseInt(Offer.specialdata.pay_stay_length))) *
            100;
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data.amount - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      }

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount = (Math.round(Amount * data) / 100).toFixed(2);

      return <><span>€</span>{balanceAmount * roomCount}</>;
    } else {
      return <>0</>;
    }
  };

  const getAmountHotellinkTotalAmount = (Offer, data) => {
    if (Offer != "no_offer") {
      let result = [];

      if (Offer.combineoffer == "nocombine") {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
        
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
         
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      } else {
        if (Offer.specialdata.spl_offer_type_id == 1) {
          const dis =
            data * parseInt(Offer.specialdata.pay_stay_length) -
            data * parseInt(Offer.specialdata.pay_stay_value);
          const disA =
            (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
          result.push(disA);
        } else {
          if (Offer.specialdata.discount_in == 2) {
            const disA = Offer.specialdata.rate_discount;
            result.push(+disA);
          } else {
            const disA = data - Offer.specialdata.rate_discount;
            result.push(+disA);
          }
        }
      }

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount = data - (Math.round(Amount * data) / 100).toFixed(2);

      return <><span>€</span>{(balanceAmount * roomCount).toFixed(2)}</>;
    } else {
      return <><span>€</span>{data * roomCount}</>;
    }
  };

  // const getAmountHotellinkTotalAmountCart = (Offer, data) => {
  //   if (Offer != "no_offer") {
  //     let result = [];

  //     if (Offer.combineoffer == "nocombine") {
  //       if (Offer.specialdata.spl_offer_type_id == 1) {
  //         const dis =
  //           data * parseInt(Offer.specialdata.pay_stay_length) -
  //           data * parseInt(Offer.specialdata.pay_stay_value);
        
  //         const disA =
  //           (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
        
  //         result.push(disA);
  //       } else {
  //         if (Offer.specialdata.discount_in == 2) {
  //           const disA = Offer.specialdata.rate_discount;
  //           result.push(+disA);
  //         } else {
  //           const disA = data - Offer.specialdata.rate_discount;
  //           result.push(+disA);
  //         }
  //       }
  //     } else {
  //       if (Offer.specialdata.spl_offer_type_id == 1) {
  //         const dis =
  //           data * parseInt(Offer.specialdata.pay_stay_length) -
  //           data * parseInt(Offer.specialdata.pay_stay_value);
  //         const disA =
  //           (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) * 100;
  //         result.push(disA);
  //       } else {
  //         if (Offer.specialdata.discount_in == 2) {
  //           const disA = Offer.specialdata.rate_discount;
  //           result.push(+disA);
  //         } else {
  //           const disA = data - Offer.specialdata.rate_discount;
  //           result.push(+disA);
  //         }
  //       }
  //     }

  //     const Amount = result.reduce((a, v) => a + v, 0);

  //     const balanceAmount = data - (Math.round(Amount * data) / 100);

  //     return (balanceAmount * roomCount);
  //   } else {
  //     return data * roomCount;
  //   }
  // };

  let CancellationModal = (id, data) => {
    let DataNew = "";
    let Value = "";
    let EuroIcon = "";
    let PerIcon = "";

    return (
      <>
        {" "}
        {data.map((i, index) => {
          const date = new Date(StartDate);
          date.setDate(date.getDate() - i.day_before_arrival);

          DataNew = moment(date).format("Do MMMM YYYY");
          // if(i.day_before_arrival == 1 || i.day_before_arrival== 2){
          //   date.setDate(date.getDate() - i.day_before_arrival);
          //   DataNew = moment(date).format("Do MMMM YYYY");
          //  }{

          //   date.setDate(date.getDate() - (i.day_before_arrival - 2));
          //    DataNew = moment(date).format("Do MMMM YYYY");
          //   DataNew = "If cancelled or modified " + parseInt(i.day_before_arrival - 2) + " days before date of acccommodations.";
          //   DataNew = "If cancelled or modified " + moment(date).format("Do MMMM YYYY") + " days before date of acccommodations.";
          // }
          if (i.penalty == "No Penalty") {
            // Value = 0;
            Value = 0;
            EuroIcon = '';
            PerIcon = '';
          } else if (i.penalty == "Full Amount") {
            // Value = 100;
            Value = '';
            EuroIcon = '';
            PerIcon = 'Full booking amount will be charged.';
          } else if (i.penalty == "Percentage") {
            // Value = i.cancellation_value;
            Value = i.cancellation_value;
            EuroIcon = '';
            PerIcon = '% of booking amount will be charged.';
          } else if (i.penalty == "Nights") {
            //Value = i.cancellation_value;
            Value = i.cancellation_value;
            EuroIcon = <span>€</span>;
            PerIcon = ' amount will be charged.';
          } else if (i.penalty == "Fixed Amount") {
            Value = i.cancellation_value;
            EuroIcon = <span>€</span>;
            PerIcon = ' amount will be charged.';
          }

          if (i.day_before_arrival == -1) {
            DataNew = "No Show";
          }

          if (id == i.rate_plan_id) {
            return (
              <div className="cncpolicyTbl__row">
                <div className="cncpolicyTbl__col">
                  {index == 0 ? "Before" : "After"} &#x202A;{DataNew}&#x202C;
                </div>
                <div className="cncpolicyTbl__col">
                  {/* Charge {Value}% of booking amount will be charged */}
                  {EuroIcon} {Value}{PerIcon}
                </div>
              </div>
            );
          }
        })}
      </>
    );
  };

  let CancellationModalHS = (id, data) => {
    let DataNew = "";
    let Value = "";
    let EuroIcon = "";
    let PerIcon = "";
    return (
      <>
        {" "}
        {data.map((i, index) => {
          const date = new Date(StartDate);
          date.setDate(date.getDate() - i.day_before_arrival);

          DataNew = moment(date).format("Do MMMM YYYY");
          // date.setDate(date.getDate() - i.day_before_arrival);
          //  if(i.day_before_arrival == 1 || i.day_before_arrival== 2){
          //   date.setDate(date.getDate() - i.day_before_arrival);
          //   DataNew = moment(date).format("Do MMMM YYYY");
          //  }{

          //   date.setDate(date.getDate() - (i.day_before_arrival - 2));
          //   // DataNew = moment(date).format("Do MMMM YYYY");
          //   DataNew = "If cancelled or modified " + parseInt(i.day_before_arrival - 2) + " days before date of acccommodations.";
          // }

          // DataNew = moment(date).format("Do MMMM YYYY");
          // if (i.penalty == "No Penalty") {
          //   Value = 0;
          // } else if (i.penalty == "Full Amount") {
          //   Value = 100;
          // } else if (i.penalty == "Percentage") {
          //   Value = i.cancellation_value;
          // } else if (i.penalty == "Nights") {
          //   Value = i.cancellation_value;
          // } else if (i.penalty == "Fixed Amount") {
          //   Value = i.cancellation_value;
          // }

          // if (i.day_before_arrival == 1) {
          //   DataNew = "No Show";
          // }
          if (i.penalty == "No Penalty") {
            Value = 0;
            EuroIcon = '';
            PerIcon = '';
          } else if (i.penalty == "Full Amount") {
            Value = '';
            EuroIcon = '';
            PerIcon = 'Full booking amount will be charged.';
          } else if (i.penalty == "Percentage") {
            Value = i.cancellation_value;
            EuroIcon = '';
            PerIcon = '% of booking amount will be charged.';
          } else if (i.penalty == "Nights") {
            Value = i.cancellation_value;
            EuroIcon = <span>€</span>;
            PerIcon = ' amount will be charged.';
          }
          else if (i.penalty == "Fixed Amount") {
            Value = i.cancellation_value;
            EuroIcon = <span>€</span>;
            PerIcon = ' amount will be charged.';
          }

          if (i.day_before_arrival == 1) {
            DataNew = "In case of no show";
          }

          if (id == i.rate_plan_id) {
            return (
              <div className="cncpolicyTbl__row">
                <div className="cncpolicyTbl__col">
                  {index == 0 ? "Before" : "After"} &#x202A;{DataNew}&#x202C;
                </div>
                <div className="cncpolicyTbl__col">
                  {/* Charge {Value}% of booking amount will be charged */}
                  {EuroIcon} {Value}{PerIcon}
                </div>
              </div>
            );
          } else {
            return (
              <div className="cncpolicyTbl__row">
                <div className="cncpolicyTbl__col">
                  {index == 0 ? "Before" : "After"} &#x202A;{DataNew}&#x202C;
                </div>
                <div className="cncpolicyTbl__col">
                  {/* Charge {Value}% of booking amount will be charged */}
                  {EuroIcon} {Value}{PerIcon}
                </div>
              </div>
            );
          }
        })}
      </>
    );
  };

  let setOption = (index) => {
    let result = [];
    let options = [...OpenDropdown];
    if (OpenDropdown.includes(index)) {
      let indexOf = OpenDropdown.indexOf(index);
      options.splice(indexOf, 1);
      setOpenDropdown(options);
    } else {
      result.push(index);
      setOpenDropdown(result);
    }
  };

  let ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };

  let PriceChanger = (num, price, id) => {
    setOption(id);

    let options = [...OpenDropdownValue];
    if (OpenDropdownValue.filter((e) => e.rate_id === id).length > 0) {
      let indexOf = OpenDropdownValue.findIndex((e) => e.rate_id === id);
      options[indexOf]["number"] = num;
      options[indexOf]["price"] = price;
      setOpenDropdownValue(options);
    } else {
      setOpenDropdownValue([
        ...OpenDropdownValue,
        { rate_id: id, number: num, price: price },
      ]);
    }
  };

  return (
    <section id="RoomType" className="page-section bottom35">
      <div className="_RoomType">
        {/* {roomCount > 1 ? (
          <>
            {props.hs_portal != "no_hs_portal" ? (
              <>
                {" "}
                {Object.keys(props.hs_portal).map((keyfirst, index) => {
                  const item = props.hs_portal[keyfirst];
                  return (
                    <>
                      {" "}
                      {item.roomOptions.map((dat, index) => {
                        if (props.eJuniper != "no_ejuniper") {
                          return <></>;
                        } else {
                          let checkAdult = false;
                          if (cats.length > 0) {
                            for (var i = 0; i < cats.length; i++) {
                              if (
                                cats[i].adultCounter * 1 +
                                cats[i].childCounter * 1 <=
                                dat.max_guests
                              ) {
                                checkAdult = true;
                              } else {
                                checkAdult = false;
                              }
                            }
                          }

                          if (checkAdult == true) {
                            return (
                              <div className="appendTop20">
                                <div className="hsmakeFlex appendBottom40 comboWrap">
                                  <div className="left">
                                    <div className="hsmakeFlex bdrBottom padding20 spaceBetween hrtlCenter makeRelative">
                                      <div className="latoBold font20  comboTitle">
                                        Recommended for{" "}
                                        {cats.reduce(
                                          (a, v) =>
                                            a +
                                            v.adultCounter * 1 +
                                            v.childCounter * 1,
                                          0
                                        )}{" "}
                                        Adults
                                      </div>
                                    </div>

                                    {cats.map((i, z) =>
                                      props.hs_portal != "no_hs_portal" ? (
                                        <>
                                          <div className="hsmakeFlex latoBold roomRow">
                                            <div className="hsmakeFlex column width33">
                                              <span className="appendBottom5 roomTag whiteText">
                                                {" "}
                                                Room {z + 1}
                                              </span>
                                              <p className="font16 blueText appendBottom5">
                                                {keyfirst}
                                              </p>
                                              <p>
                                                {i.adultCounter * 1} Adults{" "}
                                                {i.childCounter > 0
                                                  ? i.childCounter == 1
                                                    ? "+ " +
                                                    i.childCounter +
                                                    " Child"
                                                    : "+ " +
                                                    i.childCounter +
                                                    " Children"
                                                  : ""}{" "}
                                              </p>
                                            </div>
                                            <div className="hsmakeFlex column width33">
                                              <p className="font16 blackText">
                                                {" "}
                                                Type
                                              </p>
                                              <ul className="types">
                                                <li>
                                                  Room With Free Cancellation
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="hsmakeFlex column width33">
                                              <p className="font16 blackText">
                                                {" "}
                                                Inclusions
                                              </p>
                                              <ul className="types"></ul>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    )}
                                  </div>
                                  <div className="priceBreakUp">
                                    <p className="bdrBottom font20 latoBold appendBottom20 breackUpHeading">
                                      Details
                                    </p>
                                    <div
                                      className="hsmakeFlex spaceBetween paddingLR20 font18 blackText latoBold"
                                      style={{ marginTop: 10 }}
                                    >
                                      <span>Price</span>
                                      <span id="detpg_combo_price">
                                        {getAmountHsPortalTotalAmount(
                                          dat.specialOffer,
                                          dat.AmountWithUplift
                                        )}
                                      </span>
                                    </div>
                                   
                                    <p className="font11 grayText textRight appendTop5 paddingLR20">
                                      (Per night for {roomCount} Room)
                                    </p>
                                    <a
                                      rel="nofollow"
                                      href="javascript:;"
                                      onClick={() =>
                                        bookCombo(
                                          keyfirst,
                                          dat.id,
                                          dat.AmountWithUplift,
                                          dat.adult,
                                          dat.child,
                                          item.room.id,
                                          item.roomAvailability,
                                          dat.specialOffer
                                        )
                                      }
                                      
                                    >
                                      <span className="primaryBtn overlapBtn">
                                        BOOK THIS COMBO
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        }
                      })}{" "}
                    </>
                  );
                })}
              </>
            ) : (
              <> </>
            )}

            {props.hotel_link != "no_hotel_link" ? (
              <>
                {" "}
                {Object.keys(props.hotel_link).map((key, index) => {
                  const item = props.hotel_link[key];

                
                  return (
                    <>
                      {item.roomOptions.map((dat, index) => {
                        if (props.eJuniper != "no_ejuniper") {
                          return <></>;
                        } else {
                          let checkAdult = false;
                          if (cats.length > 0) {
                            for (var i = 0; i < cats.length; i++) {
                              if (
                                cats[i].adultCounter * 1 +
                                cats[i].childCounter * 1 <=
                                dat.guests_included
                              ) {
                                checkAdult = true;
                              } else {
                                checkAdult = false;
                              }
                            }
                          }

                          if (checkAdult == true) {
                            return (
                              <div className="appendTop20">
                                <div className="hsmakeFlex appendBottom40 comboWrap">
                                  <div className="left">
                                    <div className="hsmakeFlex bdrBottom padding20 spaceBetween hrtlCenter makeRelative">
                                      <div className="latoBold font20  comboTitle">
                                        Recommended for{" "}
                                        {cats.reduce(
                                          (a, v) =>
                                            a +
                                            v.adultCounter * 1 +
                                            v.childCounter * 1,
                                          0
                                        )}{" "}
                                        Adults
                                      </div>
                                    </div>

                                    {cats.map((i, z) =>
                                      props.hotel_link != "no_hotel_link" ? (
                                        <>
                                          <div className="hsmakeFlex latoBold roomRow">
                                            <div className="hsmakeFlex column width33">
                                              <span className="appendBottom5 roomTag whiteText">
                                                {" "}
                                                Room {z + 1}
                                              </span>
                                              <p className="font16 blueText appendBottom5">
                                                {key}
                                              </p>
                                              <p>
                                                {i.adultCounter * 1} Adults{" "}
                                                {i.childCounter > 0
                                                  ? i.childCounter == 1
                                                    ? "+ " +
                                                    i.childCounter +
                                                    " Child"
                                                    : "+ " +
                                                    i.childCounter +
                                                    " Children"
                                                  : ""}{" "}
                                              </p>
                                            </div>
                                            <div className="hsmakeFlex column width33">
                                              <p className="font16 blackText">
                                                {" "}
                                                Type
                                              </p>
                                              <ul className="types">
                                                <li>
                                                  Room With Free Cancellation
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="hsmakeFlex column width33">
                                              <p className="font16 blackText">
                                                {" "}
                                                Inclusions
                                              </p>
                                              <ul className="types">
                                               
                                              </ul>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    )}
                                  </div>
                                  <div className="priceBreakUp">
                                    <p className="bdrBottom font20 latoBold appendBottom20 breackUpHeading">
                                      Details
                                    </p>
                                    <div
                                      className="hsmakeFlex spaceBetween paddingLR20 font18 blackText latoBold"
                                      style={{ marginTop: 10 }}
                                    >
                                      <span>Price</span>
                                      <span id="detpg_combo_price">
                                        {getAmountHotellinkTotalAmount(
                                          dat.specialOffer,
                                          dat.AmountWithUplift
                                        )}
                                      </span>
                                    </div>
                                  
                                    <p className="font11 grayText textRight appendTop5 paddingLR20">
                                      (Per night for {roomCount} Room)
                                    </p>
                                    <a rel="nofollow"
                                      href="javascript:;"
                                      onClick={() =>
                                        bookCombo(
                                          keyfirst,
                                          dat.id,
                                          dat.AmountWithUplift,
                                          dat.adult,
                                          dat.child,
                                          item.room.id,
                                          item.roomAvailability,
                                          dat.specialOffer
                                        )
                                      }
                              
                                    >
                                      <span className="primaryBtn overlapBtn">
                                        BOOK THIS COMBO
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        }
                      })}{" "}
                    </>
                  );
                })}
              </>
            ) : (
              <> </>
            )}
          </>
        ) : (
          <></>
        )} */}

        <div className="gap"></div>

        <div className="comboRoomsSection" id="viewMoreRooms">
          <div className="roomheader">
            <span
              className="font12 latoBold capText titleWidth"
              style={{ width: "260px" }}
            >
              Room Type
            </span>

            {props.eJuniper == "no_ejuniper" ? (
              <>
                {roomCount > 1 ? (
                  <>
                    <span
                      className="font12 latoBold capText titleWidth"
                      style={{ width: "300px" }}
                    >
                      Policy
                    </span>
                    <span
                      className="font12 latoBold capText titleWidth"
                      style={{ width: "238px" }}
                    >
                      Select guest
                    </span>
                    <span
                      className="font12 latoBold capText titleWidth"
                      style={{ width: "182px" }}
                    >
                      Price/night
                    </span>
                    <span
                      className="font12 latoBold capText titleWidth"
                      style={{ width: "219px" }}
                    >
                      Cart
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="font12 latoBold capText titleWidth"
                      style={{ width: "260px" }}
                    >
                      Policy
                    </span>
                    <span
                      className="font12 latoBold capText titleWidth"
                      style={{ width: "260px" }}
                    >
                      Included in this Price
                    </span>
                    <span
                      className="font12 latoBold capText titleWidth"
                      style={{ width: "418px" }}
                    >
                      Price
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                <span
                  className="font12 latoBold capText titleWidth"
                  style={{ width: "260px" }}
                >
                  Policy
                </span>
                <span
                  className="font12 latoBold capText titleWidth"
                  style={{ width: "260px" }}
                >
                  Included in this Price
                </span>
                <span
                  className="font12 latoBold capText titleWidth"
                  style={{ width: "418px" }}
                >
                  Price
                </span>
              </>
            )}
          </div>
          {props.eJuniper != "no_ejuniper" ? (
            <>
              {" "}
              {props.eJuniper.map((item, index) => {
                return (
                  <div className="roomWrap">
                    <div className="roomLeft">
                      <div className="sticky">
                        {
                          item.HotelRooms.HotelRoom.length > 0 ? (<> {item.HotelRooms.HotelRoom.map((roomname, index) => (
                            <>
                              <h2>{roomname.attributes.Units} X {roomname.Name}</h2>

                            </>
                          ))}</>) : (<> <h2>{item.HotelRooms.HotelRoom.attributes.Units} X {item.HotelRooms.HotelRoom.Name}</h2></>)}

                        {/* <h2>{key} </h2> */}

                        {/* {stripHtml(props.roomDescription[key])} */}
                        {/* <ul className="appendBottom10">
                                    <li className="hsmakeFlex appendBottom5">
                                        <span className="oval"></span>Room Size: 7535 sq.ft</li><li className="hsmakeFlex appendBottom5"><span className="oval">

                                        </span>Air Conditioning</li>
                                    <li className="hsmakeFlex appendBottom5"><span className="oval"></span>Electric Kettle</li>
                                    <li className="hsmakeFlex appendBottom5"><span className="oval"></span>Housekeeping</li>
                                    <li className="hsmakeFlex appendBottom5"><span className="oval">

                                    </span>Bathroom</li>
                                    <li className="hsmakeFlex appendBottom5">
                                        <span className="oval"></span>Work Desk</li></ul> */}
                        {/* <a className="latoBlack blueText font12 capText hsmakeFlex hrtlCenter" id="detpg_more_about_room">More about this Resort <span className="spriteRate linkArrowIcon appendLeft10"></span></a> */}
                      </div>
                    </div>
                    <div className="roomRight">
                      {/* {props.eJuniper[key].map((item, index) => (
                        <> */}
                      <div className="multiRoomRow hsmakeFlex bdrBottom flexOne recRoom ">
                        <div className="hsmakeFlex column titleWidth">
                          <p
                            className="hsmakeFlex appendBottom20"
                            style={{ flexWrap: "wrap" }}
                          >
                            <span className="appendRight10 latoBold font14 darkText capText">
                              {" "}
                              {/* OPTION {index + 1} */}
                            </span>
                          </p>
                          <div className="hsmakeFlex  appendBottom15">
                            <span className="roomIconWrap">
                              <span className="spriteRate roomBreakfastIcon">
                                &nbsp;
                              </span>
                            </span>
                            <div className="hsmakeFlex column flexOne appendLeft10">
                              <p className="appendBottom4">
                                Room With | {item.Board}
                              </p>
                            </div>
                          </div>
                          {item?.CancellationPolicy && (
                          <div className="hsmakeFlex  appendBottom15">
                            <span className="roomIconWrap">
                              <span className="pwaSprite freeCancellation"></span>
                              &nbsp;
                            </span>
                            
                            <div className="hsmakeFlex column flexOne appendLeft10">
                              <p className="appendBottom4">
                                <span className="greenText latoBold extraSpacing">
                                  
                                  {item?.CancellationPolicy && (item?.CancellationPolicy?.PolicyRules?.Rule.length > 0 && item?.CancellationPolicy?.PolicyRules.Rule[0]?.attributes?.PercentPrice == 0 ?(<>Free Cancellation up to {" "}{moment(
                                    item?.CancellationPolicy?.PolicyRules?.Rule[0]?.attributes?.DateTo
                                  ).format("Do MMMM YYYY")}</>):(<>Cancellation up to {" "}{moment(
                                    item?.CancellationPolicy?.PolicyRules?.Rule[0]?.attributes?.DateTo
                                  ).format("Do MMMM YYYY")}</>))}
                                </span>
                              </p>
                              {item?.CancellationPolicy && (<a
                                className="OpenCancel"
                                onClick={openModalDiv}
                              >
                                Read More
                              </a>)}
                              
                            </div>
                          </div>)}
                        </div>
                        <div className="titleWidth">
                          <ul className="includeList">
                            <li>{item.Board}</li>
                          </ul>
                        </div>
                        <div className="hsmakeFlex column titleLastWidth ">
                          {item.ActualOfferName == "" ? (
                            <></>
                          ) : (
                            <div className="dealOfDay">
                              <p className="dealTitle">
                                Special Offer:{" "}
                                <span className="latoBold">
                                  {item.ActualOfferName}
                                </span>
                              </p>
                              <span
                                className="pwaSprite freeOffer"
                                data-tip
                                data-for={item.TooltipId}
                              ></span>
                              <ReactTooltip
                                className="rohan"
                                id={item.TooltipId}
                                place="left"
                                type="dark"
                              >
                                <span>{item.ActualOfferDesc}</span>
                              </ReactTooltip>
                            </div>
                          )}

                          <div className="hsmakeFlex appendTop40 roomTypePrice">
                            <div className="hsmakeFlex column font12 appendRight10 flexOne">
                            {roomCount > 1 ? (
                                <p className="font12 grayText appendBottom5">
                                  Per Night for {roomCount} Rooms{" "}
                                </p>
                              ) : (
                                <p className="font12 grayText appendBottom5">
                                  Per Night{" "}
                                </p>
                              )}
                              <div className="font16 latoBlack appendBottom5 relative hsmakeFlex column">
                                {props.HotelDetails.property_source == 1 ? (
                                  <>
                                    {item.HotelOfferAmount == "" ? (
                                      <p
                                        className="latoBlack font16 blackText appendBottom5"
                                        id="hlistpg_hotel_shown_price"
                                      >
                                        <FontAwesomeIcon
                                          icon={faEuroSign}
                                          color="#000"
                                          style={{ fontSize: "16px" }}
                                        ></FontAwesomeIcon>{" "}
                                        {(
                                          Math.round(item.NewAmount * 100) /
                                          100
                                        ).toFixed(2)}
                                      </p>
                                    ) : (
                                      <>
                                        {" "}
                                        {/* {item.PercentageAm==
                                          0 ? (
                                          <p
                                            className="latoBlack font16 blackText appendBottom5"
                                            id="hlistpg_hotel_shown_price"
                                          >
                                            <FontAwesomeIcon
                                              icon={faEuroSign}
                                              color="#000"
                                              style={{ fontSize: "16px" }}
                                            ></FontAwesomeIcon>{" "}
                                            {(
                                              Math.round(
                                                item.Amount * 100
                                              ) / 100
                                            ).toFixed(2)}
                                          </p>
                                        ) : (
                                          <>
                                            {" "}
                                            <div style={{display: "flex"}}>
                                            <p
                                              className="grayText font16 lineThrough appendBottom5"
                                              id="hlistpg_hotel_cut_price"
                                            >
                                              <FontAwesomeIcon
                                                icon={faEuroSign}
                                                style={{ fontSize: "13px" }}
                                              ></FontAwesomeIcon>
                                              {(
                                                Math.round(
                                                  item.Amount * 100
                                                ) / 100
                                              ).toFixed(2)}
                                            </p>
                                            <p
                                              className="latoBlack font16 blackText appendBottom5 appendLeft5"
                                              id="hlistpg_hotel_shown_price"
                                            >
                                              <FontAwesomeIcon
                                                icon={faEuroSign}
                                                color="#000"
                                                style={{ fontSize: "13px" }}
                                              ></FontAwesomeIcon>{" "}
                                              {(
                                                Math.round(
                                                  item.HotelOfferAmount *
                                                  100
                                                ) / 100
                                              ).toFixed(2)}
                                            </p>
                                            </div>
                                            <p className="hsmakeFlex hrtlCenter appendBottom10 directionRtl">
                                              <span className="redText font12">
                                                <span>Save </span>
                                                <span
                                                  className="latoBold"
                                                  id="hlistpg_hotel_saved_price"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faEuroSign}
                                                    style={{
                                                      fontSize: "10px",
                                                    }}
                                                  ></FontAwesomeIcon>{" "}
                                                  {(
                                                    Math.round(
                                                      item.HotelOfferAmNew *
                                                      100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </span>
                                              <span className="spriteNew discountIcon relative appendLeft5">
                                                <span
                                                  className="latoBold font11 whiteText discountText"
                                                  id="hlistpg_hotel_saved_price_percentage">
                                                  {
                                                    item.PercentageAm
                                                  }
                                                  %
                                                </span>
                                              </span>
                                            </p>
                                          </>
                                        )} */}
                                                                             { isNaN(item?.PercentageAm) ?(<p
                                      className="latoBlack font16 blackText appendBottom5"
                                      id="hlistpg_hotel_shown_price"
                                    >
                                      <FontAwesomeIcon
                                        icon={faEuroSign}
                                        color="#000"
                                        style={{ fontSize: "16px" }}
                                      ></FontAwesomeIcon>{" "}
                                      {(
                                        Math.round(
                                          item.HotelOfferAmount * 100
                                        ) / 100
                                      ).toFixed(2)}
                                    </p>):((item?.PercentageAm == "inf" || item?.PercentageAm == "-inf")?(<p
                                      className="latoBlack font16 blackText appendBottom5"
                                      id="hlistpg_hotel_shown_price"
                                    >
                                      <FontAwesomeIcon
                                        icon={faEuroSign}
                                        color="#000"
                                        style={{ fontSize: "16px" }}
                                      ></FontAwesomeIcon>{" "}
                                      {(
                                        Math.round(
                                          item.HotelOfferAmount * 100
                                        ) / 100
                                      ).toFixed(2)}
                                    </p>):(item.PercentageAm==
                                          0 ?(<p
                                      className="latoBlack font16 blackText appendBottom5"
                                      id="hlistpg_hotel_shown_price"
                                    >
                                      <FontAwesomeIcon
                                        icon={faEuroSign}
                                        color="#000"
                                        style={{ fontSize: "16px" }}
                                      ></FontAwesomeIcon>{" "}
                                      {(
                                        Math.round(
                                          item?.HotelOfferAmount * 100
                                        ) / 100
                                      ).toFixed(2)}
                                    </p>):( <>
                                            {" "}
                                            <div style={{display: "flex"}}>
                                            <p
                                              className="grayText font16 lineThrough appendBottom5"
                                              id="hlistpg_hotel_cut_price"
                                            >
                                              <FontAwesomeIcon
                                                icon={faEuroSign}
                                                style={{ fontSize: "13px" }}
                                              ></FontAwesomeIcon>
                                              {(
                                                Math.round(
                                                  item.Amount * 100
                                                ) / 100
                                              ).toFixed(2)}
                                            </p>
                                            <p
                                              className="latoBlack font16 blackText appendBottom5 appendLeft5"
                                              id="hlistpg_hotel_shown_price"
                                            >
                                              <FontAwesomeIcon
                                                icon={faEuroSign}
                                                color="#000"
                                                style={{ fontSize: "13px" }}
                                              ></FontAwesomeIcon>{" "}
                                              {(
                                                Math.round(
                                                  item.HotelOfferAmount *
                                                  100
                                                ) / 100
                                              ).toFixed(2)}
                                            </p>
                                            </div>
                                            <p className="hsmakeFlex hrtlCenter appendBottom10 directionRtl">
                                              <span className="redText font12">
                                                <span>Save </span>
                                                <span
                                                  className="latoBold"
                                                  id="hlistpg_hotel_saved_price"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faEuroSign}
                                                    style={{
                                                      fontSize: "10px",
                                                    }}
                                                  ></FontAwesomeIcon>{" "}
                                                  {(
                                                    Math.round(
                                                      item.HotelOfferAmNew *
                                                      100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </span>
                                              <span className="spriteNew discountIcon relative appendLeft5">
                                                <span
                                                  className="latoBold font11 whiteText discountText"
                                                  id="hlistpg_hotel_saved_price_percentage">
                                                  {
                                                    item.PercentageAm
                                                  }
                                                  %
                                                </span>
                                              </span>
                                            </p>
                                          </>)))}
                                      
                                  
                                       
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {props.HotelDetails.property_source ==
                                      2 ? (
                                      <>
                                        {/* {hotellink == '' ? (<></>):(<>
                                                    <p className="latoBlack font26 blackText appendBottom5" id="hlistpg_hotel_shown_price"><FontAwesomeIcon icon={faEuroSign} color='#000' style={{fontSize: '23px'}}></FontAwesomeIcon> {hotellink.amount}</p>
                                                </>)} */}
                                      </>
                                    ) : (
                                      <> </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="hsmakeFlex column perfectCenter noShrink">
                              <a
                                href={
                                  "/paymentWall?hotelid=" +
                                  Base64.btoa(props.HotelDetails.id) +
                                  "&query=" +
                                  encodeURIComponent(props.querySearching) +
                                  "&hotelImage=" +
                                  encodeURIComponent(props.HotelImage) +
                                  "&propertySource=" +
                                  encodeURIComponent(
                                    props.HotelDetails.property_source
                                  ) +
                                  "&ratePlanCode=" +
                                  encodeURIComponent(
                                    item.attributes.RatePlanCode
                                  )
                                }
                               
                                className="primaryBtn2 appendBottom15 "
                              >
                                SELECT ROOM
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>

                      <Modal
                        isOpen={openModal}
                        onRequestClose={closeModal}
                        className="Modal"
                        overlayClassName="backdrop"
                      >
                        <div className="modalCont">
                          <span
                            className="closeButton"
                            onClick={closeModal}
                          >
                            Close
                          </span>
                          <h4>Cancellation Policy :</h4>
                          <div className="modalContent">
                            <div>
                              {/* <div className="latoRegular font16 appendBottom15">
                                <b>
                                  Free Cancellation (100% refund) if you
                                  cancel this booking before &#x202A;
                                  {item.CancellationPolicy && (item.CancellationPolicy.PolicyRules.Rule.length > 0 && moment(
                                    item.CancellationPolicy.PolicyRules
                                      .Rule[0].attributes.DateTo
                                  ).format("Do MMMM YYYY"))}
                                  &#x202C;
                                
                                </b>
                              </div> */}
                            </div>
                            <div>
                              <div className="latoRegular font16 appendBottom15">
                                <b>
                                  {/* Cancellations post that will be subject to
                                      a fee as follows */}
                                  {/* The cancellation charges will be applicable as follows */}
                                </b>
                              </div>
                              {item?.CancellationPolicy && (<div className="cncpolicyTbl">
                                <div className="cncpolicyTbl__head">
                                  <div className="cncpolicyTbl__col">
                                    DATE
                                  </div>
                                  <div className="cncpolicyTbl__col">
                                    FEE
                                  </div>
                                </div>
                                {item?.CancellationPolicy && (item.CancellationPolicy?.PolicyRules?.Rule?.length > 0 ?( item?.CancellationPolicy?.PolicyRules?.Rule.map(
                                  (data, index) => (
                                    <div className="cncpolicyTbl__row">
                                      <div className="cncpolicyTbl__col">
                                        {/* {index == 0 ? "Before" : "After"}{" "} */}
                                        {/* { moment(data?.attributes?.DateFrom).format("Do MMMM YYYY")}
                                        &#x202A;
                                        to  &#x202A;
                                        { moment(data?.attributes?.DateTo).format("Do MMMM YYYY")} */}
                                        {index === item?.CancellationPolicy?.PolicyRules?.Rule.length - 1 
                                            ? `After ${moment(data?.attributes?.DateFrom).format("Do MMMM YYYY")}` 
                                            : `${moment(data?.attributes?.DateFrom).format("Do MMMM YYYY")} to ${moment(data?.attributes?.DateTo).format("Do MMMM YYYY")}`
                                          }
                                        {/* {index == 0
                                          ? moment(
                                            data.attributes.DateTo
                                          ).format("Do MMMM YYYY")
                                          : moment(
                                            data.attributes.DateFrom
                                          ).format("Do MMMM YYYY")} */}
                                        &#x202C;
                                      </div>
                                      <div className="cncpolicyTbl__col">
                                        {data?.attributes?.PercentPrice}% of booking amount will be charged
                                      </div>
                                    </div>
                                  )
                                )):(item?.CancellationPolicy?.PolicyRules?.Rule?.attributes?.ApplicationTypeNights =="FirstNight" &&(<div className="cncpolicyTbl__row">
                                  <div className="cncpolicyTbl__col">
                                    {/* {index == 0 ? "Before" : "After"}{" "} */}
                                    {/* { moment(data?.attributes?.DateFrom).format("Do MMMM YYYY")}
                                    &#x202A;
                                    to  &#x202A;
                                    { moment(data?.attributes?.DateTo).format("Do MMMM YYYY")} */}
                                    {index === item?.CancellationPolicy?.PolicyRules?.Rule?.attributes.length - 1 
                                        ? `After ${moment(item?.CancellationPolicy?.PolicyRules?.Rule?.attributes?.DateFrom).format("Do MMMM YYYY")}` 
                                        : `${moment(item?.CancellationPolicy?.PolicyRules?.Rule?.attributes?.DateFrom).format("Do MMMM YYYY")} to ${moment(item?.CancellationPolicy?.PolicyRules?.Rule?.attributes?.DateTo).format("Do MMMM YYYY")}`
                                      }
                                    {/* {index == 0
                                      ? moment(
                                        data.attributes.DateTo
                                      ).format("Do MMMM YYYY")
                                      : moment(
                                        data.attributes.DateFrom
                                      ).format("Do MMMM YYYY")} */}
                                    &#x202C;
                                  </div>
                                  <div className="cncpolicyTbl__col">
                                     full booking amount will be charged
                                  </div>
                                </div>)))}
                              </div>)}
                            </div>
                            <div>
                              {item?.CancellationPolicy && (<div className="latoRegular font16 appendBottom15">
                                {/* Cancellations are only allowed before the
                                Check-In Time. All time mentioned above is
                                in Destination Time. */}
                                Cancellations are only allowed before check-in time. All times mentioned above are in Destination Time (local time of the destination).
                              </div>)}
                            </div>
                          </div>
                        </div>
                      </Modal>
                      {/* </> */}
                      {/* ))} */}
                    </div>
                  </div>
                );
              })}{" "}
            </>
          ) : (
            <> </>
          )}

          {props.hs_portal != "no_hs_portal" ? (
            <>
              {roomCount > 1 ? (
                <>
                  <div className="roomWrap">
                    <div className="roomLeftCont">
                      {props.hs_portal != "no_hs_portal" ? (
                        <>
                          {Object.keys(props.hs_portal).map((key, indextop) => {
                            const item = props.hs_portal[key];
                           
                            return (
                              <div className="roomLeftContRow">
                                <div className="roomLeft">
                                  <div className="sticky">
                                    <h2>{key}</h2>
                                   
                                    <Carousel>
                                      {item.roomImages && item.roomImages.map((i, indextop) => (

                                        <div>
                                          <a onClick={openModalDivHSRoom}><img src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`} />
                                          </a>

                                        </div>

                                      ))}

                                    </Carousel>
                                    <Modal
                                      isOpen={openModalRoom}
                                      onRequestClose={closeModalDivHSRoom}
                                      overlayClassName="backdropNew"
                                    >
                                      <div className="modalCont">
                                        <span
                                          className="closeButton"
                                          onClick={closeModalDivHSRoom}
                                        >
                                          Close
                                        </span>
                                        {/* <h4>Cancellation Policy :</h4> */}
                                        <div className="modalContent">

                                          <Carousel>
                                            {item.roomImages && item.roomImages.map((i, indextop) => (
                                              <div>
                                                <img src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`} />

                                              </div>
                                            ))}

                                          </Carousel>

                                        </div>
                                      </div>
                                    </Modal>

                                    <ul className="appendBottom10" style={{marginTop: "-20px"}}>
                                      {item.roomFacilities
                                        .slice(0, 5)
                                        .map((result, index) => (
                                          <li className="hsmakeFlex appendBottom5">
                                            <span className="oval"></span>
                                            {result.room_amenity}
                                          </li>
                                        ))}
                                    </ul>
                                    {/* <a
                                      class="latoBlack blueText font12 capText hsmakeFlex hrtlCenter"
                                      style={{
                                        color: "#0084ff",
                                        cursor: "pointer",
                                      }}
                                    >
                                      More about this Hotel{" "}
                                      <span class="sprite linkArrowIcon appendLeft10"></span>
                                    </a> */}
                                  </div>
                                </div>
                                <div className="Middle">
                                  {item.roomOptions.map((dat, index) => {
                                  
                                    let tooltip = "tooltip_" + index;
                                    let balanceAmount = 0;

                                    if (dat.specialOffer != "no_offer") {
                                      let result = [];
                                      if (
                                        dat.specialOffer.combineoffer ==
                                        "nocombine"
                                      ) {
                                        if (
                                          dat.specialOffer.specialdata
                                            .spl_offer_type_id == 1
                                        ) {
                                          const dis =
                                            dat.AmountWithUplift *
                                            parseInt(
                                              dat.specialOffer.specialdata
                                                .pay_stay_length
                                            ) -
                                            dat.AmountWithUplift *
                                            parseInt(
                                              dat.specialOffer.specialdata
                                                .pay_stay_value
                                            );
                                        
                                          const disA =
                                            (dis /
                                              (dat.AmountWithUplift *
                                                parseInt(
                                                  dat.specialOffer.specialdata
                                                    .pay_stay_length
                                                ))) *
                                            100;
                                         
                                          result.push(disA);
                                        } else {
                                          if (
                                            dat.specialOffer.specialdata
                                              .discount_in == 2
                                          ) {
                                            const disA =
                                              dat.specialOffer.specialdata
                                                .rate_discount;
                                            result.push(+disA);
                                          } else {
                                            const disA =
                                              dat.AmountWithUplift -
                                              dat.specialOffer.specialdata
                                                .rate_discount;
                                            result.push(+disA);
                                          }
                                        }
                                      } else {
                                        if (
                                          dat.specialOffer.specialdata
                                            .spl_offer_type_id == 1
                                        ) {
                                          const dis =
                                            dat.AmountWithUplift *
                                            parseInt(
                                              dat.specialOffer.specialdata
                                                .pay_stay_length
                                            ) -
                                            dat.AmountWithUplift *
                                            parseInt(
                                              dat.specialOffer.specialdata
                                                .pay_stay_value
                                            );
                                          const disA =
                                            (dis /
                                              (dat.AmountWithUplift *
                                                parseInt(
                                                  dat.specialOffer.specialdata
                                                    .pay_stay_length
                                                ))) *
                                            100;
                                          result.push(disA);
                                        } else {
                                          if (
                                            dat.specialOffer.specialdata
                                              .discount_in == 2
                                          ) {
                                            const disA =
                                              dat.specialOffer.specialdata
                                                .rate_discount;
                                            result.push(+disA);
                                          } else {
                                            const disA =
                                              dat.AmountWithUplift -
                                              dat.specialOffer.specialdata
                                                .rate_discount;
                                            result.push(+disA);
                                          }
                                        }
                                      }

                                      const Amount = result.reduce(
                                        (a, v) => a + v,
                                        0
                                      );

                                      balanceAmount =
                                        dat.AmountWithUplift -
                                        (
                                          Math.round(
                                            Amount * dat.AmountWithUplift
                                          ) / 100
                                        ).toFixed(2);
                                    }
                                    let checkAdult = false;

                                    let result = [];
                                    if (cats.length > 0) {
                                      for (var i = 0; i < cats.length; i++) {
                                        if (
                                          cats[i].adultCounter * 1 +
                                          cats[i].childCounter * 1 <=
                                          dat.max_guests
                                        ) {
                                          checkAdult = true;

                                          result.push({
                                            number: cats[i].adultCounter,
                                            price: dat.extraAdult,
                                          });
                                        } else {
                                          checkAdult = false;
                                        }
                                      }
                                    }

                                   
                                    return (
                                      <>
                                        <div className="multiRoomRow hsmakeFlex bdrBottom ">
                                          <div className="hsmakeFlex column col1">
                                            <p
                                              className="hsmakeFlex appendBottom20"
                                              style={{ flexWrap: "wrap" }}
                                            >
                                              <span className="appendRight10 latoBold font14 darkText capText">
                                                {" "}
                                                OPTION {index + 1}
                                              </span>
                                            </p>
                                            <p
                                              className="appendBottom4"
                                              style={{
                                                textDecorationLine: "underline",
                                              }}
                                            >
                                              {dat.rate_plan_name}
                                            </p>

                                            <div className="hsmakeFlex  appendBottom15">
                                              <span className="roomIconWrap">
                                                <span className="spriteRate roomBreakfastIcon">
                                                  &nbsp;
                                                </span>
                                              </span>
                                              <div className="hsmakeFlex column flexOne appendLeft10">
                                                <p className="appendBottom4">
                                                  Room With Free Cancellation |
                                                </p>
                                              </div>
                                            </div>
                                            <div className="hsmakeFlex  appendBottom15">
                                              <span className="roomIconWrap">
                                                <span className="pwaSprite freeCancellation"></span>
                                                &nbsp;
                                              </span>
                                              <div className="hsmakeFlex column flexOne appendLeft10">
                                                <p className="appendBottom4">
                                                  <span className="greenText latoBold extraSpacing">
                                                    {item.roomCancellation[
                                                      index
                                                    ].length > 0 &&
                                                      getDate(
                                                        item.roomCancellation[
                                                        index
                                                        ]
                                                      )}
                                                  </span>
                                                </p>
                                                <a
                                                  className="OpenCancel"
                                                  onClick={openModalDivHS}
                                                >
                                                  Read More
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="col2">
                                              <ul className="includeList"></ul>
                                            </div> */}
                                          <div className="col3">
                                            <div className="ddPosition">
                                              <div>
                                                {result.length > 0 ? (
                                                  <div className="slctRmDropdown  appendTop10 appendBottom20">
                                                    <div className="ddWrapper">
                                                      <div
                                                        className="slctRmDropdown__input"
                                                        onClick={() =>
                                                          setOption(dat.id)
                                                        }
                                                      >
                                                        {OpenDropdownValue.filter(
                                                          (e) =>
                                                            e.rate_id === dat.id
                                                        ).length > 0
                                                          ? OpenDropdownValue.filter(
                                                            (e) =>
                                                              e.rate_id ===
                                                              dat.id
                                                          )[0].number
                                                          : 1}{" "}
                                                        Adult
                                                      </div>

                                                      {OpenDropdown.includes(
                                                        dat.id
                                                      ) && (
                                                          <ul className="slctRmDropdown__list">
                                                            {result
                                                              .sort(
                                                                (a, b) =>
                                                                  a.number -
                                                                  b.number
                                                              )
                                                              .map((i, j) => {
                                                                let newPrice = 0;
                                                                if (
                                                                  i.price != ""
                                                                ) {
                                                                  newPrice = Math.round(
                                                                    dat.AmountWithUplift *
                                                                    1 +
                                                                    i.price *
                                                                    1 *
                                                                    (i.number -
                                                                      dat.guests_included)
                                                                  ).toFixed(2);
                                                                } else {
                                                                  newPrice =
                                                                    dat.AmountWithUplift;
                                                                }
                                                                return (
                                                                  <li
                                                                    className="slctRmDropdown__list--item"
                                                                    onClick={() =>
                                                                      PriceChanger(
                                                                        i.number,
                                                                        newPrice,
                                                                        dat.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <p className="latoBold appendBottom3">
                                                                      {i.number}{" "}
                                                                      Adult
                                                                    </p>
                                                                    <p className="latoBold font12 grayText">
                                                                      <span>€</span>{newPrice}
                                                                    </p>
                                                                  </li>
                                                                );
                                                              })}
                                                          </ul>
                                                        )}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <p className="latoBold font14 darkText appendBottom3">
                                                    {dat.adult != null
                                                      ? dat.adult
                                                      : dat.guests_included}{" "}
                                                    Adults{" "}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col4">
                                            <div
                                              className=""
                                              style={{ marginTop: 20 }}
                                            >
                                              {dat.specialOffer !=
                                                "no_offer" && (
                                                  <div className="dealOfDayNew">
                                                    <p className="dealTitle">
                                                      Special Offer:{" "}
                                                      <span className="latoBold">
                                                        {showSpecialOffer(dat)}
                                                      </span>
                                                    </p>
                                                    <span
                                                      className="pwaSprite freeOffer"
                                                      data-tip
                                                      data-for={tooltip}
                                                    ></span>
                                                    <ReactTooltip
                                                      className="rohan"
                                                      id={tooltip}
                                                      place="left"
                                                      type="dark"
                                                    >
                                                      <span>
                                                        {showSpecialOfferDis(dat)}
                                                      </span>
                                                    </ReactTooltip>
                                                  </div>
                                                )}
                                              <div className="hsmakeFlex column font12 appendRight10 flexOne">
                                              <p className="font12 grayText appendBottom5">
                                                  Per Night
                                                </p>
                                                <div
                                                  className="font22 latoBlack appendBottom5 relative hsmakeFlex column"
                                                  style={{
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {getAmountHotelLink(
                                                    dat.specialOffer,
                                                    dat.AmountWithUplift,
                                                    dat.id
                                                  )}
                                                </div>
                                              </div>

                                              <p>
                                                <a
                                                  className={
                                                    "primaryBtn btnAddRoom add_Room_" +
                                                    item.room.id
                                                  }
                                                  onClick={() => {
                                                    addRoomtoCart(
                                                      key,
                                                      dat.id,
                                                      getAmountHotelLinkCart(
                                                        dat.specialOffer,
                                                        dat.AmountWithUplift,
                                                        dat.id
                                                      ),
                                                      dat.adult,
                                                      dat.child,
                                                      item.room.id,
                                                      item.roomAvailability
                                                    );
                                                  }}
                                                >
                                                  ADD{" "}
                                                  {ordinal_suffix_of(
                                                    cart.length + 1
                                                  )}{" "}
                                                  Room
                                                </a>
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <Modal
                                          isOpen={openModalHS}
                                          onRequestClose={closeModalDivHS}
                                          overlayClassName="backdropNew"
                                        >
                                          <div className="modalCont">
                                            <span
                                              className="closeButton"
                                              onClick={closeModalDivHS}
                                            >
                                              Close
                                            </span>
                                            <h4>Cancellation Policy :</h4>
                                            <div className="modalContent">
                                              <div>
                                                <div className="latoRegular font16 appendBottom15">
                                                  <b>
                                                    {item.roomCancellation[
                                                      index
                                                    ].length > 0 &&
                                                      getDateModal(
                                                        item.roomCancellation[
                                                        index
                                                        ]
                                                      )}
                                                    ‬&#x202C;
                                                  </b>
                                                </div>
                                              </div>
                                              <div>
                                                <div className="latoRegular font16 appendBottom15">
                                                  <b>
                                                    {/* Cancellations post that will
                                                    be subject to a fee as
                                                    follows */}
                                                    The cancellation charges will be applicable as follows
                                                  </b>
                                                </div>
                                                <div className="cncpolicyTbl">
                                                  <div className="cncpolicyTbl__head">
                                                    <div className="cncpolicyTbl__col">
                                                      DATE
                                                    </div>
                                                    <div className="cncpolicyTbl__col">
                                                      FEE
                                                    </div>
                                                  </div>

                                                  {CancellationModal(
                                                    dat.id,
                                                    item.roomCancellation[index]
                                                  )}
                                                </div>
                                              </div>
                                              <div>
                                                <div className="latoRegular font16 appendBottom15">
                                                  Cancellations are only allowed
                                                  before the Check-In Time. All
                                                  time mentioned above is in
                                                  Destination Time.
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Modal>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}{" "}
                        </>
                      ) : (
                        <> </>
                      )}
                    </div>
                    <div className="roomRightCont">
                      <div className="roomRightContSticky">
                        {cart.length > 0 ? (
                          <>
                            {cart.map((i, index) => {
                              return (
                                <>
                                  <div
                                    className={
                                      "selectedRoom appendTop20 add_roomCount_" +
                                      i.room_id
                                    }
                                  >
                                    <span
                                      className="btnCloseRoom"
                                      onClick={() => {
                                        clearCart(index, i);
                                      }}
                                    ></span>
                                    <div>
                                      <div className="selectedRoomRow">
                                        <p className="latoBold font14 blackText appendBottom3">
                                          {i.name}
                                        </p>
                                        <p className="font12 appendBottom10">
                                          {i.adult} Adults
                                        </p>
                                        <p className="latoBlack font18 blackText appendBottom3">
                                          <FontAwesomeIcon
                                            icon={faEuroSign}
                                            color="#000"
                                            style={{ fontSize: "14px" }}
                                          ></FontAwesomeIcon>
                                          {Number(i.amount).toFixed(2)}
                                        </p>
                                        <p className="font12">Per Night</p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                            <div
                              className="makeFlex column spaceBetween appendTop20 appendBottom15"
                              style={{ marginTop: 10 }}
                            >
                              <span className="latoRegular font12 grayText lineHight16">
                                {cart.reduce((a, v) => a + v.adult * 1, 0)}{" "}
                                Adults{" "}
                              </span>
                              <span className="latoBlack font20 blackText">
                                <FontAwesomeIcon
                                  icon={faEuroSign}
                                  color="#000"
                                  style={{ fontSize: "17px" }}
                                ></FontAwesomeIcon>
                                {cart
                                  .reduce((a, v) => a + v.amount * 1, 0)
                                  .toFixed(2)}
                              </span>

                              {/* <span className="latoRegular font12 darkGreyText lineHight16">+₹ 283 taxes &amp; fees</span> */}

                              <span className="latoRegular font12 darkText lineHight16">
                                Per Night
                              </span>
                            </div>
                            <a
                              className="btnConfirmBooking appendBottom15"
                              href={
                                "/paymentWall?hotelid=" +
                                Base64.btoa(props.HotelDetails.id) +
                                "&query=" +
                                encodeURIComponent(props.querySearching) +
                                "&hotelImage=" +
                                encodeURIComponent(props.HotelImage) +
                                "&propertySource=" +
                                encodeURIComponent(
                                  props.HotelDetails.property_source
                                ) +
                                "&cart=" +
                                JSON.stringify(cart)
                              }

                              style={{ marginTop: 12, cursor: "pointer" }}
                            >
                              Review Details
                            </a>
                          </>
                        ) : (
                          <>
                            <p className="txtNoRoomSelected">
                              No Room
                              <br />
                              Selected
                            </p>
                            <div className="roomBlank nextRoom appendTop20">
                              <span>Room 1</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {props.hs_portal != "no_hs_portal" ? (
                    <>
                      {" "}
                      {Object.keys(props.hs_portal).map((key, indextop) => {
                        const item = props.hs_portal[key];

                        // return (
                        //   <div className="roomWrap">
                        //     <div className="roomLeft">
                        //       <div className="sticky">
                        //         <h2>{key} </h2>
                        //         {item.roomImages.slice(0, 1).map((i, k) => (
                        //           <img
                        //             src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`}
                        //             alt="room Img"
                        //             className="hsmakeFlex appendBottom20 roomImg"
                        //           ></img>
                        //         ))}
                        //         <ul className="appendBottom10">
                        //           {item.roomFacilities
                        //             .slice(0, 5)
                        //             .map((result, index) => (
                        //               <li className="hsmakeFlex appendBottom5">
                        //                 <span className="oval"></span>
                        //                 {result.room_amenity}
                        //               </li>
                        //             ))}
                        //         </ul>
                        //         {/* <a
                        //           className="latoBlack blueText font12 capText hsmakeFlex hrtlCenter"
                        //           style={{
                        //             color: "#0084ff",
                        //             cursor: "pointer",
                        //           }}
                        //         >
                        //           More about this Resort{" "}
                        //           <span className="spriteRate linkArrowIcon appendLeft10"></span>
                        //         </a> */}
                        //       </div>
                        //     </div>
                        //     <div className="roomRight">
                        //       {item.roomOptions.map((dat, index) => {
                        //         let checkAdult = false;
                        //         let tooltip = "tooltip_" + index;
                        //         if (cats.length > 0) {
                        //           for (var i = 0; i < cats.length; i++) {
                        //             if (
                        //               cats[i].adultCounter * 1 +
                        //                 cats[i].childCounter * 1 <=
                        //               dat.max_guests
                        //             ) {
                        //               checkAdult = true;
                        //             } else {
                        //               checkAdult = false;
                        //             }
                        //           }
                        //         }

                        //         return (
                        //           <div className="multiRoomRow hsmakeFlex bdrBottom flexOne recRoom ">
                        //             <div className="hsmakeFlex column titleWidth">
                        //               <p
                        //                 className="hsmakeFlex appendBottom20"
                        //                 style={{ flexWrap: "wrap" }}
                        //               >
                        //                 <span className="appendRight10 latoBold font14 darkText capText">
                        //                   {" "}
                        //                   OPTION {index + 1}
                        //                 </span>
                        //               </p>
                        //               <p
                        //                 className="appendBottom4"
                        //                 style={{
                        //                   textDecorationLine: "underline",
                        //                 }}
                        //               >
                        //                 {dat.rate_plan_name}
                        //               </p>
                        //               <div className="hsmakeFlex  appendBottom15">
                        //                 <span className="roomIconWrap">
                        //                   <span className="spriteRate roomBreakfastIcon">
                        //                     &nbsp;
                        //                   </span>
                        //                 </span>
                        //                 <div className="hsmakeFlex column flexOne appendLeft10">
                        //                   <p className="appendBottom4">
                        //                     Room With Free Cancellation |{" "}
                        //                   </p>
                        //                 </div>
                        //               </div>
                        //               <div className="hsmakeFlex  appendBottom15">
                        //                 <span className="roomIconWrap">
                        //                   <span className="pwaSprite freeCancellation"></span>
                        //                   &nbsp;
                        //                 </span>
                        //                 <div className="hsmakeFlex column flexOne appendLeft10">
                        //                   <p className="appendBottom4">
                        //                     <span className="greenText latoBold extraSpacing">
                        //                       {item.roomCancellation[index]
                        //                         .length > 0 &&
                        //                         getDate(
                        //                           item.roomCancellation[index]
                        //                         )}
                        //                     </span>
                        //                   </p>
                        //                   <a
                        //                     className="OpenCancel"
                        //                     onClick={openModalDivHS}
                        //                   >
                        //                     Read More
                        //                   </a>
                        //                 </div>
                        //               </div>
                        //             </div>
                        //             <div className="titleWidth">
                        //               <ul className="includeList">
                        //                 {dat.OptionInclusion.map(
                        //                   (nam, keyy) => (
                        //                     <li>{nam.inclusion_name}</li>
                        //                   )
                        //                 )}
                        //               </ul>
                        //             </div>
                        //             <div className="hsmakeFlex column titleLastWidth ">
                        //               {dat.specialOffer != "no_offer" && (
                        //                 <div className="dealOfDayNew">
                        //                   <p className="dealTitle">
                        //                     Special Offer:{" "}
                        //                     <span className="latoBold">
                        //                       {showSpecialOffer(dat)}
                        //                     </span>
                        //                   </p>
                        //                   <span
                        //                     className="pwaSprite freeOffer"
                        //                     data-tip
                        //                     data-for={tooltip}
                        //                   ></span>
                        //                   <ReactTooltip
                        //                     className="rohan"
                        //                     id={tooltip}
                        //                     place="left"
                        //                     type="dark"
                        //                   >
                        //                     <span>
                        //                       {showSpecialOfferDis(dat)}
                        //                     </span>
                        //                   </ReactTooltip>
                        //                 </div>
                        //               )}
                        //               <div className="hsmakeFlex appendTop40 roomTypePrice">
                        //                 <div className="hsmakeFlex column font12 appendRight10 flexOne">
                        //                   <div
                        //                     className="font22 latoBlack appendBottom5 relative hsmakeFlex column"
                        //                     style={{
                        //                       alignItems: "center",
                        //                     }}
                        //                   >
                        //                     {/* <p
                        //                             className="latoBlack font26 blackText appendBottom5"
                        //                             id="hlistpg_hotel_shown_price"
                        //                           >
                        //                             <FontAwesomeIcon
                        //                               icon={faEuroSign}
                        //                               color="#000"
                        //                               style={{
                        //                                 fontSize: "23px",
                        //                               }}
                        //                             ></FontAwesomeIcon>{" "}
                        //                             {(
                        //                               Math.round(
                        //                                 dat.AmountWithUplift *
                        //                                   100
                        //                               ) / 100
                        //                             ).toFixed(2)}
                        //                           </p> */}
                        //                     {getAmountHsPortal(
                        //                       dat.specialOffer,
                        //                       dat.AmountWithUplift,
                        //                       dat.id
                        //                     )}
                        //                   </div>
                        //                   <p className="font12 grayText appendBottom5">
                        //                     Per Night
                        //                   </p>
                        //                 </div>
                        //                 <div className="hsmakeFlex column perfectCenter noShrink">
                        //                   <a
                        //                     className="primaryBtn2 appendBottom15"
                        //                     href={
                        //                       "/paymentWall?hotelid=" +
                        //                       Base64.btoa(
                        //                         props.HotelDetails.id
                        //                       ) +
                        //                       "&query=" +
                        //                       encodeURIComponent(
                        //                         props.querySearching
                        //                       ) +
                        //                       "&hotelImage=" +
                        //                       encodeURIComponent(
                        //                         props.HotelImage
                        //                       ) +
                        //                       "&propertySource=" +
                        //                       encodeURIComponent(
                        //                         props.HotelDetails
                        //                           .property_source
                        //                       ) +
                        //                       "&cart=" +
                        //                       getHSCartData(
                        //                         dat.id,
                        //                         dat.AmountWithUplift,
                        //                         item.room.id
                        //                       )
                        //                     }
                        //                   >
                        //                     SELECT ROOM
                        //                   </a>
                        //                 </div>
                        //               </div>
                        //             </div>
                        //           </div>
                        //         );
                        //       })}
                        //     </div>
                        //   </div>
                        // );
                        return (
                          <div className="roomWrap">
                            <div className="roomLeft">
                              <div className="sticky">
                                <h2>{key} </h2>
                                <Carousel>
                                  {item.roomImages && item.roomImages.map((i, indextop) => (

                                    <div>
                                      <a onClick={openModalDivHSRoom}><img src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`} />
                                      </a>

                                    </div>

                                  ))}

                                </Carousel>
                                <Modal
                                  isOpen={openModalRoom}
                                  onRequestClose={closeModalDivHSRoom}
                                  overlayClassName="backdropNew"
                                >
                                  <div className="modalCont">
                                    <span
                                      className="closeButton"
                                      onClick={closeModalDivHSRoom}
                                    >
                                      Close
                                    </span>
                                    {/* <h4>Cancellation Policy :</h4> */}
                                    <div className="modalContent">

                                      <Carousel>
                                        {item.roomImages && item.roomImages.map((i, indextop) => (
                                          <div>
                                            <img src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`} />

                                          </div>
                                        ))}

                                      </Carousel>

                                    </div>
                                  </div>
                                </Modal>

                                <ul className="appendBottom10" style={{marginTop: "-20px"}}>
                                  {item.roomFacilities
                                    .slice(0, 5)
                                    .map((result, index) => (
                                      <li className="hsmakeFlex appendBottom5">
                                        <span className="oval"></span>
                                        {result.room_amenity}
                                      </li>
                                    ))}
                                </ul>
                                {/* <a
                                  className="latoBlack blueText font12 capText hsmakeFlex hrtlCenter"
                                  style={{
                                    color: "#0084ff",
                                    cursor: "pointer",
                                  }}
                                >
                                  More about this Resort{" "}
                                  <span className="spriteRate linkArrowIcon appendLeft10"></span>
                                </a> */}
                              </div>
                            </div>
                            <div className="roomRight">
                              {item.roomOptions.map((dat, index) => {
                                let checkAdult = false;
                                let tooltip = "tooltip_" + index;
                                if (cats.length > 0) {
                                  for (var i = 0; i < cats.length; i++) {
                                    if (
                                      cats[i].adultCounter * 1 +
                                      cats[i].childCounter * 1 <=
                                      dat.max_guests
                                    ) {
                                      checkAdult = true;
                                    } else {
                                      checkAdult = false;
                                    }
                                  }
                                }

                                return (
                                  <>
                                    <div className="multiRoomRow hsmakeFlex bdrBottom flexOne recRoom ">
                                      <div className="hsmakeFlex column titleWidth">
                                        <p
                                          className="hsmakeFlex appendBottom20"
                                          style={{ flexWrap: "wrap" }}
                                        >
                                          <span className="appendRight10 latoBold font14 darkText capText">
                                            {" "}
                                            OPTION {index + 1}
                                          </span>
                                        </p>
                                        <p
                                          className="appendBottom4"
                                          style={{
                                            textDecorationLine: "underline",
                                          }}
                                        >
                                          {dat.rate_plan_name}
                                        </p>
                                        <div className="hsmakeFlex  appendBottom15">
                                          <span className="roomIconWrap">
                                            <span className="spriteRate roomBreakfastIcon">
                                              &nbsp;
                                            </span>
                                          </span>
                                          <div className="hsmakeFlex column flexOne appendLeft10">
                                            <p className="appendBottom4">
                                              Room With Free Cancellation |{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="hsmakeFlex  appendBottom15">
                                          <span className="roomIconWrap">
                                            <span className="pwaSprite freeCancellation"></span>
                                            &nbsp;
                                          </span>
                                          <div className="hsmakeFlex column flexOne appendLeft10">
                                            {/* {moment(dat.CancellationPolicy.PolicyRules.Rule[0].attributes.DateTo).format('Do MMMM YYYY')} */}
                                            <p className="appendBottom4">
                                              <span className="greenText latoBold extraSpacing">
                                                {item.roomCancellation[index]
                                                  .length > 0 &&
                                                  getDate(
                                                    item.roomCancellation[index]
                                                  )}
                                              </span>
                                            </p>
                                            <a
                                              className="OpenCancel"
                                              onClick={openModalDivHS}
                                            >
                                              Read More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="titleWidth">
                                        <ul className="includeList">
                                          {dat.OptionInclusion.map(
                                            (nam, keyy) => (
                                              <li>{nam.inclusion_name}</li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="hsmakeFlex column titleLastWidth ">
                                        {dat.specialOffer != "no_offer" && (
                                          <div className="dealOfDayNew">
                                            <p className="dealTitle">
                                              Special Offer:{" "}
                                              <span className="latoBold">
                                                {showSpecialOffer(dat)}
                                              </span>
                                            </p>
                                            <span
                                              className="pwaSprite freeOffer"
                                              data-tip
                                              data-for={tooltip}
                                            ></span>
                                            <ReactTooltip
                                              className="rohan"
                                              id={tooltip}
                                              place="left"
                                              type="dark"
                                            >
                                              <span>
                                                {showSpecialOfferDis(dat)}
                                              </span>
                                            </ReactTooltip>
                                          </div>
                                        )}
                                        <div className="hsmakeFlex appendTop40 roomTypePrice">
                                          <div className="hsmakeFlex column font12 appendRight10 flexOne">
                                          <p className="font12 grayText appendBottom5">
                                              Per Night
                                            </p>
                                            <div className="font22 latoBlack appendBottom5 relative hsmakeFlex column">
                                              {/* <p
                                                className="latoBlack font26 blackText appendBottom5"
                                                id="hlistpg_hotel_shown_price"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faEuroSign}
                                                  color="#000"
                                                  style={{ fontSize: "23px" }}
                                                ></FontAwesomeIcon>{" "}
                                                {(
                                                  Math.round(
                                                    dat.AmountWithUplift * 100
                                                  ) / 100
                                                ).toFixed(2)}
                                              </p> */}
                                              {getAmountHsPortal(
                                                dat.specialOffer,
                                                dat.AmountWithUplift,
                                                dat.id
                                              )}
                                            </div>
                                          </div>
                                          <div className="hsmakeFlex column perfectCenter noShrink">
                                            <a
                                              className="primaryBtn2 appendBottom15"
                                              href={
                                                "/paymentWall?hotelid=" +
                                                Base64.btoa(
                                                  props.HotelDetails.id
                                                ) +
                                                "&query=" +
                                                encodeURIComponent(
                                                  props.querySearching
                                                ) +
                                                "&hotelImage=" +
                                                encodeURIComponent(
                                                  props.HotelImage
                                                ) +
                                                "&propertySource=" +
                                                encodeURIComponent(
                                                  props.HotelDetails
                                                    .property_source
                                                ) +
                                                "&cart=" +
                                                getHSCartData(
                                                  dat.id,
                                                  dat.AmountWithUplift,
                                                  item.room.id
                                                )
                                              }
                                            >
                                              SELECT ROOM
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <Modal
                                      isOpen={openModalHS}
                                      onRequestClose={closeModalDivHS}
                                      overlayClassName="backdropNew"
                                    >
                                      <div className="modalCont">
                                        <span
                                          className="closeButton"
                                          onClick={closeModalDivHS}
                                        >
                                          Close
                                        </span>
                                        <h4>Cancellation Policy :</h4>
                                        <div className="modalContent">
                                          <div>
                                            <div className="latoRegular font16 appendBottom15">
                                              <b>
                                                {item.roomCancellation[index]
                                                  .length > 0 &&
                                                  getDateModalHS(
                                                    item.roomCancellation[index]
                                                  )}
                                                ‬&#x202C;
                                              </b>
                                            </div>
                                          </div>
                                          <div>
                                            <div className="latoRegular font16 appendBottom15">
                                              <b>
                                                {/* Cancellations post that will be
                                                subject to a fee as follows */}
                                                The cancellation charges will be applicable as follows
                                              </b>
                                            </div>
                                            <div className="cncpolicyTbl">
                                              <div className="cncpolicyTbl__head">
                                                <div className="cncpolicyTbl__col">
                                                  DATE
                                                </div>
                                                <div className="cncpolicyTbl__col">
                                                  FEE
                                                </div>
                                              </div>

                                              {CancellationModalHS(
                                                dat.id,
                                                item.roomCancellation[index]
                                              )}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="latoRegular font16 appendBottom15">
                                              Cancellations are only allowed
                                              before the Check-In Time. All time
                                              mentioned above is in Destination
                                              Time.
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Modal>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}{" "}
                    </>
                  ) : (
                    <> </>
                  )}
                </>
              )}
            </>
          ) : (
            <> </>
          )}

          {props.hotel_link != "no_hotel_link" ? (
            <>
              {roomCount > 1 ? (
                <>
                  <div className="roomWrap">
                    <div className="roomLeftCont">
                      {props.hotel_link != "no_hotel_link" ? (
                        <>
                          {Object.keys(props.hotel_link).map((key, indextop) => {
                            const item = props.hotel_link[key];
                        
                            return (
                              <div className="roomLeftContRow">
                                <div className="roomLeft">
                                  <div className="sticky">
                                    <h2>{key}</h2>
                                    {/* {item.roomImages.slice(0, 1).map((i, k) => (
                                      <img
                                        src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`}
                                        alt="room Img"
                                        className="hsmakeFlex appendBottom20 roomImg"
                                      ></img>
                                    ))} */}
                                    <Carousel>
                                      {item.roomImages && item.roomImages.map((i, indextop) => (

                                        <div>
                                          <a onClick={openModalDivHLRoom}><img src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`} />
                                          </a>

                                        </div>

                                      ))}

                                    </Carousel>
                                    <Modal
                                      isOpen={openModalRoom}
                                      onRequestClose={closeModalDivHLRoom}
                                      overlayClassName="backdropNew"
                                    >
                                      <div className="modalCont">
                                        <span
                                          className="closeButton"
                                          onClick={closeModalDivHLRoom}
                                        >
                                          Close
                                        </span>
                                        {/* <h4>Cancellation Policy :</h4> */}
                                        <div className="modalContent">

                                          <Carousel>
                                            {item.roomImages && item.roomImages.map((i, indextop) => (
                                              <div>
                                                <img src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`} />

                                              </div>
                                            ))}

                                          </Carousel>

                                        </div>
                                      </div>
                                    </Modal>

                                    <ul className="appendBottom10" style={{marginTop: "-20px"}}>
                                      {item.roomFacilities
                                        .slice(0, 5)
                                        .map((result, index) => (
                                          <li className="hsmakeFlex appendBottom5">
                                            <span className="oval"></span>
                                            {result.room_amenity}
                                          </li>
                                        ))}
                                    </ul>
                                    {/* <a
                                      class="latoBlack blueText font12 capText hsmakeFlex hrtlCenter"
                                      style={{
                                        color: "#0084ff",
                                        cursor: "pointer",
                                      }}
                                    >
                                      More about this Hotel{" "}
                                      <span class="sprite linkArrowIcon appendLeft10"></span>
                                    </a> */}
                                  </div>
                                </div>
                                <div className="Middle">
                                  {item.roomOptions.map((dat, index) => {
                                  
                                    let tooltip = "tooltip_" + index;
                                    let balanceAmount = 0;

                                    if (dat.specialOffer != "no_offer") {
                                      let result = [];
                                      if (
                                        dat.specialOffer.combineoffer ==
                                        "nocombine"
                                      ) {
                                        if (
                                          dat.specialOffer.specialdata
                                            .spl_offer_type_id == 1
                                        ) {
                                          const dis =
                                            dat.AmountWithUplift *
                                            parseInt(
                                              dat.specialOffer.specialdata
                                                .pay_stay_length
                                            ) -
                                            dat.AmountWithUplift *
                                            parseInt(
                                              dat.specialOffer.specialdata
                                                .pay_stay_value
                                            );
                                       
                                          const disA =
                                            (dis /
                                              (dat.AmountWithUplift *
                                                parseInt(
                                                  dat.specialOffer.specialdata
                                                    .pay_stay_length
                                                ))) *
                                            100;
                                         
                                          result.push(disA);
                                        } else {
                                          if (
                                            dat.specialOffer.specialdata
                                              .discount_in == 2
                                          ) {
                                            const disA =
                                              dat.specialOffer.specialdata
                                                .rate_discount;
                                            result.push(+disA);
                                          } else {
                                            const disA =
                                              dat.AmountWithUplift -
                                              dat.specialOffer.specialdata
                                                .rate_discount;
                                            result.push(+disA);
                                          }
                                        }
                                      } else {
                                        if (
                                          dat.specialOffer.specialdata
                                            .spl_offer_type_id == 1
                                        ) {
                                          const dis =
                                            dat.AmountWithUplift *
                                            parseInt(
                                              dat.specialOffer.specialdata
                                                .pay_stay_length
                                            ) -
                                            dat.AmountWithUplift *
                                            parseInt(
                                              dat.specialOffer.specialdata
                                                .pay_stay_value
                                            );
                                          const disA =
                                            (dis /
                                              (dat.AmountWithUplift *
                                                parseInt(
                                                  dat.specialOffer.specialdata
                                                    .pay_stay_length
                                                ))) *
                                            100;
                                          result.push(disA);
                                        } else {
                                          if (
                                            dat.specialOffer.specialdata
                                              .discount_in == 2
                                          ) {
                                            const disA =
                                              dat.specialOffer.specialdata
                                                .rate_discount;
                                            result.push(+disA);
                                          } else {
                                            const disA =
                                              dat.AmountWithUplift -
                                              dat.specialOffer.specialdata
                                                .rate_discount;
                                            result.push(+disA);
                                          }
                                        }
                                      }

                                      const Amount = result.reduce(
                                        (a, v) => a + v,
                                        0
                                      );

                                      balanceAmount =
                                        dat.AmountWithUplift -
                                        (
                                          Math.round(
                                            Amount * dat.AmountWithUplift
                                          ) / 100
                                        ).toFixed(2);
                                    }
                                    let checkAdult = false;

                                    let result = [];
                                    if (cats.length > 0) {
                                      for (var i = 0; i < cats.length; i++) {
                                        if (
                                          cats[i].adultCounter * 1 +
                                          cats[i].childCounter * 1 <=
                                          dat.max_guests
                                        ) {
                                          checkAdult = true;

                                          result.push({
                                            number: cats[i].adultCounter,
                                            price: dat.extraAdult,
                                          });
                                        } else {
                                          checkAdult = false;
                                        }
                                      }
                                    }

                                    return (
                                      <>
                                        <div className="multiRoomRow hsmakeFlex bdrBottom ">
                                          <div className="hsmakeFlex column col1">
                                            <p
                                              className="hsmakeFlex appendBottom20"
                                              style={{ flexWrap: "wrap" }}
                                            >
                                              <span className="appendRight10 latoBold font14 darkText capText">
                                                {" "}
                                                OPTION {index + 1}
                                              </span>
                                            </p>
                                            <p
                                              className="appendBottom4"
                                              style={{
                                                textDecorationLine: "underline",
                                              }}
                                            >
                                              {dat.rate_plan_name}
                                            </p>

                                            <div className="hsmakeFlex  appendBottom15">
                                              <span className="roomIconWrap">
                                                <span className="spriteRate roomBreakfastIcon">
                                                  &nbsp;
                                                </span>
                                              </span>
                                              <div className="hsmakeFlex column flexOne appendLeft10">
                                                <p className="appendBottom4">
                                                  Room With Free Cancellation |
                                                </p>
                                              </div>
                                            </div>
                                            <div className="hsmakeFlex  appendBottom15">
                                              <span className="roomIconWrap">
                                                <span className="pwaSprite freeCancellation"></span>
                                                &nbsp;
                                              </span>
                                              <div className="hsmakeFlex column flexOne appendLeft10">
                                                <p className="appendBottom4">
                                                  <span className="greenText latoBold extraSpacing">
                                                    {item.roomCancellation[
                                                      index
                                                    ].length > 0 &&
                                                      getDate(
                                                        item.roomCancellation[
                                                        index
                                                        ]
                                                      )}
                                                  </span>
                                                </p>
                                                <a
                                                  className="OpenCancel"
                                                  onClick={openModalDivHS}
                                                >
                                                  Read More
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="col2">
                                              <ul className="includeList"></ul>
                                            </div> */}
                                          <div className="col3">
                                            <div className="ddPosition">
                                              <div>
                                                {result.length > 0 ? (
                                                  <div className="slctRmDropdown  appendTop10 appendBottom20">
                                                    <div className="ddWrapper">
                                                      <div
                                                        className="slctRmDropdown__input"
                                                        onClick={() =>
                                                          setOption(dat.id)
                                                        }
                                                      >
                                                        {OpenDropdownValue.filter(
                                                          (e) =>
                                                            e.rate_id === dat.id
                                                        ).length > 0
                                                          ? OpenDropdownValue.filter(
                                                            (e) =>
                                                              e.rate_id ===
                                                              dat.id
                                                          )[0].number
                                                          : 1}{" "}
                                                        Adult
                                                      </div>

                                                      {OpenDropdown.includes(
                                                        dat.id
                                                      ) && (
                                                          <ul className="slctRmDropdown__list">
                                                            {result
                                                              .sort(
                                                                (a, b) =>
                                                                  a.number -
                                                                  b.number
                                                              )
                                                              .map((i, j) => {
                                                                let newPrice = 0;
                                                                if (
                                                                  i.price != ""
                                                                ) {
                                                                  newPrice = Math.round(
                                                                    dat.AmountWithUplift *
                                                                    1 +
                                                                    i.price *
                                                                    1 *
                                                                    (i.number -
                                                                      dat.guests_included)
                                                                  ).toFixed(2);
                                                                } else {
                                                                  newPrice =
                                                                    dat.AmountWithUplift;
                                                                }
                                                                return (
                                                                  <li
                                                                    className="slctRmDropdown__list--item"
                                                                    onClick={() =>
                                                                      PriceChanger(
                                                                        i.number,
                                                                        newPrice,
                                                                        dat.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <p className="latoBold appendBottom3">
                                                                      {i.number}{" "}
                                                                      Adult
                                                                    </p>
                                                                    <p className="latoBold font12 grayText">
                                                                      <span>€</span>{newPrice}
                                                                    </p>
                                                                  </li>
                                                                );
                                                              })}
                                                          </ul>
                                                        )}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <p className="latoBold font14 darkText appendBottom3">
                                                    {dat.adult != null
                                                      ? dat.adult
                                                      : dat.guests_included}{" "}
                                                    Adults{" "}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col4">
                                            <div
                                              className=""
                                              style={{ marginTop: 20 }}
                                            >
                                              {dat.specialOffer !=
                                                "no_offer" && (
                                                  <div className="dealOfDayNew">
                                                    <p className="dealTitle">
                                                      Special Offer:{" "}
                                                      <span className="latoBold">
                                                        {showSpecialOffer(dat)}
                                                      </span>
                                                    </p>
                                                    <span
                                                      className="pwaSprite freeOffer"
                                                      data-tip
                                                      data-for={tooltip}
                                                    ></span>
                                                    <ReactTooltip
                                                      className="rohan"
                                                      id={tooltip}
                                                      place="left"
                                                      type="dark"
                                                    >
                                                      <span>
                                                        {showSpecialOfferDis(dat)}
                                                      </span>
                                                    </ReactTooltip>
                                                  </div>
                                                )}
                                              <div className="hsmakeFlex column font12 appendRight10 flexOne">
                                              <p className="font12 grayText appendBottom5">
                                                  Per Night
                                                </p>
                                                <div
                                                  className="font22 latoBlack appendBottom5 relative hsmakeFlex column"
                                                  style={{
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {getAmountHotelLink(
                                                    dat.specialOffer,
                                                    dat.AmountWithUplift,
                                                    dat.id
                                                  )}
                                                </div>
                                              </div>

                                              <p>
                                                <a
                                                  className={
                                                    "primaryBtn btnAddRoom add_Room_" +
                                                    item.room.id
                                                  }
                                                  onClick={() => {
                                                    addRoomtoCart(
                                                      key,
                                                      dat.id,
                                                      getAmountHotelLinkCart(
                                                        dat.specialOffer,
                                                        dat.AmountWithUplift,
                                                        dat.id
                                                      ),
                                                      dat.adult,
                                                      dat.child,
                                                      item.room.id,
                                                      item.roomAvailability
                                                    );
                                                  }}

                                                >
                                                  ADD{" "}
                                                  {ordinal_suffix_of(
                                                    cart.length + 1
                                                  )}{" "}
                                                  Room
                                                </a>
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <Modal
                                          isOpen={openModalHS}
                                          onRequestClose={closeModalDivHS}
                                          overlayClassName="backdropNew"
                                        >
                                          <div className="modalCont">
                                            <span
                                              className="closeButton"
                                              onClick={closeModalDivHS}
                                            >
                                              Close
                                            </span>
                                            <h4>Cancellation Policy :</h4>
                                            <div className="modalContent">
                                              <div>
                                                <div className="latoRegular font16 appendBottom15">
                                                  <b>
                                                    {item.roomCancellation[
                                                      index
                                                    ].length > 0 &&
                                                      getDateModal(
                                                        item.roomCancellation[
                                                        index
                                                        ]
                                                      )}
                                                    ‬&#x202C;
                                                  </b>
                                                </div>
                                              </div>
                                              <div>
                                                <div className="latoRegular font16 appendBottom15">
                                                  <b>
                                                    {/* Cancellations post that will
                                                    be subject to a fee as
                                                    follows */}
                                                    The cancellation charges will be applicable as follows
                                                  </b>
                                                </div>
                                                <div className="cncpolicyTbl">
                                                  <div className="cncpolicyTbl__head">
                                                    <div className="cncpolicyTbl__col">
                                                      DATE
                                                    </div>
                                                    <div className="cncpolicyTbl__col">
                                                      FEE
                                                    </div>
                                                  </div>

                                                  {CancellationModal(
                                                    dat.id,
                                                    item.roomCancellation[index]
                                                  )}
                                                </div>
                                              </div>
                                              <div>
                                                <div className="latoRegular font16 appendBottom15">
                                                  Cancellations are only allowed
                                                  before the Check-In Time. All
                                                  time mentioned above is in
                                                  Destination Time.
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Modal>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}{" "}
                        </>
                      ) : (
                        <> </>
                      )}
                    </div>
                    <div className="roomRightCont">
                      <div className="roomRightContSticky">
                        {cart.length > 0 ? (
                          <>
                            {cart.map((i, index) => {
                              return (
                                <>
                                  <div
                                    className={
                                      "selectedRoom appendTop20 add_roomCount_" +
                                      i.room_id
                                    }
                                  >
                                    <span
                                      className="btnCloseRoom"
                                      onClick={() => {
                                        clearCart(index, i);
                                      }}
                                    ></span>
                                    <div>
                                      <div className="selectedRoomRow">
                                        <p className="latoBold font14 blackText appendBottom3">
                                          {i.name}
                                        </p>
                                        <p className="font12 appendBottom10">
                                          {i.adult} Adults
                                        </p>
                                        <p className="latoBlack font18 blackText appendBottom3">
                                          <FontAwesomeIcon
                                            icon={faEuroSign}
                                            color="#000"
                                            style={{ fontSize: "14px" }}
                                          ></FontAwesomeIcon>
                                          {Number(i.amount).toFixed(2)}
                                        </p>
                                        <p className="font12">Per Night</p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                            <div
                              className="makeFlex column spaceBetween appendTop20 appendBottom15"
                              style={{ marginTop: 10 }}
                            >
                              <span className="latoRegular font12 grayText lineHight16">
                                {cart.reduce((a, v) => a + v.adult * 1, 0)}{" "}
                                Adults{" "}
                              </span>
                              <span className="latoBlack font20 blackText">
                                <FontAwesomeIcon
                                  icon={faEuroSign}
                                  color="#000"
                                  style={{ fontSize: "17px" }}
                                ></FontAwesomeIcon>
                                {cart
                                  .reduce((a, v) => a + v.amount * 1, 0)
                                  .toFixed(2)}
                              </span>

                              {/* <span className="latoRegular font12 darkGreyText lineHight16">+₹ 283 taxes &amp; fees</span> */}

                              <span className="latoRegular font12 darkText lineHight16">
                                Per Night
                              </span>
                            </div>
                            <a
                              className="btnConfirmBooking appendBottom15"
                              href={
                                "/paymentWall?hotelid=" +
                                Base64.btoa(props.HotelDetails.id) +
                                "&query=" +
                                encodeURIComponent(props.querySearching) +
                                "&hotelImage=" +
                                encodeURIComponent(props.HotelImage) +
                                "&propertySource=" +
                                encodeURIComponent(
                                  props.HotelDetails.property_source
                                ) +
                                "&cart=" +
                                JSON.stringify(cart)
                              }
                              style={{ marginTop: 12, cursor: "pointer" }}
                            >
                              Review Details
                            </a>
                          </>
                        ) : (
                          <>
                            <p className="txtNoRoomSelected">
                              No Room
                              <br />
                              Selected
                            </p>
                            <div className="roomBlank nextRoom appendTop20">
                              <span>Room 1</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {props.hotel_link != "no_hotel_link" ? (
                    <>
                      {" "}
                      {Object.keys(props.hotel_link).map((key, indextop) => {
                        const item = props.hotel_link[key];
                      
                        return (
                          <div className="roomWrap">
                            <div className="roomLeft">
                              <div className="sticky">
                                <h2>{key} </h2>
                                {/* {item.roomImages.slice(0, 1).map((i, k) => (
                                  <img
                                    src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`}
                                    alt="room Img"
                                    className="hsmakeFlex appendBottom20 roomImg"
                                  ></img>
                                ))} */}
                                <Carousel>
                                  {item.roomImages && item.roomImages.map((i, indextop) => (

                                    <div>
                                      <a onClick={openModalDivHLRoom}><img src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`} />
                                      </a>

                                    </div>

                                  ))}

                                </Carousel>
                                <Modal
                                  isOpen={openModalRoom}
                                  onRequestClose={closeModalDivHLRoom}
                                  overlayClassName="backdropNew"
                                >
                                  <div className="modalCont">
                                    <span
                                      className="closeButton"
                                      onClick={closeModalDivHLRoom}
                                    >
                                      Close
                                    </span>
                                    {/* <h4>Cancellation Policy :</h4> */}
                                    <div className="modalContent">

                                      <Carousel>
                                        {item.roomImages && item.roomImages.map((i, indextop) => (
                                          <div>
                                            <img src={`${props.SupplierUrl}/uploads/room_gallery_main/${i.image}`} />

                                          </div>
                                        ))}

                                      </Carousel>

                                    </div>
                                  </div>
                                </Modal>

                                <ul className="appendBottom10" style={{marginTop: "-20px"}}>
                                  {item.roomFacilities
                                    .slice(0, 5)
                                    .map((result, index) => (
                                      <li className="hsmakeFlex appendBottom5">
                                        <span className="oval"></span>
                                        {result.room_amenity}
                                      </li>
                                    ))}
                                </ul>
                                {/* <a
                                  className="latoBlack blueText font12 capText hsmakeFlex hrtlCenter"
                                  style={{
                                    color: "#0084ff",
                                    cursor: "pointer",
                                  }}
                                >
                                  More about this Resort{" "}
                                  <span className="spriteRate linkArrowIcon appendLeft10"></span>
                                </a> */}
                              </div>
                            </div>
                            <div className="roomRight">
                              {item.roomOptions.map((dat, index) => {
                                let tooltip = "tooltip_" + index;

                                let checkAdult = false;

                                if (cats.length > 0) {
                                  for (var i = 0; i < cats.length; i++) {
                                    if (
                                      cats[i].adultCounter * 1 +
                                      cats[i].childCounter * 1 <=
                                      dat.max_guests
                                    ) {
                                      checkAdult = true;
                                    } else {
                                      checkAdult = false;
                                    }
                                  }
                                }

                                return (
                                  <>
                                    <div className="multiRoomRow hsmakeFlex bdrBottom flexOne recRoom ">
                                      <div className="hsmakeFlex column titleWidth">
                                        <p
                                          className="hsmakeFlex appendBottom20"
                                          style={{ flexWrap: "wrap" }}
                                        >
                                          <span className="appendRight10 latoBold font14 darkText capText">
                                            {" "}
                                            OPTION {index + 1}
                                          </span>
                                        </p>
                                        <p
                                          className="appendBottom4"
                                          style={{
                                            textDecorationLine: "underline",
                                          }}
                                        >
                                          {dat.rate_plan_name}
                                        </p>
                                        <div className="hsmakeFlex  appendBottom15">
                                          <span className="roomIconWrap">
                                            <span className="spriteRate roomBreakfastIcon">
                                              &nbsp;
                                            </span>
                                          </span>
                                          <div className="hsmakeFlex column flexOne appendLeft10">
                                            <p className="appendBottom4">
                                              Room With Free Cancellation |{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="hsmakeFlex  appendBottom15">
                                          <span className="roomIconWrap">
                                            <span className="pwaSprite freeCancellation"></span>
                                            &nbsp;
                                          </span>
                                          <div className="hsmakeFlex column flexOne appendLeft10">
                                            {/* {moment(dat.CancellationPolicy.PolicyRules.Rule[0].attributes.DateTo).format('Do MMMM YYYY')} */}
                                            <p className="appendBottom4">
                                              <span className="greenText latoBold extraSpacing">
                                                {item.roomCancellation[index]
                                                  .length > 0 &&
                                                  getDate(
                                                    item.roomCancellation[index]
                                                  )}
                                              </span>
                                            </p>
                                            <a
                                              className="OpenCancel"
                                              onClick={openModalDivHS}
                                            >
                                              Read More
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="titleWidth">
                                        <ul className="includeList">
                                          {dat.OptionInclusion.map(
                                            (nam, keyy) => (
                                              <li>{nam.inclusion_name}</li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="hsmakeFlex column titleLastWidth ">
                                        {dat.specialOffer != "no_offer" && (
                                          <div className="dealOfDayNew">
                                            <p className="dealTitle">
                                              Special Offer:{" "}
                                              <span className="latoBold">
                                                {showSpecialOffer(dat)}
                                              </span>
                                            </p>
                                            <span
                                              className="pwaSprite freeOffer"
                                              data-tip
                                              data-for={tooltip}
                                            ></span>
                                            <ReactTooltip
                                              className="rohan"
                                              id={tooltip}
                                              place="left"
                                              type="dark"
                                            >
                                              <span>
                                                {showSpecialOfferDis(dat)}
                                              </span>
                                            </ReactTooltip>
                                          </div>
                                        )}
                                        <div className="hsmakeFlex appendTop40 roomTypePrice">
                                          <div className="hsmakeFlex column font12 appendRight10 flexOne">
                                          <p className="font12 grayText appendBottom5">
                                                  Per Night
                                                </p>
                                                <div
                                                  className="font22 latoBlack appendBottom5 relative hsmakeFlex column"
                                                  // style={{
                                                  //   alignItems: "center",
                                                  // }}
                                                >
                                                  {getAmountHotelLink(
                                                    dat.specialOffer,
                                                    dat.AmountWithUplift,
                                                    dat.id
                                                  )}
                                                </div>
                                          </div>
                                          <div className="hsmakeFlex column perfectCenter noShrink">
                                            <a
                                              className="primaryBtn2 appendBottom15"
                                              href={
                                                "/paymentWall?hotelid=" +
                                                Base64.btoa(
                                                  props.HotelDetails.id
                                                ) +
                                                "&query=" +
                                                encodeURIComponent(
                                                  props.querySearching
                                                ) +
                                                "&hotelImage=" +
                                                encodeURIComponent(
                                                  props.HotelImage
                                                ) +
                                                "&propertySource=" +
                                                encodeURIComponent(
                                                  props.HotelDetails
                                                    .property_source
                                                ) +
                                                "&cart=" +
                                                getHSCartData(
                                                  dat.id,
                                                  dat.AmountWithUplift,
                                                  item.room.id
                                                )
                                              }
                                            >
                                              SELECT ROOM
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <Modal
                                      isOpen={openModalHS}
                                      onRequestClose={closeModalDivHS}
                                      overlayClassName="backdropNew"
                                    >
                                      <div className="modalCont">
                                        <span
                                          className="closeButton"
                                          onClick={closeModalDivHS}
                                        >
                                          Close
                                        </span>
                                        <h4>Cancellation Policy :</h4>
                                        <div className="modalContent">
                                          <div>
                                            <div className="latoRegular font16 appendBottom15">
                                              <b>
                                                {item.roomCancellation[index]
                                                  .length > 0 &&
                                                  getDateModal(
                                                    item.roomCancellation[index]
                                                  )}
                                                ‬&#x202C;
                                              </b>
                                            </div>
                                          </div>
                                          <div>
                                            <div className="latoRegular font16 appendBottom15">
                                              <b>
                                                {/* Cancellations post that will be
                                                subject to a fee as follows */}
                                                The cancellation charges will be applicable as follows
                                              </b>
                                            </div>
                                            <div className="cncpolicyTbl">
                                              <div className="cncpolicyTbl__head">
                                                <div className="cncpolicyTbl__col">
                                                  DATE
                                                </div>
                                                <div className="cncpolicyTbl__col">
                                                  FEE
                                                </div>
                                              </div>

                                              {CancellationModal(
                                                dat.id,
                                                item.roomCancellation[index]
                                              )}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="latoRegular font16 appendBottom15">
                                              Cancellations are only allowed
                                              before the Check-In Time. All time
                                              mentioned above is in Destination
                                              Time.
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Modal>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}{" "}
                    </>
                  ) : (
                    <> </>
                  )}
                </>
              )}
            </>
          ) : (
            <> </>
          )}
        </div>
      </div>
    </section>
  );
}

export default RatesAndDetails;
